import { Card, CardBody, CardHeader, StatusText } from '@avast/react-ui-components';
import React, { PropsWithChildren, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

type TNoteProps = {
	header: string;
	noDataMessage?: string;
	minHeight?: number;
};

export const Note = (props: PropsWithChildren<TNoteProps>): ReactElement => {
	const [t] = useTranslation();
	const { children, header, noDataMessage = t('components:notes.noContent'), minHeight = 250 } = props;
	const hasChildren = Boolean(children);

	return (
		<Card>
			<CardHeader>{header}</CardHeader>
			<CardBody style={{ minHeight: `${hasChildren ? minHeight : 0}px` }}>
				{hasChildren ? <p>{children}</p> : <StatusText caption={noDataMessage!} />}
			</CardBody>
		</Card>
	);
};
