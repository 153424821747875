import { useMatchLocation } from 'js/hooks/useMatchLocation';
import { RouteName } from 'module/RouteName';

export const useIsRefreshRestricted = () => {
	return useMatchLocation([
		RouteName.SECURITY.LOGOUT,
		RouteName.SECURITY.SSO,
		RouteName.SECURITY.OMS_SSO,
		RouteName.SECURITY.LOGIN_AS_AFFILIATE,
	]);
};
