import React, { ReactElement, useMemo } from 'react';
import { TDetailArguments } from 'types';
import { useNavigate } from 'react-router-dom';
import { RouteName } from 'module/RouteName';
import { H1, Toast } from '@avast/react-ui-components';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { PageDetailComponent } from 'js/layouts/page/PageDetailComponent';
import { TUseApiDetailProps } from 'js/queries/useApiDetailQuery';
import { Headline } from 'js/components/molecules/Headline';
import { LinkButton } from 'js/components/atoms/Button';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { customersConfig } from 'module/customers/customersConfig';
import { IApiPartnerCustomerFilter, IEntityCustomer } from 'module/customers';
import { useApiPartnerCustomer, useApiUpdateCustomer } from 'module/customers/hooks/useApiCustomers';
import { CustomerForm } from 'module/customers/forms/CustomerForm';
import { getCustomerRenderName } from 'module/customers/utils/customerSelectors';
import { buildParametrizedRoute } from 'js/utils/common';
import { updateCustomerApiError } from 'module/customers/utils/apiError';
import { useRouteParams } from 'js/hooks/useRouteParams';
import { useApiErrorContext, useAuthContext, useOrderContext } from 'js/contexts';

type TData = IEntityCustomer;
type TArguments = TDetailArguments;
type TQueryProps = TUseApiDetailProps<TData, IApiPartnerCustomerFilter>;

export const PageUpdate = (): ReactElement => {
	const [t] = useTranslation(customersConfig.trNamespace);
	const params = useRouteParams<TArguments>();
	const navigate = useNavigate();
	const { authCompanyId } = useAuthContext();
	const { orderState, setCustomer } = useOrderContext();
	const { setError } = useApiErrorContext();
	const { mutate: updateCustomer } = useApiUpdateCustomer({
		config: {
			params: {
				partnerId: authCompanyId!,
				salesforceCustomerId: params.id,
			},
		},
	});

	/**
	 * Setup query props/filter
	 * @type {TQueryProps}
	 */
	const queryProps: TQueryProps = useMemo(
		() => ({
			filter: {
				salesforceId: params.id,
				partnerId: authCompanyId!,
			},
		}),
		[params, authCompanyId],
	);

	return (
		<PageDetailComponent<TData, IApiPartnerCustomerFilter>
			query={useApiPartnerCustomer}
			queryProps={queryProps}
			trNamespace={customersConfig.trNamespace}
			titleRender={getCustomerRenderName}
			detailLink={buildParametrizedRoute(RouteName.CUSTOMERS.UPDATE, params.id)}
		>
			{({ data }) => {
				const detailLink = customersConfig.detailLink(data);
				return (
					<>
						<Headline
							className="align-items-center"
							rightContent={
								<LinkButton
									testId="back"
									size="sm"
									variant="outline-primary"
									to={detailLink}
									iconLeftFa={faChevronLeft}
								>
									{t('common:actions.back')}
								</LinkButton>
							}
						>
							<H1 type="h2">{getCustomerRenderName(data)}</H1>
						</Headline>

						<div className="section__content mt-3">
							<CustomerForm
								initialValues={data}
								isUpdate
								onCancel={() => {
									navigate(detailLink);
								}}
								onSubmit={(values, { setSubmitting }) => {
									updateCustomer(values, {
										onSuccess(response) {
											setSubmitting(false);
											// Redirect
											if (Boolean(response?.data)) {
												// Update in order instance
												if (orderState.customer?.id === response.data.id) {
													setCustomer(response.data);
												}

												toast.success(<Toast>{t('page.update.success')}</Toast>);
												navigate(detailLink);
											}
										},
										onError(error) {
											setError({ error, resolve: updateCustomerApiError });
											setSubmitting(false);
										},
									});
								}}
							/>
						</div>
					</>
				);
			}}
		</PageDetailComponent>
	);
};
