import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import { IEntityPromotion } from 'module/promotions';
import { DataTableTr } from 'js/components/molecules/DataTable';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { numberFormatter } from 'js/utils/number';

export const QuantityRow = () => {
	const { data: promotion } = usePageDetailContext<IEntityPromotion>();
	const [t] = useTranslation(promotionsConfig.trNamespace);

	// Business
	if (promotion.quantityMax) {
		return (
			<>
				<DataTableTr
					name={t('entity.unitsFrom')}
					isEmpty={false}
				>
					{numberFormatter.format(promotion.quantity, { mantissa: 0 })}
				</DataTableTr>
				<DataTableTr name={t('entity.unitsTo')}>
					{numberFormatter.format(promotion.quantityMax, { mantissa: 0 })}
				</DataTableTr>
			</>
		);
	}

	// Consumer
	return (
		<DataTableTr
			name={t('entity.quantityMin')}
			isEmpty={false}
		>
			{numberFormatter.format(promotion.quantity, { mantissa: 0 })}
		</DataTableTr>
	);
};
