import { Col, Row } from 'js/components/atoms/Row';
import { DataTableCard, DataTableTr } from 'js/components/molecules/DataTable';
import React, { ReactElement } from 'react';
import { IEntityCustomer } from 'module/customers';
import { useTranslation } from 'react-i18next';
import { customersConfig } from 'module/customers/customersConfig';
import { Alert } from '@avast/react-ui-components';
import { customerTypeEnumUtils, discountTypeEnumUtils } from 'module/customers/enums';
import {
	getCustomerFullName,
	hasCustomerAnyOtherData,
	isCustomerBusiness,
	isCustomerConsumer,
} from 'module/customers/utils/customerSelectors';
import { useCountryName } from 'js/entities/country/useCountryName';
import { useCountryStateName } from 'js/entities/country/useCountryStateName';
import { Link } from 'react-router-dom';
import { EmailDisplay } from 'js/components/atoms/EmailDisplay';
import { Copy2Clipboard } from 'js/components/molecules/Copy2Clipboard';

type TCustomerDetailProps = {
	customer: IEntityCustomer | null;
	linkable?: boolean;
	isLoading?: boolean;
	hideIncompleteData?: boolean;
};

export const CustomerDetail = (props: TCustomerDetailProps): ReactElement => {
	const { customer, linkable, isLoading, hideIncompleteData } = props;
	const [t] = useTranslation(customersConfig.trNamespace);
	const countryName = useCountryName(customer?.billing.countryCode, customer?.billing.country);
	const stateName = useCountryStateName(
		customer?.billing.countryCode,
		customer?.billing.stateCode,
		customer?.billing.state,
	);

	if (!customer?.id) {
		return <Alert caption={t('error:detailNotFound')} />;
	}

	const link = customersConfig.detailLink(customer);
	const hasCompanyName = Boolean(customer.companyName);
	const isConsumer = isCustomerConsumer(customer);
	const customerName = getCustomerFullName(customer);

	return (
		<Row multi>
			<Col md="6">
				<DataTableCard
					caption={t('common:accountInformation')}
					variant="simple"
					isLoading={isLoading}
				>
					<DataTableTr
						name={t('common:entity.company')}
						noRenderEmpty={isConsumer}
					>
						{linkable && hasCompanyName ? <Link to={link}>{customer.companyName}</Link> : customer.companyName}
					</DataTableTr>
					<DataTableTr
						name={t('entity.customerType')}
						defaultValue={t('common:_.NA')}
					>
						{customer.contactType && customerTypeEnumUtils.getText(customer.contactType)}
					</DataTableTr>
					<DataTableTr
						name={t('common:entity.discountType')}
						noRenderEmpty={hideIncompleteData || isConsumer}
					>
						{customer.billing.discountType && discountTypeEnumUtils.getText(customer.billing.discountType)}
					</DataTableTr>
					<DataTableTr
						name={t('common:entity.requestedDiscountType')}
						noRenderEmpty
					>
						{customer.requestedDiscountType && discountTypeEnumUtils.getText(customer.requestedDiscountType)}
					</DataTableTr>
					<DataTableTr name={t('common:contact.name')}>
						{hasCompanyName || !linkable ? customerName : <Link to={link}>{customerName}</Link>}
					</DataTableTr>
					<DataTableTr name={t('common:contact.email')}>
						<Copy2Clipboard value={customer?.email}>
							<EmailDisplay email={customer?.email} />
						</Copy2Clipboard>
					</DataTableTr>
					<DataTableTr
						name={t('common:contact.phone')}
						noRenderEmpty={hideIncompleteData}
					>
						{customer.phone}
					</DataTableTr>
					<DataTableTr
						name={t('common:contact.website')}
						noRenderEmpty={hideIncompleteData}
					>
						{customer.website}
					</DataTableTr>
				</DataTableCard>
			</Col>
			<Col md="6">
				<DataTableCard
					caption={t('common:address.caption')}
					variant="simple"
					isLoading={isLoading}
				>
					<DataTableTr name={t('common:address.street')}>{customer.billing.street}</DataTableTr>
					<DataTableTr name={t('common:address.city')}>{customer.billing.city}</DataTableTr>
					<DataTableTr name={t('common:address.postalCode')}>{customer.billing.postalCode}</DataTableTr>
					<DataTableTr
						name={t('common:address.state')}
						noRenderEmpty
					>
						{stateName}
					</DataTableTr>
					<DataTableTr name={t('common:address.country')}>{countryName}</DataTableTr>
				</DataTableCard>

				{/* Only for BUSINESS customers, If should hide incomplete data, check if is there any data to show  */}
				{isCustomerBusiness(customer) && !(hideIncompleteData && !hasCustomerAnyOtherData(customer)) && (
					<DataTableCard
						caption={t('common.otherCustomerInformation')}
						variant="simple"
						isLoading={isLoading}
						cardConfig={{ className: 'mt-3' }}
					>
						<DataTableTr name={t('entity.industry')}>{customer.industry}</DataTableTr>
					</DataTableCard>
				)}
			</Col>
		</Row>
	);
};
