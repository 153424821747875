import type { TWidgetConfig, TWidgetGrid } from 'submodule/widgets';
import { getWidgetConfigValidators } from 'submodule/widgets/validators';

export const widgetConfigNormalizer = {
	normalize(config: TWidgetGrid, allowedWidgets: TWidgetConfig[]): TWidgetGrid {
		const { itemValidator, columnValidator, rowValidator } = getWidgetConfigValidators(allowedWidgets);

		return config
			.map((row) =>
				row
					.map((column) => column.filter((item) => itemValidator.isValidSync(item)))
					.filter((column) => columnValidator.isValidSync(column)),
			)
			.filter((row) => rowValidator.isValidSync(row));
	},
};
