import React, { useEffect, useReducer } from 'react';
import { useLicenseOperationContext } from 'module/licenses/context/LicenseOperationContext';
import { useOrderContext } from 'js/contexts';
import { SelectCustomerModalContent, SelectDistributionPartnerModalContent, SelectPartnerModalContent } from './party';
import { SelectPartyEnum } from './enums';
import { getSelectPartyReducerState, ILicenseOperationPartyResolution, selectPartyReducer } from './selectPartyReducer';
import { TAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';

export type TSelectPartyModalProps = {
	forwardedRef: TAsyncModalRef<{}, ILicenseOperationPartyResolution>;
};

export const SelectPartyModal = (props: TSelectPartyModalProps) => {
	const { forwardedRef } = props;
	const licenseOperationContext = useLicenseOperationContext();
	const orderContext = useOrderContext();

	const [state, dispatch] = useReducer(
		selectPartyReducer,
		{
			orderContext,
			licenseOperationContext,
		},
		getSelectPartyReducerState,
	);

	// Wait to step, what resolve/cancel this flow
	useEffect(() => {
		switch (state.step) {
			case SelectPartyEnum.RESOLVED:
				forwardedRef.current?.onSuccess(state);
				break;
			case SelectPartyEnum.CANCEL:
				forwardedRef.current?.onCancel();
				break;
			default:
				break;
		}
	}, [state, forwardedRef]);

	// Render party selection
	switch (state.step) {
		case SelectPartyEnum.PARTNER:
			return (
				<SelectPartnerModalContent
					state={state}
					onResolve={(partnerState) => {
						if (partnerState) {
							dispatch({ type: 'SET_PARTNER', payload: partnerState });
						}
					}}
					onPrev={() => dispatch({ type: 'BACK' })}
				/>
			);
		case SelectPartyEnum.DISTRIBUTION_PARTNER:
			return (
				<SelectDistributionPartnerModalContent
					state={state}
					onResolve={(distributionPartner) => {
						dispatch({ type: 'SET_DISTRIBUTION_PARTNER', payload: distributionPartner });
					}}
					onPrev={() => dispatch({ type: 'BACK' })}
				/>
			);
		case SelectPartyEnum.CUSTOMER:
			return (
				<SelectCustomerModalContent
					state={state}
					onResolve={(customer) => {
						dispatch({ type: 'SET_CUSTOMER', payload: customer });
					}}
					onPrev={() => dispatch({ type: 'BACK' })}
				/>
			);
		default:
			return null;
	}
};
