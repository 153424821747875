import { useTranslation } from 'react-i18next';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { useAuthContext } from 'js/contexts';
import {
	AsyncSelectDistributionPartnerModal,
	useAsyncSelectDistributionPartnerRef,
} from 'module/distributionPartners/components/AsyncSelectDistributionPartnerModal';
import { useNavigate } from 'react-router-dom';
import { Can } from 'js/components/molecules/Can';
import { Button } from '@avast/react-ui-components';
import React from 'react';

export const SelectPartnerButton = () => {
	const [t] = useTranslation(flexibleBillingConfig.trNamespace);
	const { authCompanyId } = useAuthContext();
	const selectDistributionPartnerRef = useAsyncSelectDistributionPartnerRef();
	const navigate = useNavigate();

	const selectDistributionPartner = async () => {
		const distributionPartner = await selectDistributionPartnerRef.current?.show({
			distributorId: authCompanyId,
		});

		if (distributionPartner) {
			navigate(flexibleBillingConfig.detailLinkById(distributionPartner.id));
		}
	};

	return (
		<Can
			do={flexibleBillingConfig.aclModule}
			create
		>
			<>
				<Button
					testId="create"
					size="sm"
					variant="primary"
					onClick={selectDistributionPartner}
				>
					{t('actions.selectPartner')}
				</Button>
				<AsyncSelectDistributionPartnerModal forwardedRef={selectDistributionPartnerRef} />
			</>
		</Can>
	);
};
