import { useApiProductGroupList } from 'module/purchase/hooks/useApiPurchase';
import { IEntityPartnerDetail } from 'module/partners';
import { isPosaPartner } from 'module/partners/utils/partnerSelectors';
import { filterPosaProductGroups } from 'module/retail/utils/common';
import { useGenerateRetailCodesMarketSegments } from 'module/retail/hooks/useGenerateRetailCodesMarketSegments';

export const useRetailProductGroupList = (partner: IEntityPartnerDetail | null) => {
	const { data, query } = useApiProductGroupList({
		filter: { priceListCode: 'PREPAID_OMS', partnerId: partner?.id! },
		queryConfig: { enabled: Boolean(partner?.id) },
	});
	const marketSegments = useGenerateRetailCodesMarketSegments();

	let productGroups = data;
	if (isPosaPartner(partner)) {
		productGroups = filterPosaProductGroups(productGroups);
	}
	if (marketSegments.length === 1) {
		const marketSegment = marketSegments[0];
		productGroups = productGroups.filter((group) => group.marketSegment === marketSegment);
	}

	return {
		data: productGroups,
		query,
	};
};
