import { IAuthTokenResponse, TAuthToken } from 'js/reducers/authContextReducer';
import type { AxiosInstance } from 'axios';
import { logDebug } from 'js/utils/app';

type TRefreshTokenServiceCallback = (response: IAuthTokenResponse | null) => void;
type TRefreshTokenServiceHistory = { expired: TAuthToken; refreshed: TAuthToken };

type TRefreshTokenService = {
	isFetching: boolean;
	list: TRefreshTokenServiceCallback[];
	history: TRefreshTokenServiceHistory[];

	subscribe(api: AxiosInstance, expiredToken?: TAuthToken | null): Promise<IAuthTokenResponse>;
	refresh(api: AxiosInstance): void;
	resolveCurrentToken(token: TAuthToken): TAuthToken;
};

export const refreshTokenService: TRefreshTokenService = {
	isFetching: false,
	list: [],
	history: [],

	subscribe(api, expiredToken) {
		logDebug('Subscribe to JWT token refresh');
		if (!this.isFetching) {
			this.isFetching = true;
			this.refresh(api);

			// Save result to history
			if (expiredToken) {
				this.list.push((response) => {
					if (response) {
						this.history.push({ expired: expiredToken, refreshed: response.token });
					}
				});
			}
		}

		return new Promise((resolve, reject) => {
			this.list.push((response) => {
				if (response) {
					resolve(response);
				} else {
					reject(response);
				}
			});
		});
	},

	refresh(api) {
		logDebug('Refresh JWT token');
		api
			.post<IAuthTokenResponse>('/token/refresh', null, {
				apiVersion: null,
				withCredentials: true,
				isExpiredTokenRetry: true,
				onNonAuthorized: null,
				authToken: null,
				catchError: false,
			})
			.then((response) => {
				this.list.forEach((cb) => cb(response.data));
			})
			.catch((error) => {
				this.list.forEach((cb) => cb(null));
				logDebug('Get refresh token failed', error);
			})
			.finally(() => {
				this.isFetching = false;
				this.list = [];
				logDebug('JWT token refreshed');
			});
	},

	resolveCurrentToken(token) {
		return this.history.find((item) => item.expired === token)?.refreshed || token;
	},
};
