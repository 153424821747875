const prefix = '/security';

export const securityRouteName = {
	LOGIN: `${prefix}/login`,
	LOGOUT: `${prefix}/logout`,
	MAINTENANCE: '/maintenance',
	SSO: '/sso',
	OMS_SSO: '/sso-redirect',
	LOGIN_AS_AFFILIATE: '/login-as-affiliate/:authorizationCode',
	REGISTRATION_INCOMPLETE: '/registration-incomplete',
	INACTIVE: '/inactive',
	TERMS: '/new-terms',
	PASSWORD_RESET: `${prefix}/password-reset`,
	NEW_PASSWORD: `${prefix}/password-reset/:token`,
};
