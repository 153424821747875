import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { securityConfig } from 'module/security/securityConfig';
import { LoadingFullScreenPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { H3, Toast } from '@avast/react-ui-components';
import { useNavigate } from 'react-router-dom';
import { RouteName } from 'module/RouteName';
import { DynamicPageTitleItem } from 'js/components/molecules/DynamicPageTitle';
import { toast } from 'react-toastify';
import { useLoginAsAffiliate } from 'module/security/hooks/useLoginAsAffiliate';
import { TLoginAsAffiliateFormData } from 'module/security/index';
import { useLocationSearchParams } from 'js/hooks/useLocationSearchParams';
import { navigateLinkToNavigateObject } from 'js/utils/link';
import { useRouteParams } from 'js/hooks/useRouteParams';

export const PageLoginAsAffiliate = (): ReactElement => {
	const [t] = useTranslation(securityConfig.trNamespace);
	const navigate = useNavigate();
	const { redirectTo } = useLocationSearchParams<{ redirectTo?: string }>();
	const loginAsAffiliate = useLoginAsAffiliate();
	const params = useRouteParams<TLoginAsAffiliateFormData>();

	useEffect(() => {
		loginAsAffiliate(params).then((result) => {
			if (result.success) {
				const navigateObject = navigateLinkToNavigateObject(
					result.redirectTo || redirectTo || RouteName.HOMEPAGE.DEFAULT,
				);
				navigate(navigateObject.to, navigateObject.options);
				return;
			}

			toast.error(<Toast>{t(result.message)}</Toast>);
			navigate(RouteName.SECURITY.LOGIN);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<DynamicPageTitleItem text={t('page.loginAsAffiliate.title')} />
			<LoadingFullScreenPlaceholder>
				<H3 bold>{t('page.loginAsAffiliate.loading')}</H3>
			</LoadingFullScreenPlaceholder>
		</>
	);
};
