import React, { PropsWithChildren, useContext } from 'react';
import { TChargeOrderId, TCurrency } from 'types';
import { IEntityRefundableAmount, IInitiateRefundForm } from 'submodule/refunds';
import { useTranslation } from 'react-i18next';
import { useApiChargeOrder } from 'js/chargeOrder/useApiChargeOrder';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { Alert, Toast } from '@avast/react-ui-components';
import { createRefundRequest, getInitialFormData } from 'submodule/refunds/refundUtils';
import { logDebug } from 'js/utils/app';
import { API_REFUNDS_KEYS, useApiRefundableAmount, useApiRefundProcess } from 'submodule/refunds/hooks/useApiRefunds';
import { toast } from 'react-toastify';
import { useAsyncInvalidateQueries } from 'js/hooks/useInvalidateQueries';
import type { TFunction } from 'i18next';
import { InitiateRefundFormEnum } from 'submodule/refunds/enums';
import { useApiErrorContext, useAppContext, useAuthContext } from 'js/contexts';
import { isEmpty } from 'lodash';
import { FormikConfig } from 'formik';
import { FormValues, useInitiateRefundFormValidator } from 'submodule/refunds/hooks/useInitiateRefundFormValidator';
import type { ObjectSchema, Shape } from 'yup';
import { resolveRefundProcessApiError } from 'submodule/refunds/apiError';

interface IInitiateRefundContextProps {
	chargeOrderId: TChargeOrderId;
	onSuccess: () => Promise<unknown>;
	onCancel: () => void;
	onChangeView: (step: InitiateRefundFormEnum) => void;
}

export interface IInitiateRefundContext {
	currency: TCurrency;
	t: TFunction<'submodules'>;
	onChangeView: (step: InitiateRefundFormEnum) => void;
	refundTypes: IEntityRefundableAmount['refundTypes'];
	canPartialRefund: boolean;
	onSubmit: FormikConfig<IInitiateRefundForm>['onSubmit'];
	initialValues: IInitiateRefundForm;
	onCancel: IInitiateRefundContextProps['onCancel'];
	validationSchema: ObjectSchema<Shape<undefined | object, FormValues>>;
}

const InitiateRefundContext = React.createContext<IInitiateRefundContext>({} as IInitiateRefundContext);
InitiateRefundContext.displayName = 'InitiateRefundContext';

export const useInitiateRefundContext = () => {
	const context = useContext(InitiateRefundContext);

	if (isEmpty(context)) {
		throw new Error('useInitiateRefundContext hook must be wrapped in InitiateRefundContextProvider');
	}

	return context;
};

export const InitiateRefundContextProvider = (props: PropsWithChildren<IInitiateRefundContextProps>) => {
	const [t] = useTranslation('submodules');
	const { loadingModalRef } = useAppContext();
	const { setError } = useApiErrorContext();
	const { isRoleSalesOperations } = useAuthContext();
	const { data: chargeOrder, query: chargeOrderQuery } = useApiChargeOrder({ id: props.chargeOrderId });
	const { data: refundableAmount, query: refundableAmountQuery } = useApiRefundableAmount({ id: props.chargeOrderId });
	const refundProcess = useApiRefundProcess();
	const invalidateQueries = useAsyncInvalidateQueries(Object.values(API_REFUNDS_KEYS));
	const { validationSchema } = useInitiateRefundFormValidator();
	const canPartialRefund = isRoleSalesOperations;

	if (chargeOrderQuery.isFetching || refundableAmountQuery.isFetching) {
		return <LoadingPlaceholder type="BAR" />;
	}

	if (!chargeOrder || !refundableAmount || !props.chargeOrderId || !chargeOrder.currency) {
		return (
			<Alert
				variant="danger"
				caption={t('refunds.error.refundableAmount')}
			/>
		);
	}

	if (!refundableAmount.refundTypes.some((type) => type.isEnabled)) {
		return (
			<Alert
				variant="danger"
				caption={t('refunds.error.noRefundTypeEnabled')}
			/>
		);
	}

	const initialValues = getInitialFormData(chargeOrder, refundableAmount);

	const onSubmit: IInitiateRefundContext['onSubmit'] = async (values, formikHelpers) => {
		const refundRequest = createRefundRequest(values, canPartialRefund);

		logDebug('submit', refundRequest);
		loadingModalRef.current?.show({
			title: t('refunds.initiate.process.loading'),
		});

		const response = await refundProcess.mutateAsync(refundRequest).catch((error) => {
			setError({ error, resolve: resolveRefundProcessApiError });
		});

		await invalidateQueries();

		loadingModalRef.current?.hide();
		formikHelpers.setSubmitting(false);
		refundableAmountQuery.remove();

		if (response) {
			// Success
			if (response.data) {
				await props.onSuccess();
			}

			// Not processed
			else {
				toast.error(<Toast caption={t('refunds.initiate.process.error')} />);
			}
		}
	};

	const value: IInitiateRefundContext = {
		t,
		onChangeView: props.onChangeView,
		currency: chargeOrder.currency,
		refundTypes: refundableAmount.refundTypes,
		canPartialRefund,
		initialValues,
		onCancel: props.onCancel,
		onSubmit,
		validationSchema,
	};

	return <InitiateRefundContext.Provider value={value}>{props.children}</InitiateRefundContext.Provider>;
};
