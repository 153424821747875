import { SelectPartyEnum, SelectPartyOptionEnum } from '../enums';
import React, { PropsWithChildren } from 'react';
import { useSelectPartyTranslation } from '../hooks';
import { SelectPartyCard, SelectPartyCardBody, SelectPartyCardFooter } from '../common';
import { Alert } from '@avast/react-ui-components';
import { TSelectPartyModalContentProps } from './types';
import { IEntityDistributionPartner } from 'module/distributionPartners';
import { DistributionPartnerCard } from 'module/distributionPartners/components';
import { isDefined } from 'js/utils/common';

type TSelectDistributionPartnerCardProps = TSelectPartyModalContentProps<IEntityDistributionPartner> & {
	option: SelectPartyOptionEnum;
	distributionPartner?: IEntityDistributionPartner | null;
};

export const SelectDistributionPartnerCard = (props: PropsWithChildren<TSelectDistributionPartnerCardProps>) => {
	const { distributionPartner, state, onResolve } = props;
	const { tPartyOption } = useSelectPartyTranslation(SelectPartyEnum.DISTRIBUTION_PARTNER);

	const isNotAllowed = isDefined(distributionPartner) && distributionPartner.distributorId !== state.partner?.id;

	return (
		<SelectPartyCard
			type={SelectPartyEnum.DISTRIBUTION_PARTNER}
			option={props.option}
		>
			<SelectPartyCardBody>
				{(() => {
					if (!distributionPartner) {
						return <Alert variant="warning">{tPartyOption(props.option, 'empty')}</Alert>;
					}

					return (
						<>
							<DistributionPartnerCard distributionPartner={distributionPartner} />
							{isNotAllowed && (
								<Alert
									className="mt-3 mb-0"
									variant="danger"
								>
									{tPartyOption(props.option, 'notAllowed')}
								</Alert>
							)}
						</>
					);
				})()}
			</SelectPartyCardBody>

			<SelectPartyCardFooter
				option={props.option}
				disabled={isNotAllowed}
				onSelect={() => onResolve(distributionPartner)}
			>
				{tPartyOption(
					props.option,
					distributionPartner || props.option === SelectPartyOptionEnum.ORDER ? 'button' : 'buttonEmpty',
				)}
			</SelectPartyCardFooter>
		</SelectPartyCard>
	);
};
