import React from 'react';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { Alert } from '@avast/react-ui-components';
import { DataTable, DataTableTr } from 'js/components/molecules/DataTable';
import { numberFormatter } from 'js/utils/number';
import { IStandardOrderInstanceItem, IStandardOrderItemPricing } from 'module/purchase';
import { useOrderContext } from 'js/contexts';
import { isDefined } from 'js/utils/common';
import { standardOrderApprovalFlagEnumUtils } from 'module/purchase/enums';

type TOrderTableRowDiscretionaryDiscountStatusProps = {
	rowPricing: IStandardOrderItemPricing | null;
	row: IStandardOrderInstanceItem;
};

export const OrderTableRowDiscretionaryDiscountStatus = (props: TOrderTableRowDiscretionaryDiscountStatusProps) => {
	const { rowPricing, row } = props;
	const { orderState, billableParty, hasLineDiscount, discountApprovalFlag } = useOrderContext();
	const [t] = useTranslation(purchaseConfig.trNamespace);

	// Component states
	const instanceLineHasDiscount = isDefined(row.discretionaryDiscount);
	const instanceHasDiscount = isDefined(orderState.discretionaryDiscount);
	const isLoading = !orderState.pricing;
	const isValuesLoading = isLoading && (instanceHasDiscount || instanceLineHasDiscount);

	if (!instanceHasDiscount && (!hasLineDiscount || instanceHasDiscount)) {
		return null;
	}

	// No discount
	if (!instanceLineHasDiscount && !instanceHasDiscount) {
		return <Alert caption={t('discretionaryDiscount.status.lineNotSet')} />;
	}

	// Has discount or pricing is loading
	return (
		<>
			<hr />
			<DataTable
				variant="simple"
				contentWidth
			>
				<DataTableTr
					name={t('discretionaryDiscount.approvalFlag')}
					bold
					isLoading={isValuesLoading}
				>
					{standardOrderApprovalFlagEnumUtils.getText(discountApprovalFlag)}
				</DataTableTr>
				<DataTableTr
					name={t('discretionaryDiscount.amountUnit')}
					bold
					isLoading={isValuesLoading}
				>
					{numberFormatter.currency(rowPricing?.discountLineFlat, billableParty?.currencyCode)}
				</DataTableTr>
				<DataTableTr
					name={t('discretionaryDiscount.amountTotal')}
					bold
					isLoading={isValuesLoading}
				>
					{numberFormatter.currency(
						(rowPricing?.discountLineFlat || 0) * (rowPricing?.unitsToPrice || 1),
						billableParty?.currencyCode,
					)}
				</DataTableTr>
			</DataTable>
		</>
	);
};
