import { TApiErrorResolve } from 'types/api';
import { customersConfig } from 'module/customers/customersConfig';
import i18n from 'i18next';
import { apiErrorResolveError } from 'js/utils/apiError';
import { API_ERROR_CUSTOMER } from 'module/customers/constants';

export const createCustomerApiError: TApiErrorResolve = (error) => {
	const messages = apiErrorResolveError(error, API_ERROR_CUSTOMER);

	return {
		title: i18n.t(customersConfig.trPrefix('error.createCustomer')),
		messages,
	};
};

export const updateCustomerApiError: TApiErrorResolve = (error) => {
	const messages = apiErrorResolveError(error, API_ERROR_CUSTOMER);

	return {
		title: i18n.t(customersConfig.trPrefix('error.updateCustomer')),
		messages,
	};
};
