import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { noValuePlaceholder } from 'js/utils/app';
import Skeleton from 'react-loading-skeleton';
import { numberFormatter } from 'js/utils/number';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { IProductGroupWithPrice } from 'module/purchase/hooks/useSelectProductsGroupsWithPrices';
import { TableColumnsDef } from 'types';
import { usePartyPriceTranslation } from 'module/purchase/hooks/usePartyPriceTranslation';
import { IEntityProductGroup } from 'module/purchase';
import { useOrderContext } from 'js/contexts';
import { marketSegmentEnumUtils } from 'js/enums';

type TTableData = IProductGroupWithPrice;
type TSelectProductsColumnsProps = {
	isLoading?: boolean;
	onClick: (group: IEntityProductGroup) => void;
};

export const useSelectProductsColumns = (props: TSelectProductsColumnsProps): TableColumnsDef<TTableData> => {
	const { isLoading, onClick } = props;
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const { tPartyPrice } = usePartyPriceTranslation();
	const { billableParty, isEndCustomerBillableParty, orderMarketSegments } = useOrderContext();
	const currencyCode = billableParty?.currencyCode;
	const hasMultiMarketSegments = orderMarketSegments.length > 1;

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		const columns = [
			columnHelper.text('code', { header: t('common:entity.sku') }),
			columnHelper.text('name', {
				header: t('common:entity.productName'),
				meta: {
					formatters: [formatter.bold],
				},
			}),
		];

		if (hasMultiMarketSegments) {
			columns.push(
				columnHelper.text('marketSegment', {
					header: t('entity.marketSegment'),
					meta: {
						defaultValue: { value: noValuePlaceholder() },
						formatters: [marketSegmentEnumUtils.getText],
					},
				}),
			);
		}

		columns.push(
			columnHelper.text('unitPriceSrp', {
				header: tPartyPrice('unitPrice', 'customer'),
				meta: {
					defaultValue: { value: 0, applyFormatters: true },
					formatters: [
						(value: number) => {
							if (isLoading) {
								return <Skeleton width={100} />;
							}
							return t('pricing.basePrice', { price: numberFormatter.currency(value, currencyCode) });
						},
					],
				},
			}),
		);

		if (!isEndCustomerBillableParty) {
			columns.push(
				columnHelper.text('unitPrice', {
					header: tPartyPrice('unitPrice'),
					meta: {
						defaultValue: { value: 0, applyFormatters: true },
						formatters: [
							(value: number) => {
								if (isLoading) {
									return <Skeleton width={100} />;
								}
								return t('pricing.basePrice', { price: numberFormatter.currency(value, currencyCode) });
							},
						],
					},
				}),
			);
		}

		columns.push(
			columnHelper.actions({
				button: {
					children: t('table.action.selectVariant'),
					onClick,
					testId: 'selectVariant',
				},
			}),
		);

		return columns;
	}, [t, currencyCode, tPartyPrice, isLoading, isEndCustomerBillableParty, hasMultiMarketSegments, onClick]);
};
