import { Button } from '@avast/react-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { IEntityLicense } from 'module/licenses';
import { useRegisterCustomerToLicense } from 'module/licenses/hooks';

export const LicenseRegistrationButton = ({ license }: { license: IEntityLicense }) => {
	const [t] = useTranslation(licensesConfig.trNamespace);
	const { isEnabled, initRegisterCustomerToLicense } = useRegisterCustomerToLicense(license);

	return (
		<Button
			variant="outline-primary"
			size="sm"
			onClick={() => initRegisterCustomerToLicense(license)}
			disabled={!isEnabled}
			testId="registerCustomerToLicense"
		>
			{t('actions.registerTo')}
		</Button>
	);
};
