import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { useMemo } from 'react';
import { IEntityClosingBalance, IStatementDetailItem } from 'module/closingBalance';
import { TableColumnsDef } from 'types';
import { useTranslation } from 'react-i18next';
import { closingBalanceConfig } from 'module/closingBalance/closingBalanceConfig';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import { TAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { TAsyncStatementDetailModalProps } from 'module/closingBalance/components/modal/AsyncStatementDetailModal';

type TTableData = IStatementDetailItem;

export const useClosingBalanceStatementColumns = (
	statementDetailRef: TAsyncModalRef<TAsyncStatementDetailModalProps>,
): TableColumnsDef<TTableData> => {
	const [t] = useTranslation(closingBalanceConfig.trNamespace);
	const { data } = usePageDetailContext<IEntityClosingBalance>();
	const { currency } = data;

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.text('id', { header: t('entity.lineDetailId') }),
			columnHelper.ellipsis('product.description', {
				header: t('common:entity.productName'),
				meta: { formatters: [formatter.bold] },
			}),
			columnHelper.text('product.naturalSku', { header: t('common:entity.sku') }),
			columnHelper.integer('pricedQuantity', { header: t('common:entity.quantity') }),
			columnHelper.money(
				'price.withoutTax',
				{
					header: t('common:entity.price'),
					meta: { defaultValue: null },
				},
				{ currency: currency || '' },
			),
			columnHelper.money(
				'price.withTax',
				{
					header: t('common:entity.priceWTax'),
					meta: { defaultValue: null },
				},
				{ currency: currency || '' },
			),
			columnHelper.actions({
				button: {
					children: t('common:actions.detail'),
					onClick(statementDetail) {
						statementDetailRef.current?.show({ statementDetail });
					},
					testId: 'detail',
				},
			}),
		];
	}, [t, currency, statementDetailRef]);
};
