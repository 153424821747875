import React, { MutableRefObject, PropsWithChildren, ReactElement, TdHTMLAttributes } from 'react';
import classNames from 'classnames';

type TCustomTdProps = TdHTMLAttributes<HTMLTableCellElement> & {
	foot?: boolean;
	forwardedRef?: MutableRefObject<HTMLTableCellElement>;
	forwardedInnerRef?: MutableRefObject<HTMLElement | null>;
};

export const _CustomTd = (props: PropsWithChildren<TCustomTdProps>): ReactElement => {
	const { className, children, foot, forwardedRef, forwardedInnerRef, ...rest } = props;
	const _classNames = classNames(
		'cell',
		{
			'cell-body': !foot,
			'cell-foot': foot,
		},
		className,
	);

	return (
		<td
			role="cell"
			className={_classNames}
			ref={forwardedRef}
			{...rest}
		>
			<span ref={forwardedInnerRef}>{children}</span>
		</td>
	);
};
