import React, { useEffect } from 'react';
import { titleStore } from 'js/components/molecules/DynamicPageTitle';

export type TDynamicPageTitleItemProps = string;

export const DynamicPageTitleItem = ({ text }: { text: TDynamicPageTitleItemProps }) => {
	const { setTitlePart, removeTitlePart } = titleStore();

	useEffect(() => {
		// Add the title part
		setTitlePart(text);

		return () => {
			// Remove the title part when unmounting
			removeTitlePart();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <></>;
};
