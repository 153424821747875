import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Headline } from 'js/components/molecules/Headline';
import { H1 } from '@avast/react-ui-components';
import { OrderHoldAlert } from 'module/security/components/OrderHoldAlert';
import { useApiSetIntroGuideDisplayed } from 'module/partners/hooks/useApiPartners';
import { HomepageActions } from 'module/homepage/components/HomepageActions';
import { homepageConfig } from 'module/homepage/homepageConfig';
import { Announcements } from 'submodule/Announcements';
import { WidgetsView } from 'submodule/widgets/view';
import { useWidgetContext } from 'submodule/widgets/context';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { EditWidgetButton } from './EditWidgetButton';
import { useAppContext, useAuthContext } from 'js/contexts';

export const HomepageContent = (): ReactElement => {
	const [t] = useTranslation(homepageConfig.trNamespace);
	const { authPartner, setAuthPartner, isGroupPartner } = useAuthContext();
	const { helpModalRef } = useAppContext();
	const { loading, config } = useWidgetContext();
	const { mutate: setGuideDisplayed } = useApiSetIntroGuideDisplayed({
		config: { params: { affiliateId: authPartner?.id } },
	});

	useEffect(() => {
		if (authPartner?.shouldSeeIntroGuide && isGroupPartner) {
			helpModalRef.current?.show();

			setGuideDisplayed(
				{},
				{
					onSuccess(response) {
						setAuthPartner(response.data);
					},
				},
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) {
		return <LoadingPlaceholder />;
	}

	return (
		<>
			<Headline
				className="align-items-center"
				rightContent={<HomepageActions />}
			>
				<div className="d-flex align-items-center">
					<H1 type="h2">{t('app:appName')}</H1>
					<EditWidgetButton />
				</div>
			</Headline>

			<Announcements
				className="mt-3"
				placement={isGroupPartner ? 'HOMEPAGE_PARTNER' : 'HOMEPAGE_INTERNAL'}
			/>

			<OrderHoldAlert className="my-3" />
			<WidgetsView config={config} />
		</>
	);
};
