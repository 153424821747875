import React, { ReactElement } from 'react';
import { TDetailArguments } from 'types';
import { useRouteParams } from 'js/hooks/useRouteParams';
import { creditStatusConfig } from 'module/creditStatus/creditStatusConfig';
import { useTabsTestAttributes } from 'js/hooks/testAttributes/useTabsTestAttributes';
import { useTranslation } from 'react-i18next';
import { useApiCreditStatus } from 'module/creditStatus/hooks/useApiCreditStatus';
import { getCreditStatusRenderName } from 'module/creditStatus/utils/creditStatusSelectors';
import { buildParametrizedRoute } from 'js/utils/common';
import { RouteName } from 'module/RouteName';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { CreditStatusDetail, CreditTransactionTable } from 'module/creditStatus/components';
import { PageDetailComponent } from 'js/layouts/page/PageDetailComponent';
import { Tab, Tabs } from 'react-bootstrap';
import { IEntityCreditStatus, IEntityCreditStatusFilter } from 'module/creditStatus/index';
import { Alert } from '@avast/react-ui-components';
import type { TFunction } from 'i18next';

type TData = IEntityCreditStatus;
type TArguments = TDetailArguments;
type TQueryFilter = IEntityCreditStatusFilter;
type TDetailProps = TQueryFilter;
type TErrorContent = (t: TFunction) => ReactElement;

export const PageDetail = (props: Partial<TDetailProps>) => {
	const { id = props.id } = useRouteParams<TArguments>();
	const [t] = useTranslation(creditStatusConfig.trNamespace);
	const testAttributes = useTabsTestAttributes('creditStatus');

	return (
		<PageDetailComponent<TData, TQueryFilter>
			query={useApiCreditStatus}
			queryProps={{ id }}
			trNamespace={creditStatusConfig.trNamespace}
			titleRender={getCreditStatusRenderName}
			getErrorContent={() => getErrorContent(t)}
			detailLink={buildParametrizedRoute(RouteName.CREDIT_STATUS.DETAIL, id)}
		>
			<Tabs
				defaultActiveKey="detail"
				{...testAttributes}
			>
				<Tab
					eventKey="detail"
					title={t('tabs.detail')}
				>
					<DefaultContainer>
						<CreditStatusDetail />
					</DefaultContainer>
				</Tab>
				<Tab
					eventKey="transactions"
					title={t('tabs.transactions')}
				>
					<DefaultContainer>
						<CreditTransactionTable />
					</DefaultContainer>
				</Tab>
			</Tabs>
		</PageDetailComponent>
	);
};

const getErrorContent: TErrorContent = (t): ReactElement => (
	<Alert
		variant="danger"
		caption={t('error.creditStatusNotFound')}
	/>
);
