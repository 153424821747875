import { Button, Modal } from '@avast/react-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';

type TSelectPartyModalFooterProps = {
	onClick(): void;
};

export const SelectPartyModalFooter = (props: TSelectPartyModalFooterProps) => {
	const [t] = useTranslation(licensesConfig.trNamespace);

	return (
		<Modal.Footer
			cancelButton={false}
			className="d-block"
		>
			<Button
				size="sm"
				onClick={props.onClick}
				iconLeftFa={faChevronLeft}
				variant="outline-primary"
				testId="back"
			>
				{t('common:actions.back')}
			</Button>
		</Modal.Footer>
	);
};
