import { TPartnerId } from 'types';
import { useAuthContext } from 'js/contexts';
import { useApiPartner } from 'module/partners/hooks/useApiPartners';
import { IEntityPartner } from 'module/partners/index';

type TUsePartnerById = {
	partner: IEntityPartner | null;
	isLoading: boolean;
};

export const usePartnerById = (partnerId?: TPartnerId | null): TUsePartnerById => {
	const { authCompany } = useAuthContext();
	const isPartnerLogged = Boolean(partnerId) && partnerId === authCompany?.id;
	const { data, query } = useApiPartner({ id: partnerId, queryConfig: { enabled: !isPartnerLogged } });

	if (!partnerId) {
		return {
			partner: null,
			isLoading: false,
		};
	}

	if (isPartnerLogged) {
		return {
			partner: authCompany,
			isLoading: false,
		};
	}

	return {
		partner: data,
		isLoading: query.isLoading,
	};
};
