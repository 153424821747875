import { Alert, Card, CardBody, CardHeader } from '@avast/react-ui-components';
import { DiscretionaryDiscountForm } from 'module/purchase/forms/DiscretionaryDiscountForm';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { OrderTableRowDiscretionaryDiscountStatus } from './OrderTableRowDiscretionaryDiscountStatus';
import { IStandardOrderInstanceItem, IStandardOrderItemPricing } from 'module/purchase';
import { useOrderContext } from 'js/contexts';

type TOrderTableRowDiscretionaryDiscountProps = {
	name: string;
	row: IStandardOrderInstanceItem;
	rowPricing: IStandardOrderItemPricing | null;
	className?: string;
};

export const OrderTableRowDiscretionaryDiscount = (props: TOrderTableRowDiscretionaryDiscountProps) => {
	const { name, row, rowPricing, className } = props;
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const {
		orderState: { discretionaryDiscount },
	} = useOrderContext();
	const isEnable = !discretionaryDiscount;

	return (
		<Card className={className}>
			<CardHeader>{t('discretionaryDiscount.title')}</CardHeader>
			<CardBody>
				{(() => {
					if (isEnable) {
						return <DiscretionaryDiscountForm name={name} />;
					}

					return <Alert variant="warning">{t('discretionaryDiscount.status.orderLevelDiscountIsSet')}</Alert>;
				})()}
				<OrderTableRowDiscretionaryDiscountStatus
					row={row}
					rowPricing={rowPricing}
				/>
			</CardBody>
		</Card>
	);
};
