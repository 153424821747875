import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import {
	useLicenseOperationPartyResolver,
	useLicenseOperationToOrderItem,
} from 'module/licenses/hooks/licenseOperation';
import { isLicenseOperationAlreadyInOrder } from 'module/licenses/utils/licenseOperation';
import { useLicenseOperationContext } from 'module/licenses/context/LicenseOperationContext';
import { ILicenseOperationForm } from 'module/licenses';
import { usePriceListGuard } from 'module/purchase/hooks/guards';
import { useMaxCartItemsGuard } from 'module/purchase/hooks/guards/useMaxCartItemsGuard';
import { useAppContext, useAuthContext, useOrderContext } from 'js/contexts';
import { CustomerSourceEnum } from 'module/customers/enums';

export const useLicenseOperationAddToOrder = () => {
	const { confirmationModalRef } = useAppContext();
	const { isGroupPartner } = useAuthContext();
	const licenseOperationContext = useLicenseOperationContext();
	const { orderState, updateParties, hasBillableParty, addItems } = useOrderContext();
	const [t] = useTranslation(licensesConfig.trNamespace);
	const operationToOrderItem = useLicenseOperationToOrderItem();
	const licenseOperationPartyResolver = useLicenseOperationPartyResolver();
	const priceListGuard = usePriceListGuard();
	const maxCartItemsGuard = useMaxCartItemsGuard();
	const isEmptyPartnerOrder =
		isGroupPartner && orderState.items.length === 0 && !orderState.customer && !orderState.distributionPartner;

	return useCallback(
		async (values: ILicenseOperationForm): Promise<boolean> => {
			const {
				license,
				licenseHasBillableParties,
				partner,
				customer,
				customerSource,
				distributionPartner,
				priceListCode,
				partnerPriceLists,
			} = licenseOperationContext;
			const { licenseBillableParty } = values;
			const orderItem = operationToOrderItem(values);
			const isLicenseCustomerLinkedWithOrderPartner = Boolean(customer)
				? customerSource === CustomerSourceEnum.PARTNER
				: true;

			if (!orderItem || !priceListCode) {
				return false;
			}

			// Check max items in a cart
			if (!(await maxCartItemsGuard([orderItem]))) {
				return false;
			}

			// Get errors
			const licenseAlreadyInOrder = isLicenseOperationAlreadyInOrder(license, orderState);

			// License already in the order -> rewrite or keep
			if (licenseAlreadyInOrder) {
				if (
					!(await confirmationModalRef.current?.show({
						title: t('error.operation.alreadyInCart.title'),
						messages: [t('error.operation.alreadyInCart.message')],
						submitButtonText: t('error.operation.actions.rewriteOperation'),
					}))
				) {
					return false;
				}
			}

			// Resolve billable parties
			if (!hasBillableParty || (isEmptyPartnerOrder && isLicenseCustomerLinkedWithOrderPartner)) {
				updateParties(
					{
						partner,
						partnerPriceLists,
						distributionPartner,
						customer,
					},
					licenseBillableParty?.currencyCode,
				);
			} else if (licenseHasBillableParties) {
				const partyResolution = await licenseOperationPartyResolver();
				if (!partyResolution) {
					return false;
				}

				// Check price lists
				const guard = await priceListGuard(priceListCode);
				if (!guard) {
					return false;
				}

				updateParties(partyResolution, licenseBillableParty?.currencyCode);
			}

			addItems([orderItem], priceListCode);
			return true;
		},
		[
			confirmationModalRef,
			orderState,
			hasBillableParty,
			isEmptyPartnerOrder,
			t,
			addItems,
			priceListGuard,
			maxCartItemsGuard,
			licenseOperationContext,
			updateParties,
			operationToOrderItem,
			licenseOperationPartyResolver,
		],
	);
};
