import { TableColumnsDef } from 'types';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { IEntityDistributionPartner } from 'module/distributionPartners';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';

type TTableData = IEntityDistributionPartner;

export const useSelectDistributionPartnerColumns = (): TableColumnsDef<TTableData> => {
	const [t] = useTranslation(distributionPartnersConfig.trNamespace);

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.hidden('id'),
			columnHelper.hidden('taxId'),
			columnHelper.ellipsis('name', {
				header: t('common:entity.company'),
				meta: { formatters: [formatter.bold] },
			}),
			columnHelper.text('contact.firstName', { header: t('common:contact.firstName') }),
			columnHelper.text('contact.lastName', { header: t('common:contact.lastName') }),
			columnHelper.text('contact.email', { header: t('common:contact.email') }),
		];
	}, [t]);
};
