import React, { ReactElement } from 'react';
import { initial } from 'lodash';
import { Breadcrumbs } from '@avast/react-ui-components';
import { Link } from 'react-router-dom';
import type { Location } from '@remix-run/router';
import { useAppContext } from 'js/contexts';
import { create } from 'zustand';
import {
	isLoadingItem,
	TDynamicBreadcrumbsItem,
	TDynamicBreadcrumbsItemProps,
} from 'js/components/molecules/DynamicBreadcrumbs';
import Skeleton from 'react-loading-skeleton';

interface IDynamicBreadcrumbsStore {
	breadcrumbsParts: TDynamicBreadcrumbsItemProps[];
	setBreadcrumbsPart: (breadcrumbsParts: TDynamicBreadcrumbsItemProps) => void;
	removeBreadcrumbsPart: () => void;
}

const DISABLED_BREADCRUMB_LINK = '';

const breadcrumbsStore = create<IDynamicBreadcrumbsStore>()((set) => ({
	breadcrumbsParts: [],
	setBreadcrumbsPart: (newBreadcrumbPart) =>
		set((state) => ({ breadcrumbsParts: [...state.breadcrumbsParts, newBreadcrumbPart] })),
	removeBreadcrumbsPart: () => set((state) => ({ breadcrumbsParts: initial(state.breadcrumbsParts) })),
}));

/**
 * Prepare breadcrumb item to render
 * @param {TDynamicBreadcrumbsItemProps} props
 * @param {Location|null} prevLocation
 * @returns {TBreadcrumbsItemProps<LinkProps>}
 */
const prepareItem = (props: TDynamicBreadcrumbsItem, prevLocation?: Location | null): TDynamicBreadcrumbsItem => ({
	...props,
	linkAs: Link,
	linkProps: {
		to: prevLocation || props.href,
		state: { back: true },
		...props.linkProps,
		children: props.children,
	},
});

/**
 * Container component that renders breadcrumb items, that were collected
 */
const DynamicBreadcrumbs = (): ReactElement => {
	const items = breadcrumbsStore((state) => state.breadcrumbsParts);
	const { prevLocationListener } = useAppContext();
	let prevLocationIndex = 1;

	const preparedItems = items.map((item) => {
		if (isLoadingItem(item) || item.href === DISABLED_BREADCRUMB_LINK) {
			return item;
		}

		// Get number of steps to go in location history
		const prevLocation = prevLocationListener.resolveByPathname(item.href, prevLocationIndex);
		if (prevLocation) {
			prevLocationIndex += 1;
		}

		return prepareItem(item, prevLocation);
	});

	return (
		<Breadcrumbs light>
			{preparedItems.map((item, index) => {
				const key = `dynamic-breadcrumbs-item-${index}`;

				if (isLoadingItem(item)) {
					return (
						<Breadcrumbs.Item
							key={key}
							active={true}
						>
							<Skeleton width={100} />
						</Breadcrumbs.Item>
					);
				}

				const isLastItem = index === preparedItems.length - 1;
				const isActiveItem = isLastItem || item.href === DISABLED_BREADCRUMB_LINK;

				return (
					<Breadcrumbs.Item
						key={key}
						active={isActiveItem}
						{...item}
					/>
				);
			})}
		</Breadcrumbs>
	);
};

export { DynamicBreadcrumbs, breadcrumbsStore, DISABLED_BREADCRUMB_LINK };
