import { TableColumnsDef } from 'types';
import { IEntityOrder, IEntityOrderItem } from 'module/orders';
import React, { useMemo } from 'react';
import { usePageDetailContext } from 'js/contexts';
import {
	canShowOrderItemPriceDetails,
	hasAvailableLicenses,
	hasQuotedCustomPrices,
	normalizeSku,
} from 'module/orders/utils/common';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import { usePartyPriceTranslation } from 'module/purchase/hooks/usePartyPriceTranslation';
import { TAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { TPriceDetailModalProps } from 'module/orders/components/AsyncPriceDetailModal';
import { TLicenseModalProps } from 'module/orders/components/AsyncLicensesModal';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { noValuePlaceholder } from 'js/utils/app';
import { Dropdown } from '@avast/react-ui-components';
import { toInteger } from 'lodash';

type TItemsInOrderColumnsProps = {
	priceDetailRef: TAsyncModalRef<TPriceDetailModalProps>;
	licensesRef: TAsyncModalRef<TLicenseModalProps>;
};

type TTableData = IEntityOrderItem;

export const useItemsInOrderColumns = (props: TItemsInOrderColumnsProps): TableColumnsDef<TTableData> => {
	const { priceDetailRef, licensesRef } = props;

	const [t] = useTranslation(ordersConfig.trNamespace);
	const { data } = usePageDetailContext<IEntityOrder>();
	const hasLicenses = hasAvailableLicenses(data);
	const hasQuotedPrices = hasQuotedCustomPrices(data.lineItems);
	const { tPartyPrice } = usePartyPriceTranslation();

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		const columns = [
			columnHelper.text('product.sku', {
				header: t('common:entity.sku'),
				meta: { formatters: [normalizeSku] },
			}),
			columnHelper.ellipsis('product.description', {
				header: t('common:entity.productName'),
				meta: { formatters: [formatter.bold] },
			}),
		];

		if (!data.isPayAsYouGoOrder) {
			columns.push(
				columnHelper.text('transactionType', { header: t('common:entity.transaction') }),
				columnHelper.text('ems.order.validity', {
					header: t('common:entity.validity'),
					meta: {
						defaultValue: { value: noValuePlaceholder() },
						formatters: [(value) => t('common:datePeriod.MONTH', { count: toInteger(value) })],
					},
				}),
				columnHelper.integer('computedValues.quantity', {
					header: t('common:entity.quantity'),
					meta: { defaultValue: { value: noValuePlaceholder() } },
				}),
			);
		}

		columns.push(
			columnHelper.integer('computedValues.unit', {
				header: t('common:entity.unit', { count: 0 }),
				meta: { defaultValue: { value: noValuePlaceholder() } },
			}),
		);

		if (!data.isPayAsYouGoOrder) {
			columns.push(
				columnHelper.money(
					'computedValues.price.customer.total',
					{
						header: tPartyPrice('price', data.computedValues.price.customer.prefixType),
						meta: { defaultValue: { value: noValuePlaceholder() } },
					},
					{ currency: data.currency || '' },
				),
			);
		}

		if (hasQuotedPrices) {
			columns.push(
				columnHelper.money(
					'computedValues.price.quotedCustomer.total',
					{
						header: tPartyPrice('price', 'quotedCustomer'),
						meta: { defaultValue: { value: noValuePlaceholder() } },
					},
					{ currency: data.currency || '' },
				),
			);
		}

		if (!data.computedValues.isEndCustomerOrder && !data.isPayAsYouGoOrder) {
			columns.push(
				columnHelper.money(
					'computedValues.price.partner.total',
					{
						header: tPartyPrice('price'),
					},
					{ currency: data.currency || '' },
				),
			);
		}

		if (data.isPayAsYouGoOrder) {
			columns.push(
				columnHelper.money(
					'computedValues.price.partner.unit',
					{
						header: tPartyPrice('unitPrice'),
						meta: { defaultValue: { value: noValuePlaceholder() } },
					},
					{ currency: data.currency || '' },
				),
				columnHelper.money(
					'computedValues.price.partner.total',
					{
						header: tPartyPrice('price'),
						meta: { defaultValue: { value: noValuePlaceholder() } },
					},
					{ currency: data.currency || '' },
				),
			);
		} else {
			columns.push(
				columnHelper.actions({
					dropdown: {
						items(orderItem) {
							const list = [];
							if (canShowOrderItemPriceDetails(orderItem)) {
								list.push(
									<Dropdown.Item
										onClick={() => priceDetailRef.current?.show({ order: data, orderItem })}
										testId="priceDetails"
									>
										{t('actions.priceDetails')}
									</Dropdown.Item>,
								);
							}

							if (hasLicenses) {
								list.push(
									<Dropdown.Item
										onClick={() => licensesRef.current?.show({ orderItem })}
										testId="licenses"
									>
										{t('actions.licenses')}
									</Dropdown.Item>,
								);
							}
							return list;
						},
					},
				}),
			);
		}

		return columns;
	}, [t, data, hasLicenses, priceDetailRef, licensesRef, tPartyPrice, hasQuotedPrices]);
};
