import React from 'react';
import { useInitiateRefundContext } from '../InitiateRefundContext';
import { Alert, UiTable } from '@avast/react-ui-components';
import { get } from 'lodash';
import { IInitiateRefundForm, IInitiateRefundTableItem } from 'submodule/refunds';
import { useFormikContext } from 'formik';
import { RefundTypeEnum, refundTypeEnumUtils } from 'submodule/refunds/enums';
import { useRefundProductsColumns } from 'submodule/refunds/hooks';

export const ProductsTable = () => {
	const { t } = useInitiateRefundContext();
	const { values, setFieldValue } = useFormikContext<IInitiateRefundForm>();
	const { refundType } = values;
	const listKey = refundType === RefundTypeEnum.KEY ? 'licenses' : 'items';
	const list: IInitiateRefundTableItem[] = get(values, listKey, []);
	const isSelectable = refundTypeEnumUtils.validateOneOf(refundType, [RefundTypeEnum.KEY, RefundTypeEnum.LINE]);
	const columns = useRefundProductsColumns();

	function isRowDisabled(row: IInitiateRefundTableItem) {
		return row.refundableAmount === 0;
	}

	if (refundType === RefundTypeEnum.KEY && list.length === 0) {
		return <Alert variant="warning">{t('refunds.license.noSelectedLicenses')}</Alert>;
	}

	return (
		<UiTable<IInitiateRefundTableItem>
			className="table-scrollableVertically"
			columns={columns}
			data={list}
			meta={{
				rowClassName: (row) => (isRowDisabled(row) ? 'disabled' : ''),
				isClickableRow: (row) => !isRowDisabled(row),
				tableProps: { className: 'table-autoHeight table-refunds table--sm' },
				isScrollable: true,
				isHighlightedRow: (row) => isSelectable && row.isSelected,
				onRowClick: isSelectable
					? (row) => {
							list.forEach((item, index) => {
								if (item.id === row.id) {
									setFieldValue(`${listKey}.${index}.isSelected`, !item.isSelected);
								}
							});
						}
					: undefined,
			}}
		/>
	);
};
