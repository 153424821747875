import { SelectPartyEnum, SelectPartyOptionEnum } from '../enums';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';

export const useSelectPartyTranslation = (party: SelectPartyEnum) => {
	const [t] = useTranslation(licensesConfig.trNamespace);

	const tParty = (key?: string) => t(`selectParty.${party}.${key}`);
	const tPartyOption = (option: SelectPartyOptionEnum, key?: string) => t(`selectParty.${party}.${option}.${key}`);

	return { tParty, tPartyOption };
};
