import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum QuoteDateRangeTypeEnum {
	CREATED = 'CREATED',
	EXPIRY = 'EXPIRY',
}

export const quoteDateRangeTypeEnumUtils = generateEnumUtils<QuoteDateRangeTypeEnum>(QuoteDateRangeTypeEnum, {
	translatePath: 'enums:dateRangeType',
});
