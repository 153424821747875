import { TUseApiPaginatedListModule, useApiPaginatedListQuery } from 'js/queries/useApiPaginatedListQuery';
import {
	ICreditLimitForm,
	ICreditLimitListApiFilter,
	ICreditLimitListFilter,
	IEntityCreditLimit,
} from 'module/creditLimit';
import { TUseApiMutationModule, useApiMutationQuery } from 'js/queries/useApiMutationQuery';
import { creditLimitFilterNormalizer } from 'module/creditLimit/normalizer';
import { ApiMutationTypeEnum } from 'js/enums';
import { API_CREDIT_STATUS_KEYS } from 'module/creditStatus/hooks/useApiCreditStatus';

const API_CREDIT_LIMIT_KEYS = {
	LIST: 'credit-limit',
	CREATE: 'credit-limit',
};

export const useApiCreditLimitList: TUseApiPaginatedListModule<
	IEntityCreditLimit,
	ICreditLimitListFilter,
	ICreditLimitListApiFilter
> = (props) =>
	useApiPaginatedListQuery(API_CREDIT_LIMIT_KEYS.LIST, props, {
		apiFilterNormalizer: (filter) => creditLimitFilterNormalizer.denormalize(filter),
	});

export const useApiCreateCreditLimit: TUseApiMutationModule<ICreditLimitForm, IEntityCreditLimit> = (props) => {
	return useApiMutationQuery(API_CREDIT_LIMIT_KEYS.CREATE, ApiMutationTypeEnum.POST, props, {
		invalidateQueries: [...Object.values(API_CREDIT_LIMIT_KEYS), ...Object.values(API_CREDIT_STATUS_KEYS)],
	});
};
