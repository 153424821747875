import React from 'react';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { Alert } from '@avast/react-ui-components';
import { DataTable, DataTableTr } from 'js/components/molecules/DataTable';
import { numberFormatter } from 'js/utils/number';
import { useOrderContext } from 'js/contexts';
import { isDefined } from 'js/utils/common';
import { standardOrderApprovalFlagEnumUtils } from 'module/purchase/enums';

export const DiscretionaryDiscountStatus = () => {
	const { orderState, billableParty, hasLineDiscount, discountApprovalFlag } = useOrderContext();
	const [t] = useTranslation(purchaseConfig.trNamespace);

	// Component states
	const instanceHasDiscount = isDefined(orderState.discretionaryDiscount);
	const isLoading = !orderState.pricing;
	const isValuesLoading = isLoading && instanceHasDiscount;

	// No discount
	if (!instanceHasDiscount && !hasLineDiscount) {
		return <Alert caption={t('discretionaryDiscount.status.notSet')} />;
	}

	// Has discount or pricing is loading
	return (
		<>
			<hr />
			<DataTable
				variant="simple"
				isLoading={isValuesLoading}
			>
				<DataTableTr
					name={t('discretionaryDiscount.approvalFlag')}
					bold
				>
					{standardOrderApprovalFlagEnumUtils.getText(discountApprovalFlag)}
				</DataTableTr>
				<DataTableTr
					name={t('discretionaryDiscount.amount')}
					bold
				>
					{numberFormatter.currency(orderState.pricing?.headers.discountOrderFlat, billableParty?.currencyCode)}
				</DataTableTr>
			</DataTable>
		</>
	);
};
