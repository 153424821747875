import { Dispatch, useCallback } from 'react';
import { IStandardOrderInstance } from 'module/purchase';
import { isOrderInstanceObsolete, logDebug } from 'js/utils/app';
import { IAuthData } from 'js/reducers/authContextReducer';
import { TOrderStateAction } from 'js/reducers/orderReducer';
import { useRefreshOrderPartnerState } from 'js/hooks/useRefreshOrderPartnerState';
import { useAsyncApiCustomer, useAsyncApiPartnerCustomer } from 'module/customers/hooks/useAsyncApiCustomers';
import { isEqual } from 'lodash';
import { useAsyncApiDistributionPartner } from 'module/distributionPartners/hooks/useAsyncApiDistributionPartners';
import { useIsRefreshRestricted } from 'js/hooks/useIsRefreshRestricted';

export interface IRefreshOrderStateProps {
	state: IStandardOrderInstance | null;
	authData?: IAuthData;
	dispatch: Dispatch<TOrderStateAction>;
}

export const useRefreshOrderState = () => {
	const refreshOrderPartnerState = useRefreshOrderPartnerState();
	const asyncApiCustomer = useAsyncApiCustomer();
	const asyncApiPartnerCustomer = useAsyncApiPartnerCustomer();
	const asyncApiDistributionPartner = useAsyncApiDistributionPartner();
	const isRefreshRestricted = useIsRefreshRestricted();

	return useCallback(
		async (props: IRefreshOrderStateProps): Promise<void> => {
			const { state, dispatch, authData } = props;

			// Do not run when is restricted
			if (isRefreshRestricted) {
				return;
			}

			// Logged partner has different ID then state owner
			if (isOrderInstanceObsolete(state, authData?.partner?.id)) {
				return;
			}

			// Partner Order
			if (state?.partner) {
				logDebug('useRefreshOrderState', 'PARTNER');
				const partnerPayload = await refreshOrderPartnerState(props);

				if (!partnerPayload) {
					return;
				}

				// Partner customer
				if (state.customer) {
					logDebug('useRefreshOrderState', 'PARTNER CUSTOMER');
					const customerResponse = await asyncApiPartnerCustomer(
						{
							partnerId: partnerPayload.partner.id!,
							salesforceId: state.customer.id!,
						},
						{ authToken: authData?.token },
					).catch((error) => logDebug('Get Order customer failed.', state.customer?.id, error));

					if (customerResponse && !isEqual(customerResponse.data, state.customer)) {
						dispatch({ type: 'SET_CUSTOMER', payload: customerResponse.data });
					}
				}

				// Partner distribution partner
				if (state.distributionPartner) {
					logDebug('useRefreshOrderState', 'DISTRIBUTION PARTNER');
					const distributionPartnerResponse = await asyncApiDistributionPartner(
						{
							distributorId: partnerPayload.partner.id,
							id: state.distributionPartner.id,
						},
						{ authToken: authData?.token },
					).catch((error) => logDebug('Get Order distribution partner failed.', state.customer?.id, error));

					if (distributionPartnerResponse && !isEqual(distributionPartnerResponse.data, state.customer)) {
						dispatch({ type: 'SET_DISTRIBUTION_PARTNER', payload: distributionPartnerResponse.data });
					}
				}
			}

			// Customer Order
			else if (state?.customer) {
				logDebug('useRefreshOrderState', 'CUSTOMER');
				const customerResponse = await asyncApiCustomer(
					{
						salesforceId: state.customer.id!,
					},
					{ authToken: authData?.token },
				).catch((error) => logDebug('Get Order customer failed.', state.customer?.id, error));

				if (customerResponse && !isEqual(customerResponse.data, state.customer)) {
					dispatch({ type: 'SET_CUSTOMER', payload: customerResponse.data });
				}
			}
		},
		[
			refreshOrderPartnerState,
			asyncApiPartnerCustomer,
			asyncApiCustomer,
			asyncApiDistributionPartner,
			isRefreshRestricted,
		],
	);
};
