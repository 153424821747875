import { useAuthContext } from 'js/contexts';
import { useDebugValue } from 'react';

export const useIsInitiateRefundEligible = () => {
	const { isRoleSalesOperations, isGroupPartner } = useAuthContext();

	useDebugValue(isRoleSalesOperations || isGroupPartner ? 'true' : 'false');

	return isRoleSalesOperations || isGroupPartner;
};
