import { Alert, Modal, Toast } from '@avast/react-ui-components';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { customersConfig } from 'module/customers/customersConfig';
import { CustomerForm, TCustomerFormFields } from 'module/customers/forms/CustomerForm';
import { toast } from 'react-toastify';
import { createCustomerApiError } from 'module/customers/utils/apiError';
import { useApiCreateCustomer } from 'module/customers/hooks/useApiCustomers';
import { IEntityCustomer } from 'module/customers';
import { useApiErrorContext, useAuthContext } from 'js/contexts';
import { IEntityPartner } from 'module/partners';
import { getPartnerRenderName } from 'module/partners/utils/partnerSelectors';

type TCreateCustomerContainerProps = {
	initialValues?: TCustomerFormFields | null;
	partner?: IEntityPartner | null;
	onCreate(customer: IEntityCustomer): void;
	onCancel(): void;
};

export const CreateCustomerContainer = (props: TCreateCustomerContainerProps) => {
	const [t] = useTranslation(customersConfig.trNamespace);
	const { setError } = useApiErrorContext();
	const { mutate: createCustomer } = useApiCreateCustomer({
		config: { params: { partnerId: props.partner?.id! } },
	});
	const { isGroupPartner } = useAuthContext();

	return (
		<>
			<Modal.Header>{t('page.create.title')}</Modal.Header>
			<Modal.Body>
				{props.partner && !isGroupPartner && (
					<Alert variant="info">
						<Trans
							t={t}
							i18nKey="common.createBehalfPartner"
							values={{ name: getPartnerRenderName(props.partner) }}
						/>
					</Alert>
				)}
				<CustomerForm
					initialValues={props.initialValues || undefined}
					onSubmit={(values, { setSubmitting }) => {
						createCustomer(values, {
							onSuccess(response) {
								setSubmitting(false);
								if (Boolean(response?.data)) {
									toast.success(<Toast>{t('page.create.success')}</Toast>);
									props.onCreate(response.data);
								}
							},
							onError(error) {
								setError({ error, resolve: createCustomerApiError });
								setSubmitting(false);
							},
						});
					}}
					onCancel={() => {
						props.onCancel();
					}}
				/>
			</Modal.Body>
		</>
	);
};
