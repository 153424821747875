import { useApiDetail } from 'js/hooks/api';
import { IEntityPartnerDetail } from 'module/partners/index';
import { useCallback } from 'react';
import { API_PARTNERS_KEYS } from './useApiPartners';
import { TSalesforceId } from 'types';

export const useAsyncApiPartnerBySalesforceId = () => {
	const api = useApiDetail<IEntityPartnerDetail>({});

	return useCallback(
		(id: TSalesforceId) =>
			api({
				queryKey: [API_PARTNERS_KEYS.SF_DETAIL, id, {}],
				meta: undefined,
			}),
		[api],
	);
};
