import React from 'react';
import { FormikControl, FormikControlRadio } from 'js/components/formik/FormikControl';
import { refundTypeEnumUtils } from 'submodule/refunds/enums';
import { FormikFooterControls } from 'js/components/formik/FormikFooterControls';
import { isInitialRefundFormInvalid } from 'submodule/refunds/refundUtils';
import { useInitiateRefundContext } from 'submodule/refunds/initiate/InitiateRefundContext';
import { useFormikContext } from 'formik';
import { IInitiateRefundForm } from 'submodule/refunds';
import { ProductsTable } from './ProductsTable';
import { Alert } from '@avast/react-ui-components';
import { SearchLicensesForRefund } from 'submodule/refunds/initiate/search/SearchLicensesForRefund';

type TStepProductsProps = {
	onSubmit: () => void;
	onCancel: () => void;
};

export const StepProducts = (props: TStepProductsProps) => {
	const { onSubmit, onCancel } = props;
	const { t, refundTypes, canPartialRefund } = useInitiateRefundContext();
	const { values } = useFormikContext<IInitiateRefundForm>();

	return (
		<>
			<FormikControlRadio
				name="refundType"
				inline
				groupProps={{ className: 'mb-3' }}
				required
			>
				{refundTypes
					.filter((type) => type.isAllowed)
					.map((type) => (
						<FormikControl.Radio
							key={type.name}
							name="refundType"
							value={type.name}
							label={refundTypeEnumUtils.getText(type.name)}
							disabled={!type.isEnabled}
						/>
					))}
			</FormikControlRadio>
			<div className="mb-6">
				{canPartialRefund && <Alert>{t(`refunds.initiate.message.${values.refundType}`)}</Alert>}
				<SearchLicensesForRefund />
				<ProductsTable />
			</div>
			<FormikFooterControls
				cancel={{ onClick: onCancel }}
				submit={{
					type: 'button',
					onClick: onSubmit,
					children: t('common:actions.continue'),
					disabled: isInitialRefundFormInvalid(values),
				}}
			/>
		</>
	);
};
