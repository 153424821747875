import { createAxiosInstance } from 'api/setupInterceptors';
import { ApiTypeEnum } from 'js/enums';

export const getApiDigitalRiverSessionTokenPromise = () => {
	const api = createAxiosInstance({ apiType: ApiTypeEnum.DR });

	return api.get<{ session_token: string }>('/store/avgb2b/SessionToken?format=json', {
		withCredentials: false,
	});
};
