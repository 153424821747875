import { TLoginResolve } from 'module/security';
import { useCallback } from 'react';
import { useAuthUserData } from 'module/security/hooks/useAuthUserData';
import { getInvalidStateLocation } from 'module/security/utils/common';
import { useAuthContext } from 'js/contexts';
import { AxiosResponse } from 'axios';
import { IAuthTokenResponse } from 'js/reducers/authContextReducer';

export const useSsoLogin = (
	apiSsoPromise: () => Promise<AxiosResponse<IAuthTokenResponse>>,
): (() => Promise<TLoginResolve>) => {
	const { setAuthData } = useAuthContext();
	const authUserData = useAuthUserData();

	return useCallback(
		() =>
			new Promise(async (resolve) => {
				const response = await apiSsoPromise().catch(() => {
					resolve({ success: false, message: 'state.invalidSso' });
				});

				// Err -> handled by hook props
				if (!response) {
					resolve({ success: false, message: 'state.invalidSso' });
					return;
				}

				const data = await authUserData(response.data);
				if (data) {
					setAuthData(data);
					resolve({ success: true, redirectTo: getInvalidStateLocation(data) });
					return;
				}
				resolve({ success: false, message: 'state.invalidSso' });
			}),
		[setAuthData, authUserData, apiSsoPromise],
	);
};
