import React, { Component, ReactNode, RefObject, useRef } from 'react';
import type { ITestId, TModalProps } from '@avast/react-ui-components';
import { Modal } from '@avast/react-ui-components';
import { isFunction } from 'lodash';

type TAsyncModalResolve<R extends unknown = number> = (state: R | null) => void;
type TAsyncModalReject = (reason?: unknown) => void;
type TModalPropsForAsync = Omit<TModalProps, 'show'>;
type TAsyncModalProps<P> = TModalPropsForAsync & {
	children: ReactNode | ((props: P) => ReactNode);
};
type TAsyncModalState<P> = {
	show: boolean;
	childrenProps?: P;
	modalProps?: TModalPropsForAsync;
};

export type TAsyncModalRef<P extends {} = {}, R extends unknown = number> = RefObject<AsyncModal<P, R>>;

export type TAsyncModalContainerProps<P extends {} = {}, R extends unknown = number> = {
	forwardedRef: TAsyncModalRef<P, R>;
} & ITestId;

export const useAsyncModalRef = <P extends {} = {}, R extends unknown = number>() => {
	return useRef<AsyncModal<P, R>>(null);
};

export class AsyncModal<P extends {} = {}, R extends unknown = number> extends Component<
	TAsyncModalProps<P>,
	TAsyncModalState<P>
> {
	state: TAsyncModalState<P> = {
		show: false,
		modalProps: {},
	};
	promiseInfo: {
		resolve?: TAsyncModalResolve<R>;
		reject?: TAsyncModalReject;
	} = {};

	updateModalProps(modalProps: TModalPropsForAsync) {
		this.setState({ modalProps });
	}

	async show(childrenProps?: P, modalProps?: TModalPropsForAsync) {
		return new Promise<R | null>((resolve, reject) => {
			this.promiseInfo = {
				resolve,
				reject,
			};
			this.setState({
				show: true,
				childrenProps,
				modalProps,
			});
		});
	}

	hide() {
		this.setState({
			show: false,
		});
	}

	isOpen(): boolean {
		return this.state.show;
	}

	onSuccess(state: R): void {
		const { resolve } = this.promiseInfo;

		this.hide();
		resolve?.(state);
	}

	onCancel(): void {
		const { resolve } = this.promiseInfo;

		this.hide();
		resolve?.(null);
	}

	getResolve(): TAsyncModalResolve<R> {
		const { resolve } = this.promiseInfo;
		return (result) => {
			resolve?.(result);
			this.hide();
		};
	}

	getReject(): TAsyncModalReject {
		const { reject } = this.promiseInfo;
		return (err) => {
			reject?.(err);
			this.hide();
		};
	}

	render() {
		const { children, onHide, ...props } = this.props;
		const { show, childrenProps, modalProps } = this.state;

		return (
			<Modal
				{...props}
				{...modalProps}
				show={show}
				onHide={() => {
					this.onCancel();
					if (modalProps?.onHide) {
						modalProps.onHide();
					} else {
						onHide?.();
					}
				}}
			>
				{show && (isFunction(children) ? children(childrenProps!) : children)}
			</Modal>
		);
	}
}
