import { CustomTable } from 'js/components/molecules/CustomTable';
import React, { ChangeEvent, ReactElement } from 'react';
import { IEntityProductGroup, IStandardOrderItemPricing } from 'module/purchase';
import { useOrderContext } from 'js/contexts';
import { usePartyPriceTranslation } from 'module/purchase/hooks/usePartyPriceTranslation';
import { useFormikContext } from 'formik';
import { TSelectVariantForm, TSelectVariantItem } from 'module/purchase/components/selectProducts';
import { isGroupBusiness } from 'module/purchase/utils/selectors';
import { get, partial } from 'lodash';
import { useSelectVariantPricing } from 'module/purchase/hooks/pricing/useSelectVariantPricing';
import { getProductValidityLabels, isQuantityChangeDisabled } from 'module/purchase/utils/common';
import Skeleton from 'react-loading-skeleton';
import { numberFormatter } from 'js/utils/number';
import { logError } from 'js/utils/app';
import { TCheckboxProps } from '@avast/react-ui-components';
import { TFormikFieldProps, TFormikNumberFieldProps } from 'js/components/formik/FormikControl';
import { CONFIG } from 'config';
import { getMaxProductQuantity } from 'js/utils/common';
import { MarketSegmentEnum } from 'js/enums';

export type ProductVariantModule = 'purchase' | 'retail';

type TUseSelectProductVariantTableProps = {
	getAdditionalHeaderCells: () => ReactElement | null;
	getAdditionalBodyCells: (row: TSelectVariantItem) => ReactElement | null;
	getVariantCheckboxProps: (row: TSelectVariantItem, index: number) => TFormikFieldProps<TCheckboxProps>;
	getQuantityInputProps: (row: TSelectVariantItem, index: number) => TFormikNumberFieldProps;
	getUnitInputProps: (row: TSelectVariantItem, index: number) => TFormikNumberFieldProps;
};

export const useSelectProductVariantTableProps = (
	module: ProductVariantModule,
	productGroup: IEntityProductGroup,
): TUseSelectProductVariantTableProps => {
	const orderContext = useOrderContext();
	const { billableParty, isEndCustomerBillableParty } = orderContext;
	const currencyCode = billableParty?.currencyCode;
	const { setFieldValue } = useFormikContext<TSelectVariantForm>();
	const { pricing, isPricingLoading } = useSelectVariantPricing();
	const { tPartyPrice } = usePartyPriceTranslation();

	const isRetailModule = module === 'retail';
	const isBusinessProduct = isGroupBusiness(productGroup);
	const isConsumerProduct = !isBusinessProduct;

	function getAdditionalHeaderCells() {
		if (isRetailModule) {
			return null;
		}

		return (
			<>
				<CustomTable.Th align="end">{tPartyPrice('totalPrice', 'customer')}</CustomTable.Th>
				{!isEndCustomerBillableParty && <CustomTable.Th align="end">{tPartyPrice('totalPrice')}</CustomTable.Th>}
			</>
		);
	}

	function getAdditionalBodyCells(row: TSelectVariantItem) {
		if (isRetailModule) {
			return null;
		}

		const isPricingAvailable = isConsumerProduct ? row.quantity !== 0 : row.unit !== 0;
		const { partnerPrice, customerPrice } = getPrices(row, isPricingAvailable);

		return (
			<>
				<CustomTable.Td align="right">
					{isPricingLoading && isPricingAvailable ? (
						<Skeleton width={100} />
					) : (
						`${numberFormatter.currency(customerPrice, currencyCode)}*`
					)}
				</CustomTable.Td>
				{!isEndCustomerBillableParty && (
					<CustomTable.Td align="right">
						{isPricingLoading && isPricingAvailable ? (
							<Skeleton width={100} />
						) : (
							`${numberFormatter.currency(partnerPrice, currencyCode)}*`
						)}
					</CustomTable.Td>
				)}
			</>
		);
	}

	function getPartyTypePrice(
		pricingItem: IStandardOrderItemPricing,
		quantity = 1,
		isBusiness = false,
		partyType: 'partner' | 'customer',
	) {
		let price = 0;

		switch (partyType) {
			case 'partner':
				price = pricingItem?.linePriceWOTax ?? 0;
				break;
			case 'customer':
				price = pricingItem?.customerPrice?.total ?? 0;
				break;
			default:
				logError(`Unsupported pricing party type: ${partyType}`);
		}

		if (isBusiness) {
			price *= quantity;
		}

		return price;
	}

	function getPrices(row: TSelectVariantItem, isPricingAvailable: boolean) {
		const pricingItem = isPricingAvailable
			? pricing?.lineitems.find((item) => item.reference_lineitem_id === row.product.sku)
			: null;

		if (!pricingItem) {
			return { partnerPrice: 0, customerPrice: 0 };
		}

		const getPriceForType = partial(getPartyTypePrice, pricingItem, row.quantity, isBusinessProduct);

		return { partnerPrice: getPriceForType('partner'), customerPrice: getPriceForType('customer') };
	}

	function onCheckboxChanged(checked: boolean, row: TSelectVariantItem, index: number) {
		const name = isConsumerProduct ? 'quantity' : 'unit';
		const currentValue = get(row, name, 1);
		const newValue = checked ? currentValue || 1 : 0;

		setFieldValue(`variant.${index}.${name}`, newValue, true);
	}

	function getVariantCheckboxProps(row: TSelectVariantItem, index: number): TFormikFieldProps<TCheckboxProps> {
		const { label, description } = getProductValidityLabels(row.product);

		return {
			testId: row.product.sku,
			name: `variant.${index}.checked`,
			label,
			description,
			onChange: (e: ChangeEvent<HTMLInputElement>) => onCheckboxChanged(e.target.checked, row, index),
		};
	}

	function getQuantityInputProps(row: TSelectVariantItem, index: number): TFormikNumberFieldProps {
		const marketSegment = isConsumerProduct ? MarketSegmentEnum.CONSUMER : MarketSegmentEnum.BUSINESS;
		const groupCode = productGroup.code;

		return {
			testId: `${row.product.sku}_quantity`,
			name: `variant.${index}.quantity`,
			min: isConsumerProduct ? 0 : 1,
			max: isConsumerProduct
				? getMaxProductQuantity({ marketSegment, isFirstPurchase: true, groupCode, isRetailPartner: isRetailModule })
				: undefined,
			onChange(value: number | undefined) {
				if (isConsumerProduct) {
					setFieldValue(`variant.${index}.checked`, value && value > 0, true);
				}
			},
			disabled: isBusinessProduct && !row.checked,
		};
	}

	function getUnitInputProps(row: TSelectVariantItem, index: number): TFormikNumberFieldProps {
		return {
			testId: `${row.product.sku}_unit`,
			name: `variant.${index}.unit`,
			min: isBusinessProduct ? 0 : row.unit,
			max: isBusinessProduct ? CONFIG.MAX_QUANTITY.BUSINESS : undefined,
			value: row.unit ? row.unit : 0,
			onChange(value: number | undefined) {
				if (isBusinessProduct) {
					setFieldValue(`variant.${index}.checked`, value && value > 0, true);
				}
			},
			disabled: isConsumerProduct || isQuantityChangeDisabled(productGroup.code),
		};
	}

	return {
		getAdditionalHeaderCells,
		getAdditionalBodyCells,
		getVariantCheckboxProps,
		getQuantityInputProps,
		getUnitInputProps,
	};
};
