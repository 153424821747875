import React from 'react';
import { useRegistrationContext } from 'module/registration/context/RegistrationContext';
import { useApiAcceptTerms } from 'module/registration/hooks/useApiRegistration';
import { resolveTermsAcceptedApiError } from 'module/registration/utils/apiError';
import { TermsForm } from 'module/registration/forms/TermsForm';
import { RegistrationStepEnum } from 'module/registration/enums';
import { useApiErrorContext, useAppContext } from 'js/contexts';
import { logError } from 'js/utils/app';
import { ApiErrorLevelEnum } from 'js/enums';
import { useTranslation } from 'react-i18next';
import { registrationConfig } from 'module/registration/registrationConfig';
import { getApiDigitalRiverSessionTokenPromise } from 'module/registration/api';

export const Terms = () => {
	const [t] = useTranslation(registrationConfig.trNamespace);
	const { setError } = useApiErrorContext();
	const { apiErrorModalRef } = useAppContext();
	const { setStep, partnerId } = useRegistrationContext();
	const { mutate: acceptTerms } = useApiAcceptTerms({
		config: { params: { partnerId } },
	});

	return (
		<TermsForm
			partnerId={partnerId}
			onSubmit={async (values, { setSubmitting }) => {
				// Get GC token
				const token = await getApiDigitalRiverSessionTokenPromise().catch(async (e) => {
					logError('Unable to get DR session token', e);
					await apiErrorModalRef.current?.show({
						error: {
							level: ApiErrorLevelEnum.CRITICAL,
							messages: [t('error.unableToRegistrationToken')],
						},
					});
				});

				if (!token) {
					setSubmitting(false);
					return;
				}

				acceptTerms(
					{ sessionToken: token.data.session_token },
					{
						onSuccess() {
							setStep(RegistrationStepEnum.REGISTRATION_INFO);
						},
						onError(error) {
							setError({ error, resolve: resolveTermsAcceptedApiError });
							setSubmitting(false);
						},
					},
				);
			}}
		/>
	);
};
