import { StepperVerticalContainer, StepperVerticalHeadline } from 'submodule/stepperVertical';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { priceCalculatorConfig } from 'module/priceCalculator/priceCalculatorConfig';
import { useFormStepsGuard } from 'module/priceCalculator/guards';
import { Col, Row } from 'js/components/atoms/Row';
import { FormikControl } from 'js/components/formik/FormikControl';
import { useProductGroupSelectOptions } from 'module/priceCalculator/hooks/useProductGroupSelectOptions';
import { useFormikContext } from 'formik';
import { IPriceCalculatorForm } from 'module/priceCalculator';
import { CONFIG } from 'config';
import { ProductGroupExceptions } from 'js/components/molecules/Exceptions';
import { isQuantityChangeDisabled } from 'module/purchase/utils/common';
import { MarketSegmentEnum } from 'js/enums';
import { MarketSegmentSwitch } from 'module/priceCalculator/forms/fields';
import { TransactionTypeEnum } from 'module/licenses/enums';
import { isDefined } from 'js/utils/common';

export const ProductStep = () => {
	const [t] = useTranslation(priceCalculatorConfig.trNamespace);
	const formStepsGuard = useFormStepsGuard();
	const { setFieldValue, values } = useFormikContext<IPriceCalculatorForm>();
	const productGroups = useProductGroupSelectOptions(values.marketSegment);

	// Units label
	let unitsLabel = 'common:entity.unit';
	const selectedMarketSegment = values.productGroup?.marketSegment || values.marketSegment;
	if (selectedMarketSegment === MarketSegmentEnum.CONSUMER) {
		unitsLabel = 'common:entity.quantity';
	} else if (isDefined(values.productGroup) && isDefined(values.productGroup.unitType)) {
		unitsLabel = `common:entity.unitType.${values.productGroup.unitType}`;
	}

	// Max units
	const isUnitsChangeDisabled = isQuantityChangeDisabled(values.productGroup?.code);
	const maxUnitsByMarketSegment = CONFIG.MAX_QUANTITY[selectedMarketSegment || MarketSegmentEnum.BUSINESS];
	const maxUnits = isUnitsChangeDisabled ? 1 : maxUnitsByMarketSegment;

	return (
		<StepperVerticalContainer
			border
			variant="light"
			className="py-5"
		>
			<StepperVerticalHeadline
				step={2}
				title={t('steps.product.title')}
				className="mb-3"
				active={formStepsGuard.isProductStepActive}
			/>

			<ProductGroupExceptions
				transactionType={TransactionTypeEnum.NEW}
				productCode={values.productGroup?.code}
			/>
			<MarketSegmentSwitch />
			<Row size="sm">
				<Col md={8}>
					<FormikControl label={t('common:entity.product')}>
						<FormikControl.SingleSelect
							required
							name="productGroupCode"
							size="sm"
							isClearable={false}
							isLoading={productGroups.isLoading}
							options={productGroups.options}
							onChange={(value) => {
								const productGroup = productGroups.list.find((group) => group.code === value);
								setFieldValue('productGroup', productGroup || null);
							}}
						/>
					</FormikControl>
				</Col>
				<Col
					md={4}
					sm={6}
				>
					<FormikControl label={t(unitsLabel, { count: 0 })}>
						<FormikControl.Number
							required
							name="units"
							size="sm"
							min={1}
							max={maxUnits}
							disabled={isUnitsChangeDisabled}
						/>
					</FormikControl>
				</Col>
				<Col md={12}>
					<FormikControl groupProps={{ className: 'mb-0' }}>
						<FormikControl.Checkbox
							name="isRenewal"
							label={t('steps.product.isRenewal')}
						/>
					</FormikControl>
				</Col>
			</Row>
		</StepperVerticalContainer>
	);
};
