import React, { ReactElement, useEffect, useState } from 'react';
import { RouteName } from 'module/RouteName';
import { useApiCreditStatus } from 'module/creditStatus/hooks/useApiCreditStatus';
import { useTranslation } from 'react-i18next';
import { creditStatusConfig } from 'module/creditStatus/creditStatusConfig';
import { numberFormatter } from 'js/utils/number';
import { TEntityKey } from 'types';
import { IEntityCreditStatus } from 'module/creditStatus';
import { useCreditStatusWidgetColumns } from 'submodule/widgets/tableColumns';
import { WidgetTypeEnum, widgetTypeEnumUtils } from 'submodule/widgets/enums';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'js/contexts';
import { CardTable } from 'js/components/molecules/DataTable/CardTable';

// Table data type
export type TCreditStatusWidgetData = {
	name: string;
	value: string;
};

const rows: TEntityKey<IEntityCreditStatus>[] = ['creditLimit', 'unbilledBalance', 'unpaidBalance', 'creditAvailable'];

/**
 * Widget with my credit status
 * @param {Object} props
 * @returns {ReactElement}
 * @constructor
 */
export const CreditStatusWidget = ({ name }: { name?: string }): ReactElement => {
	const [t] = useTranslation(creditStatusConfig.trNamespace);
	const { authCompanyId } = useAuthContext();
	const navigate = useNavigate();

	// Api call for data
	const {
		data,
		query: { isFetching, isError },
	} = useApiCreditStatus({ id: authCompanyId });

	// State for data
	const [widgetData, setWidgetData] = useState<TCreditStatusWidgetData[]>(
		rows.map((key) => ({
			name: t(`entityShort.${key}`),
			value: '',
		})),
	);

	// Get data on load
	useEffect(() => {
		if (!isFetching && !isError && data) {
			setWidgetData(
				rows.map((key) => ({
					name: t(`entityShort.${key}`),
					value: numberFormatter.currency(data[key] as number, data.currency),
				})),
			);
		}
	}, [isFetching, isError, data, t]);

	// Define columns
	const columns = useCreditStatusWidgetColumns(isFetching);

	return (
		<CardTable<TCreditStatusWidgetData>
			testId="credit status widget"
			columns={columns}
			caption={name ?? t(widgetTypeEnumUtils.getCaption(WidgetTypeEnum.CREDIT_STATUS))}
			data={widgetData}
			onViewMore={() => navigate(RouteName.CREDIT_STATUS.DEFAULT)}
			tableProps={{
				meta: {
					customError: !data && !isFetching ? t('error.creditStatusNotFound') : false,
				},
			}}
		/>
	);
};
