import { generateEnumUtils } from 'js/enums/generator/enumFunctions';
import { logError } from 'js/utils/app';

export enum PartnerTermsEnum {
	DR = 'DR',
	CLIENT = 'Client',
}

export const partnerTermsEnumUtils = generateEnumUtils<PartnerTermsEnum>(PartnerTermsEnum, {
	getText(value): string {
		switch (value) {
			case PartnerTermsEnum.DR:
				return 'Digital River Channel Participant Membership Agreement';
			case PartnerTermsEnum.CLIENT:
				return 'Partner Agreement';
			default:
				logError(`Unknown Partner Terms Enum value: ${value}`);
				return '';
		}
	},
});
