import CopyToClipboard from 'react-copy-to-clipboard';
import type { ITestId } from '@avast/react-ui-components';
import { IconButton, Toast, useTestAttributes } from '@avast/react-ui-components';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import React, { ReactElement } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

type TCopy2ClipboardProps = {
	value?: string | null;
} & ITestId;

export const Copy2ClipboardIcon = (props: TCopy2ClipboardProps): ReactElement | null => {
	const { value, testId } = props;
	const [t] = useTranslation();
	const testAttributes = useTestAttributes(testId, { type: 'copy2Clipboard' });

	if (!value) {
		return null;
	}

	return (
		<CopyToClipboard
			{...testAttributes}
			text={value}
			onCopy={() => {
				toast.success(<Toast caption={t('common:actions.copied')} />, {
					autoClose: 2000,
					hideProgressBar: true,
					pauseOnHover: false,
				});
			}}
		>
			<span className="component__copy2clipboard">
				<IconButton
					className="btn-copy"
					iconFa={faCopy}
				/>
			</span>
		</CopyToClipboard>
	);
};
