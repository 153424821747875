import React, { useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import {
	CustomerTab,
	DiscretionaryDiscountTab,
	DistributionPartnerTab,
	ItemsInOrderTab,
	NotesTab,
	OrderLicensesTab,
	OrderStatusTab,
	PartnerTab,
} from 'module/orders/pages/tabs';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import { useAuthContext, usePageDetailContext } from 'js/contexts';
import { IEntityOrder, IOrderDetailState } from 'module/orders';
import { hasAvailableLicenses, hasOrderAnyNote } from 'module/orders/utils/common';
import { useClearLocationStateOnMount } from 'js/hooks/useClearLocationStateOnMount';
import { useTabsTestAttributes } from 'js/hooks/testAttributes/useTabsTestAttributes';
import { BillablePartyTypeEnum } from 'module/orders/enums';
import { useLocationState } from 'js/hooks/useLocationState';

type TTabKeys =
	| 'orderStatusTab'
	| 'itemsInOrderTab'
	| 'partnerTab'
	| 'distributionPartnerTab'
	| 'discretionaryDiscountTab'
	| 'notesTab'
	| IOrderDetailState['tab'];

export const PageDetailTabs = () => {
	const [t] = useTranslation(ordersConfig.trNamespace);
	const { data } = usePageDetailContext<IEntityOrder>();
	const { isRoleReseller, isGroupInternal, isGroupPartner } = useAuthContext();
	const { customer, billableParty, discount, distributionPartner } = data;
	const defaultTab: TTabKeys = 'orderStatusTab';
	const testAttributes = useTabsTestAttributes('order');
	useClearLocationStateOnMount();

	const { show: showState, tab } = useLocationState<IOrderDetailState>();
	const [activeTab, setActiveTab] = useState<TTabKeys>(tab || defaultTab);

	const isDistributor = billableParty?.type === BillablePartyTypeEnum.DISTRIBUTOR;
	const isPartner = billableParty?.type === BillablePartyTypeEnum.PARTNER;

	const isCustomerTabDisabled = !customer?.id;
	const isPartnerTabDisabled = (!isDistributor && !isPartner) || !billableParty?.id;
	const isDistributionPartnerTabDisabled = !isDistributor || !distributionPartner;

	return (
		<Tab.Container
			activeKey={activeTab}
			onSelect={(key) => setActiveTab((key as TTabKeys) || defaultTab)}
			mountOnEnter={true}
			unmountOnExit={true}
		>
			<Nav
				variant="tabs"
				className="px-4"
				{...testAttributes}
			>
				<Nav.Item>
					<Nav.Link eventKey="orderStatusTab">{t('tab.orderStatus')}</Nav.Link>
				</Nav.Item>
				{!data.isPayAsYouGoOrder && (
					<Nav.Item>
						<Nav.Link
							eventKey="licenses"
							disabled={!hasAvailableLicenses(data)}
						>
							{t('tab.licenses')}
						</Nav.Link>
					</Nav.Item>
				)}
				<Nav.Item>
					<Nav.Link eventKey="itemsInOrderTab">{t('tab.itemsInOrder')}</Nav.Link>
				</Nav.Item>
				{!isGroupPartner && (
					<Nav.Item>
						<Nav.Link
							eventKey="partnerTab"
							disabled={isPartnerTabDisabled}
						>
							{t('tab.partner')}
						</Nav.Link>
					</Nav.Item>
				)}
				{!isRoleReseller && (
					<Nav.Item>
						<Nav.Link
							eventKey="distributionPartnerTab"
							disabled={isDistributionPartnerTabDisabled}
						>
							{t('common:entity.distributionPartner')}
						</Nav.Link>
					</Nav.Item>
				)}
				{!data.isPayAsYouGoOrder && (
					<Nav.Item>
						<Nav.Link
							eventKey="customerTab"
							disabled={isCustomerTabDisabled}
						>
							{t('tab.customer')}
						</Nav.Link>
					</Nav.Item>
				)}
				{isGroupInternal && (
					<Nav.Item>
						<Nav.Link
							eventKey="discretionaryDiscountTab"
							disabled={!discount?.applied}
						>
							{t('tab.discretionaryDiscount')}
						</Nav.Link>
					</Nav.Item>
				)}
				{!data.isPayAsYouGoOrder && (
					<Nav.Item>
						<Nav.Link
							eventKey="notesTab"
							disabled={!hasOrderAnyNote(data)}
						>
							{t('tab.notes')}
						</Nav.Link>
					</Nav.Item>
				)}
			</Nav>

			<Tab.Content>
				<Tab.Pane eventKey="orderStatusTab">
					<OrderStatusTab
						showPaymentInstructions={showState === 'paymentInstructions'}
						showDocuments={showState === 'documents'}
					/>
				</Tab.Pane>
				{!data.isPayAsYouGoOrder && (
					<Tab.Pane eventKey="licenses">
						<OrderLicensesTab onCancelOrder={() => setActiveTab('orderStatusTab')} />
					</Tab.Pane>
				)}
				<Tab.Pane eventKey="itemsInOrderTab">
					<ItemsInOrderTab />
				</Tab.Pane>
				{!isGroupPartner && (
					<Tab.Pane eventKey="partnerTab">
						<PartnerTab />
					</Tab.Pane>
				)}
				{!isRoleReseller && (
					<Tab.Pane eventKey="distributionPartnerTab">
						<DistributionPartnerTab />
					</Tab.Pane>
				)}
				{!data.isPayAsYouGoOrder && (
					<Tab.Pane eventKey="customerTab">
						<CustomerTab />
					</Tab.Pane>
				)}
				{isGroupInternal && (
					<Tab.Pane eventKey="discretionaryDiscountTab">
						<DiscretionaryDiscountTab />
					</Tab.Pane>
				)}
				{!data.isPayAsYouGoOrder && (
					<Tab.Pane eventKey="notesTab">
						<NotesTab />
					</Tab.Pane>
				)}
			</Tab.Content>
		</Tab.Container>
	);
};
