import React, { PropsWithChildren, ReactElement, ReactNode, useCallback, useMemo } from 'react';
import { ILicenseListFilter } from 'module/licenses';
import { LicenseStatusEnum, licenseStatusEnumUtils } from 'module/licenses/enums/licenseStatusEnum';
import { DataFilter, TDataFilterProps } from 'js/components/molecules/DataFilter/DataFilter';
import { SearchComboBox } from 'js/components/molecules/SearchBox';
import { LicenseSearchKeyEnum, licenseSearchKeyEnumUtils } from 'module/licenses/enums/licenseSearchKeyEnum';
import { Col, Row } from 'js/components/atoms/Row';
import { dateTimeToFilterDate } from 'js/utils/dateTime';
import { useTranslation } from 'react-i18next';
import { FormControl } from '@avast/react-ui-components';
import { SelectCustomer } from 'module/customers/components';
import { SelectPartner } from 'module/partners/components';
import { TQuickFiltersProps } from 'js/components/molecules/DataFilter/QuickFilters';
import { FilterTypedRangeDatepicker } from 'js/components/molecules/Datepicker/FilterTypedRangeDatepicker';
import { DateTime } from 'luxon';
import { LicenseDateRangeTypeEnum, licenseDateRangeTypeEnumUtils } from 'module/licenses/enums';
import { SelectDistributionPartner } from 'module/distributionPartners/components/SelectDistributionPartner';
import { useAuthContext } from 'js/contexts';

type TFilter = ILicenseListFilter;
type TFilterProps = TDataFilterProps<TFilter> & {
	controls?: ReactNode;
};

/**
 * Filter for licenses
 * @param {PropsWithChildren<TFilterProps>} props
 * @returns {ReactElement}
 * @constructor
 */
export const LicensesFilter = (props: PropsWithChildren<TFilterProps>): ReactElement => {
	const [t] = useTranslation('moduleLicenses');
	const { isGroupInternal, isGroupPartner, authCompanyId, isRoleDistributor, authCompany } = useAuthContext();
	const { controls, ...rest } = props;

	// Function for resolve if filter fields should be disabled
	const isEnabled = useCallback((values: TFilter) => {
		const isFilteredByLicenseKey =
			licenseSearchKeyEnumUtils.validateOneOf(values.search?.key, [LicenseSearchKeyEnum.LICENSE_KEY]) &&
			values.search?.value;

		return !isFilteredByLicenseKey;
	}, []);

	// Filter quick actions
	const actions = useMemo<TQuickFiltersProps<TFilter>['actions']>(
		() => [
			{
				caption: t('actions.lastNDays', { count: 30 }),
				filter: {
					statuses: [LicenseStatusEnum.EXPIRED],
					dateRange: [dateTimeToFilterDate(DateTime.now().minus({ days: 30 })), dateTimeToFilterDate(DateTime.now())],
					dateRangeType: LicenseDateRangeTypeEnum.EXPIRATION,
				},
			},
			{
				caption: t('actions.nextNDays', { count: 14 }),
				filter: {
					statuses: [LicenseStatusEnum.ACTIVE],
					dateRange: [dateTimeToFilterDate(DateTime.now()), dateTimeToFilterDate(DateTime.now().plus({ days: 14 }))],
					dateRangeType: LicenseDateRangeTypeEnum.EXPIRATION,
				},
			},
			{
				caption: t('actions.nextNDays', { count: 30 }),
				filter: {
					statuses: [LicenseStatusEnum.ACTIVE],
					dateRange: [dateTimeToFilterDate(DateTime.now()), dateTimeToFilterDate(DateTime.now().plus({ days: 30 }))],
					dateRangeType: LicenseDateRangeTypeEnum.EXPIRATION,
				},
			},
			{
				caption: t('actions.nextNDays', { count: 60 }),
				filter: {
					statuses: [LicenseStatusEnum.ACTIVE],
					dateRange: [dateTimeToFilterDate(DateTime.now()), dateTimeToFilterDate(DateTime.now().plus({ days: 60 }))],
					dateRangeType: LicenseDateRangeTypeEnum.EXPIRATION,
				},
			},
		],
		[t],
	);

	return (
		<DataFilter<TFilter>
			useLocation
			{...rest}
			actions={actions}
			enabledRules={{
				partnerId: isEnabled,
				distributionPartnerId: isEnabled,
				customerId: isEnabled,
				dateRange: isEnabled,
				dateRangeType: isEnabled,
			}}
		>
			{({ values, isEnabledField, updateFilter }) => (
				<Row
					multi
					size="sm"
					align="center"
				>
					<Col
						xl={5}
						md={6}
					>
						<SearchComboBox<LicenseSearchKeyEnum>
							keys={licenseSearchKeyEnumUtils.getSelectOptions()}
							controlledValue={values.search}
							onSubmit={(value) => updateFilter({ search: value })}
							onCancel={(value) => updateFilter({ search: { ...value, value: '' } })}
						/>
					</Col>
					<Col
						xl={7}
						sm={6}
						className="text-end"
					>
						{controls}
					</Col>
					<Col
						xs={12}
						className="col-hr"
					/>
					{isGroupInternal && (
						<Col
							lg={3}
							sm={6}
						>
							<SelectPartner
								size="sm"
								value={values.partnerId}
								disabled={!isEnabledField('partnerId')}
								onChange={(partnerId) => updateFilter({ partnerId })}
							/>
						</Col>
					)}
					{isRoleDistributor && (
						<Col
							lg={3}
							sm={6}
						>
							<SelectDistributionPartner
								size="sm"
								value={values.distributionPartnerId}
								distributorId={authCompany!.id}
								disabled={!isEnabledField('distributionPartnerId')}
								onChange={(distributionPartnerId) => updateFilter({ distributionPartnerId })}
							/>
						</Col>
					)}
					<Col
						lg={3}
						sm={6}
					>
						<SelectCustomer
							value={values.customerId}
							size="sm"
							partnerId={isGroupPartner ? authCompanyId : values.partnerId}
							disabled={!isEnabledField('customerId')}
							onChange={(value) => updateFilter({ customerId: value })}
						/>
					</Col>
					<Col
						lg={3}
						sm={6}
					>
						<FormControl label={t('entity.status')}>
							<FormControl.MultiSelect<LicenseStatusEnum>
								name="statuses"
								value={values.statuses}
								onChange={(value) => updateFilter({ statuses: value?.length ? value : undefined })}
								size="sm"
								placeholder={t('entity.statusPlaceholder')}
								options={licenseStatusEnumUtils.getSelectOptions({ omitted: [LicenseStatusEnum.BLACKLISTED] })}
							/>
						</FormControl>
					</Col>
					<Col
						lg={3}
						sm={6}
					>
						<FormControl label={t('entity.date')}>
							<FilterTypedRangeDatepicker<LicenseDateRangeTypeEnum>
								testId="date"
								defaultValue={LicenseDateRangeTypeEnum.CREATED_AT}
								typeValues={licenseDateRangeTypeEnumUtils.getSelectOptions()}
							/>
						</FormControl>
					</Col>
				</Row>
			)}
		</DataFilter>
	);
};
