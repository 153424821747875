import React from 'react';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import { IEntityOrder } from 'module/orders';
import { usePageDetailContext } from 'js/contexts';
import { Alert, Modal } from '@avast/react-ui-components';
import { PaymentInstructions } from 'module/orders/components/paymentInstructions/PaymentInstructions';
import { useApiChargeOrderPaymentInstructions } from 'js/chargeOrder/useApiChargeOrder';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';

export const PaymentInstructionsModal = () => {
	const [t] = useTranslation(ordersConfig.trNamespace);
	const { data: standardOrder } = usePageDetailContext<IEntityOrder>();
	const { data: paymentInstructions, query } = useApiChargeOrderPaymentInstructions({
		id: standardOrder.chargeOrderId,
	});

	return (
		<>
			<Modal.Header>{t('paymentInstructions.title')}</Modal.Header>
			<Modal.Body>
				{(() => {
					if (query.isFetching) {
						return <LoadingPlaceholder />;
					}

					if (query.isError || !paymentInstructions) {
						return (
							<Alert
								variant="danger"
								caption={t('paymentInstructions.error.notAvailable')}
							/>
						);
					}

					return <PaymentInstructions paymentInstructions={paymentInstructions} />;
				})()}
			</Modal.Body>
			<Modal.Footer cancelButton />
		</>
	);
};
