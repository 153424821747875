import React, { PropsWithChildren, ReactElement } from 'react';
import { DataFilter, TDataFilterProps } from 'js/components/molecules/DataFilter/DataFilter';
import { Col, Row } from 'js/components/atoms/Row';
import { SelectPartner } from 'module/partners/components';
import { ICreditStatusListFilter } from 'module/creditStatus';
import { head } from 'lodash';
import { useAuthContext } from 'js/contexts';

type TFilter = ICreditStatusListFilter;
type TFilterProps = TDataFilterProps<TFilter>;

/**
 * Filter for credit status list
 * @param {React.PropsWithChildren<TFilterProps>} props
 * @returns {React.ReactElement}
 * @constructor
 */
export const CreditStatusFilter = (props: PropsWithChildren<TFilterProps>): ReactElement => {
	const { isGroupInternal } = useAuthContext();

	return (
		<DataFilter<TFilter>
			useLocation
			{...props}
		>
			{({ values, updateFilter }) => (
				<Row
					multi
					size="sm"
					align="center"
				>
					{isGroupInternal && (
						<Col
							xl={3}
							lg={4}
							md={6}
						>
							<SelectPartner
								size="sm"
								value={head(values.partnerIds)}
								onChange={(value) => updateFilter({ partnerIds: value ? [value] : null })}
							/>
						</Col>
					)}
				</Row>
			)}
		</DataFilter>
	);
};
