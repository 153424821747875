import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppLayout } from 'js/layouts/AppLayout';
import { AuthRoute } from 'js/routes/AuthRoute';
import { HomepageContainer } from 'module/homepage/layouts/HomepageContainer';
import { AccountContainer } from 'module/account/layouts/AccountContainer';
import { PurchaseContainer } from 'module/purchase/layouts/PurchaseContainer';
import { LicensesContainer } from 'module/licenses/layouts/LicensesContainer';
import { RetailContainer } from 'module/retail/layouts/RetailContainer';
import { OrdersContainer } from 'module/orders/layouts/OrdersContainer';
import { ClosingBalanceContainer } from 'module/closingBalance/layouts/ClosingBalanceContainer';
import { QuotesContainer } from 'module/orders/layouts/QuotesContainer';
import { CreditStatusContainer } from 'module/creditStatus/layouts/CreditStatusContainer';
import { CreditLimitContainer } from 'module/creditLimit/layouts/CreditLimitContainer';
import { PromotionsContainer } from 'module/promotions/layouts/PromotionsContainer';
import { CustomersContainer } from 'module/customers/layouts/CustomersContainer';
import { DistributionPartnersContainer } from 'module/distributionPartners/layouts/DistributionPartnerContainer';
import { PriceCalculatorContainer } from 'module/priceCalculator/layout/PriceCalculatorContainer';
import { Error404 } from 'module/error/page/Error404';
import { RegistrationContainer } from 'module/registration/layouts/RegistrationContainer';
import { DefaultLayout, SecurityContainer, WarningLayout } from 'module/security/layouts';
import {
	PageInactive,
	PageLoginAsAffiliate,
	PageMaintenance,
	PageOmsSsoLogin,
	PageRegistrationIncomplete,
	PageSsoLogin,
	PageTerms,
} from 'module/security/pages';
import { PageEditWidgets, PageHomepage } from 'module/homepage/pages';
import { FlexibleBillingContainer } from 'module/flexibleBilling/layouts/FlexibleBillingContainer';

export const AppRoutes = () => (
	<Routes>
		<Route element={<AppLayout />}>
			<Route element={<AuthRoute />}>
				<Route element={<HomepageContainer />}>
					<Route
						path="/"
						element={<PageHomepage />}
					/>
					<Route
						path="edit-widgets"
						element={<PageEditWidgets />}
					/>
				</Route>
				<Route
					path="account/*"
					element={<AccountContainer />}
				/>
				<Route
					path="purchase/*"
					element={<PurchaseContainer />}
				/>
				<Route
					path="licenses/*"
					element={<LicensesContainer />}
				/>
				<Route
					path="retail/*"
					element={<RetailContainer />}
				/>
				<Route
					path="orders/*"
					element={<OrdersContainer />}
				/>
				<Route
					path="closing-balance/*"
					element={<ClosingBalanceContainer />}
				/>
				<Route
					path="quotes/*"
					element={<QuotesContainer />}
				/>
				<Route
					path="credit-status/*"
					element={<CreditStatusContainer />}
				/>
				<Route
					path="credit-limit/*"
					element={<CreditLimitContainer />}
				/>
				<Route
					path="promotions/*"
					element={<PromotionsContainer />}
				/>
				<Route
					path="customers/*"
					element={<CustomersContainer />}
				/>
				<Route
					path="distribution-partners/*"
					element={<DistributionPartnersContainer />}
				/>
				<Route
					path="price-calculator/*"
					element={<PriceCalculatorContainer />}
				/>
				<Route
					path="flexible-billing/*"
					element={<FlexibleBillingContainer />}
				/>
				<Route
					path="*"
					element={<Error404 />}
				/>
			</Route>
		</Route>
		<Route element={<AuthRoute />}>
			<Route
				path="registration/*"
				element={<RegistrationContainer />}
			/>
		</Route>
		<Route element={<DefaultLayout />}>
			<Route
				path="maintenance"
				element={<PageMaintenance />}
			/>
		</Route>
		<Route element={<WarningLayout />}>
			<Route
				path="registration-incomplete"
				element={<PageRegistrationIncomplete />}
			/>
			<Route
				path="inactive"
				element={<PageInactive />}
			/>
			<Route
				path="new-terms"
				element={<PageTerms />}
			/>
		</Route>
		<Route
			path="security/*"
			element={<SecurityContainer />}
		/>
		<Route
			path="sso"
			element={<PageSsoLogin />}
		/>
		<Route
			path="sso-redirect"
			element={<PageOmsSsoLogin />}
		/>
		<Route
			path="login-as-affiliate/:authorizationCode"
			element={<PageLoginAsAffiliate />}
		/>
		<Route
			path="*"
			element={<Error404 />}
		/>
	</Routes>
);
