import React, { ReactElement } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { OrderTableRow } from 'module/purchase/components/order';
import { CustomTable } from 'js/components/molecules/CustomTable';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { usePartyPriceTranslation } from 'module/purchase/hooks/usePartyPriceTranslation';
import { useFormikContext } from 'formik';
import { TProductsTableForm } from 'module/purchase/pages/PageOrder';
import { usePricingMetadata } from 'module/purchase/hooks/pricing/usePricingMetadata';
import { IconButton, Toast, Tooltip } from '@avast/react-ui-components';
import { toast } from 'react-toastify';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { useOrderTablePagination } from 'module/purchase/hooks/order/useOrderTablePagination';
import { OrderTablePagination } from 'module/purchase/components/order/OrderTablePagination';
import { hasOrderInstanceSavedCustomerPrice } from 'module/purchase/utils/common';
import { useAppContext, useOrderContext } from 'js/contexts';

export const OrderTable = (): ReactElement => {
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const { tPartyPrice } = usePartyPriceTranslation();
	const { values } = useFormikContext<TProductsTableForm>();
	const { isEndCustomerBillableParty, orderState, removeAllItems } = useOrderContext();
	const { confirmationModalRef } = useAppContext();
	const pricingMetadata = usePricingMetadata();
	const tablePagination = useOrderTablePagination();
	const hasSavedCustomerPrice = hasOrderInstanceSavedCustomerPrice(orderState);
	let cols = isEndCustomerBillableParty ? 9 : 10;
	if (orderState.isLocked) {
		cols -= 1;
	}

	return (
		<Table
			borderless
			className="table-orderProducts"
		>
			<thead>
				<tr>
					<CustomTable.Th />
					<CustomTable.Th>{t('common:entity.sku')}</CustomTable.Th>
					<CustomTable.Th>{t('common:entity.productName')}</CustomTable.Th>
					<CustomTable.Th description={t('tableVariant.tooltip.quantity')}>
						{t('common:entity.quantity')}
					</CustomTable.Th>
					<CustomTable.Th>{t('common:entity.unit', { count: 0 })}</CustomTable.Th>
					<CustomTable.Th />
					<CustomTable.Th>{t('common:entity.transaction')}</CustomTable.Th>
					<CustomTable.Th align="end">
						{(() => {
							if (hasSavedCustomerPrice) {
								return (
									<div>
										<div className="text-gray">{tPartyPrice('price', pricingMetadata.customerPricePrefixType)}</div>
										<div>{tPartyPrice('price', 'quotedCustomer')}</div>
									</div>
								);
							}

							return tPartyPrice('price', pricingMetadata.customerPricePrefixType);
						})()}
					</CustomTable.Th>
					{!isEndCustomerBillableParty && <CustomTable.Th align="end">{tPartyPrice('price')}</CustomTable.Th>}

					{!orderState.isLocked && (
						<CustomTable.Th>
							<Tooltip
								content={t('tooltips.emptyCart')}
								placement="bottom"
							>
								<IconButton
									onClick={() => {
										confirmationModalRef.current
											?.show({
												title: t('tooltips.emptyCart'),
												messages: [t('message.emptyCartConfirmation')],
												submitButtonText: t('common:actions.confirm'),
											})
											.then((response) => {
												if (response) {
													removeAllItems();
													toast.success(<Toast>{t('message.emptyCart')}</Toast>);
												}
											});
									}}
									variant="light"
									iconFa={faTrashAlt}
									size="sm"
									className="ico-remove"
									testId="remove_resetOrder"
								/>
							</Tooltip>
						</CustomTable.Th>
					)}
				</tr>
			</thead>
			<tbody>
				{values.items?.map((item, i) => {
					if (tablePagination.isPaginated && !tablePagination.pageItemsIds.includes(item.id)) {
						return null;
					}
					return (
						<OrderTableRow
							key={item.id}
							showDetailOnInit={i === 0}
							index={i}
							row={item}
							cols={cols}
						/>
					);
				})}
			</tbody>
			{tablePagination.isPaginated && (
				<tfoot>
					<tr style={{ background: 'transparent', borderBottomWidth: '1px' }}>
						<td
							colSpan={cols}
							className="p-0"
							style={{ background: 'transparent' }}
						>
							<OrderTablePagination tablePagination={tablePagination} />
						</td>
					</tr>
				</tfoot>
			)}
		</Table>
	);
};
