import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useApiAutoBillingFirstPurchase } from 'module/account/hooks/useApiAutoBilling';
import { DynamicPageTitleItem } from 'js/components/molecules/DynamicPageTitle';
import { accountConfig } from 'module/account/accountConfig';
import { DynamicBreadcrumbsItem } from 'js/components/molecules/DynamicBreadcrumbs';
import { CreditCardForm } from 'module/account/forms/CreditCardForm';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { useAutoBillingCreateSource } from 'module/account/hooks/useAutoBillingCreateSource';
import { Navigate, useNavigate } from 'react-router-dom';
import { RouteName } from 'module/RouteName';
import { toast } from 'react-toastify';
import { Headline } from 'js/components/molecules/Headline';
import { LinkButton } from 'js/components/atoms/Button';
import { H1, Toast } from '@avast/react-ui-components';
import { useAutoBillingHasSource } from 'module/account/hooks/useAutoBillingHasSource';
import { apiCriticalErrorResolve } from 'js/utils/apiError';
import { useApiErrorContext } from 'js/contexts';

export const PageAutoBillingAddCard = (): ReactElement | null => {
	const [t] = useTranslation(accountConfig.trNamespace);
	const navigate = useNavigate();
	const { setError } = useApiErrorContext();

	const hasSource = useAutoBillingHasSource(() => navigate(RouteName.ACCOUNT.FLEXIBLE_BILLING));
	const createSource = useAutoBillingCreateSource();
	const {
		data: firstPurchaseData,
		query: { isLoading, isError },
	} = useApiAutoBillingFirstPurchase({
		queryConfig: {
			onError(error) {
				setError({
					error,
					resolve: apiCriticalErrorResolve,
					onClose: () => navigate(RouteName.ACCOUNT.FLEXIBLE_BILLING),
				});
			},
			enabled: !hasSource.isFetching && !hasSource.result,
		},
	});

	if (hasSource.isFetching || isLoading || isError) {
		return <LoadingPlaceholder>{t('common:_.loading')}</LoadingPlaceholder>;
	}

	// Already has a source redirect to view page
	if (hasSource.result) {
		return (
			<Navigate
				to={RouteName.ACCOUNT.FLEXIBLE_BILLING}
				replace
			/>
		);
	}

	return (
		<>
			<DynamicPageTitleItem text={t('page.autoBillingAddCard.title')} />
			<DynamicBreadcrumbsItem href={RouteName.ACCOUNT.AUTO_BILLING_ADD_CARD}>
				{t('page.autoBillingAddCard.title')}
			</DynamicBreadcrumbsItem>

			<Headline
				className="align-items-center"
				rightContent={
					<LinkButton
						testId="update"
						size="sm"
						variant="outline-primary"
						to={RouteName.ACCOUNT.FLEXIBLE_BILLING}
					>
						{t('common:actions.back')}
					</LinkButton>
				}
			>
				<H1 type="h2">{t('page.autoBillingAddCard.title')}</H1>
			</Headline>

			<CreditCardForm
				onSubmit={async (values, cardNumber, { setSubmitting }) => {
					if (!firstPurchaseData?.paymentSessionId) {
						setSubmitting(false);
						toast.error(<Toast>{t('error:common.repeatOrContactSales')}</Toast>);
						navigate(RouteName.ACCOUNT.FLEXIBLE_BILLING);
						return;
					}

					const response = await createSource({
						cardNumber,
						values,
						paymentSessionId: firstPurchaseData.paymentSessionId,
					}).catch(() => {
						setSubmitting(false);
						toast.error(<Toast>{t('error:common.repeatOrContactSales')}</Toast>);
						navigate(RouteName.ACCOUNT.FLEXIBLE_BILLING);
					});

					if (response) {
						toast.success(<Toast>{t('creditCard.message.successCreate')}</Toast>);
						navigate(RouteName.ACCOUNT.FLEXIBLE_BILLING);
					}
				}}
			/>
		</>
	);
};
