import { TAnnouncement } from 'submodule/Announcements/types';
import React from 'react';
import { Alert } from '@avast/react-ui-components';
import { useApiErrorContext, useAuthContext } from 'js/contexts';
import { useApiAnnouncementUpdate } from 'submodule/Announcements/api';

export type TAnnouncementProps = {
	className?: string;
	announcement: TAnnouncement;
};

export const Announcement = (props: TAnnouncementProps) => {
	const { announcement, className } = props;
	const { authPartnerId } = useAuthContext();
	const { setError } = useApiErrorContext();
	const { mutateAsync: updateAnnouncement } = useApiAnnouncementUpdate({
		config: { params: { announcementId: announcement.id, partnerId: authPartnerId! } },
	});

	return (
		<Alert
			variant={announcement.variant}
			caption={announcement.caption?.()}
			closeable={announcement.isHidable}
			onClose={() => {
				updateAnnouncement({ isHidden: true }).catch((error) => {
					setError({ error });
				});
			}}
			icon={announcement.icon}
			className={className}
		>
			{announcement.render()}
		</Alert>
	);
};
