import React, { RefObject } from 'react';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import { Button } from '@avast/react-ui-components';
import { AsyncModal } from 'js/components/molecules/Modal/AsyncModal';
import { RefundsModalContent } from 'submodule/refunds/view/RefundsModalContent';
import { IEntityClosingBalance } from 'module/closingBalance';
import { useIsChargeOrderRefunded } from 'submodule/refunds/hooks/useIsChargeOrderRefunded';

export const ViewRefundsButton = ({ modalRef }: { modalRef: RefObject<AsyncModal> }) => {
	const {
		data: closingBalance,
		query: { isFetching },
	} = usePageDetailContext<IEntityClosingBalance>();
	const [t] = useTranslation(ordersConfig.trNamespace);
	const isChargeOrderRefunded = useIsChargeOrderRefunded(closingBalance.chargeOrderId);

	if (!closingBalance.chargeOrderId || (!isChargeOrderRefunded.isRefunded && !isChargeOrderRefunded.isFetching)) {
		return null;
	}

	return (
		<>
			<Button
				size="sm"
				loading={isFetching || isChargeOrderRefunded.isFetching}
				variant="outline-primary"
				onClick={() => modalRef.current?.show()}
				testId="refunds"
			>
				{t('common.refunds')}
			</Button>
			<AsyncModal
				ref={modalRef}
				size="xl"
				testId="refunds"
			>
				<RefundsModalContent />
			</AsyncModal>
		</>
	);
};
