import { TableColumnsDef } from 'types';
import { IEntityClosingBalanceLicenses } from 'module/closingBalance';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { noValuePlaceholder } from 'js/utils/app';
import { closingBalanceConfig } from 'module/closingBalance/closingBalanceConfig';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { TAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { TAsyncDetailViewModalProps } from 'module/closingBalance/components/modal/AsyncDetailViewModal';

type TTableData = IEntityClosingBalanceLicenses;
export const useClosingBalanceDetailViewColumns = (
	detailViewRef: TAsyncModalRef<TAsyncDetailViewModalProps>,
): TableColumnsDef<TTableData> => {
	const [t] = useTranslation(closingBalanceConfig.trNamespace);

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.copy2clipboard('licenseKey', {
				header: t('common:entity.licenseKey'),
				meta: {
					defaultValue: {
						value: noValuePlaceholder(t('common:_.NA')),
						applyFormatters: false,
						condition: (value) => value === 'n/a',
					},
				},
			}),
			columnHelper.ellipsis('product.name', {
				header: t('common:entity.productName'),
				meta: {
					defaultValue: { value: noValuePlaceholder() },
					formatters: [formatter.bold],
				},
			}),
			columnHelper.text('product.sku', {
				header: t('common:entity.sku'),
				meta: {
					defaultValue: { value: noValuePlaceholder() },
				},
			}),
			columnHelper.text('product.validity', {
				header: t('common:entity.validity'),
				meta: {
					formatters: [(value) => t('common:datePeriod.MONTH', { count: value })],
				},
			}),
			columnHelper.integer('product.quantity', { header: t('common:entity.quantity') }),
			columnHelper.money(
				'srpTotalPrice',
				{ header: t('common:entity.customerTotalPrice') },
				{ currencyKey: 'currency' },
			),
			columnHelper.actions({
				button: {
					children: t('common:actions.detail'),
					onClick(detailView) {
						detailViewRef.current?.show({ detailView });
					},
					testId: 'detail',
				},
			}),
		];
	}, [t, detailViewRef]);
};
