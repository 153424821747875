import { IEntityAffiliateConfig, IEntityPartner, TAffiliateConfigApiValue } from 'module/partners/index';
import { logDebug, logError } from 'js/utils/app';
import { affiliateConfigApiValueToUi, affiliateConfigUiValueToApi } from 'module/partners/utils/affiliateConfig';
import { useApiAffiliateConfig, useApiAffiliateConfigUpdate } from 'module/partners/hooks/useApiPartners';
import { useCallback } from 'react';
import type { AxiosResponse } from 'axios';
import { AffiliateConfigEnum } from 'module/partners/enums';

type TUseAffiliateConfig<UiType> = {
	data: IEntityAffiliateConfig<UiType> | null;
	loading: boolean;
};

type TUseAffiliateConfigOptions<UiType> = {
	affiliateId: IEntityPartner['id'];
	defaultValue?: UiType | null;
	name: AffiliateConfigEnum;
};

export const useAffiliateConfig = <ApiType extends TAffiliateConfigApiValue, UiType extends unknown = ApiType>(
	options: TUseAffiliateConfigOptions<UiType>,
): TUseAffiliateConfig<UiType> => {
	const { affiliateId, name, defaultValue } = options;
	const value: UiType | null = defaultValue ?? null;

	// Prepare update config call
	const { mutateAsync: affiliateConfigUpdate } = useApiAffiliateConfigUpdate({
		config: { params: { id: affiliateId, name } },
	});

	// Get config value
	const { data = value, query } = useApiAffiliateConfig({
		filter: { id: affiliateId!, name },
		config: { catchError: (err) => err.response?.status !== 404 },
		queryConfig: {
			enabled: Boolean(affiliateId),
			// @ts-ignore
			select: useCallback(
				(response: AxiosResponse<IEntityAffiliateConfig<ApiType>>) => ({
					...response,
					data: {
						...response.data,
						value: affiliateConfigApiValueToUi<UiType>(name, response.data.value),
					},
				}),
				[name],
			),
			onError(error) {
				if (error.response?.status === 404 && defaultValue) {
					const apiValue = affiliateConfigUiValueToApi<UiType>(name, defaultValue);
					if (apiValue) {
						logDebug('Save default UI value to API', name, apiValue);
						affiliateConfigUpdate({ value: apiValue }).catch(logError);
					}
					return;
				}
				throw error;
			},
		},
	});

	return { data: data as IEntityAffiliateConfig<UiType> | null, loading: query.isLoading };
};
