import React, {
	PropsWithChildren,
	ReactElement,
	useCallback,
	useContext,
	useEffect,
	useImperativeHandle,
	useMemo,
	useReducer,
} from 'react';
import {
	IOrderContextBillableParty,
	IPricingResponse,
	IStandardOrderInstance,
	IStandardOrderInstanceCustomPrice,
	IStandardOrderInstanceItem,
	IStandardOrderItemPricing,
	TStandardOrderInstanceAddItem,
} from 'module/purchase';
import { getOrderContextInitialState, orderStateReducer } from 'js/reducers/orderReducer';
import { IEntityCustomer } from 'module/customers';
import { IEntityDistributionPartner } from 'module/distributionPartners';
import { IEntityPartnerDetail, IPartnerGroups, TPartnerRolesAndRoleGroups } from 'module/partners';
import { TUseStorageContextOnChange, useStorageContext } from 'js/hooks/useStorageContext';
import { getMarketSegments, getPartnerGroups, hasPartnerDetailData } from 'module/partners/utils/partnerSelectors';
import { getPartnerRolesAndRoleGroups } from 'module/partners/utils/roles';
import { TPartnerPriceLists, TPriceListCode } from 'js/priceList';
import { useRefreshOrderState } from 'js/hooks/useRefreshOrderState';
import { IAuthContextSubscriber, TAuthContextSubscriberRef } from 'types';
import { isOrderInstanceObsolete, logError } from 'js/utils/app';
import { PurchaseBillablePartyTypeEnum, StandardOrderApprovalFlagEnum } from 'module/purchase/enums';
import { BillablePartyTypeEnum } from 'module/orders/enums';
import { useAuthContext } from 'js/contexts/AuthContext';
import { MarketSegmentEnum } from 'js/enums';
import { ILicenseOperationPartyResolution } from 'module/licenses/components/selectParty';

const ORDER_CONTEXT_STORAGE_KEY = 'order';

export interface IOrderContext {
	orderState: IStandardOrderInstance;

	/**
	 * When data are loaded from cache & initialized
	 */
	isOrderReady: boolean;
	hasLineDiscount: boolean;
	isDiscountApprovalRequired: boolean;
	discountApprovalFlag?: StandardOrderApprovalFlagEnum;
	billableParty: IOrderContextBillableParty | null;
	hasBillableParty: boolean;
	isEndCustomerBillableParty: boolean;
	isPartnerBillableParty: boolean;
	updateMode: boolean;
	partnerRolesAndRoleGroups: TPartnerRolesAndRoleGroups;
	partnerGroups: IPartnerGroups;
	orderMarketSegments: MarketSegmentEnum[];

	/**
	 * Check if order has selected order parties
	 * @returns {boolean}
	 */
	hasOrderParties(): boolean;

	/**
	 * @param {IEntityPartnerDetail} partner
	 * @param {TPartnerPriceLists} partnerPriceLists
	 * @returns {void}
	 */
	setPartner(partner: IEntityPartnerDetail, partnerPriceLists: TPartnerPriceLists): void;

	/**
	 * @returns {void}
	 */
	removePartner(): void;

	/**
	 * @param {IEntityCustomer | null} payload
	 * @returns {void}
	 */
	setCustomer(payload: IEntityCustomer | null): void;

	/**
	 * @param {string | undefined} payload
	 * @returns {void}
	 */
	setCustomerCurrencyCode(payload?: IStandardOrderInstance['customerCurrencyCode']): void;

	/**
	 * @param {IEntityDistributionPartner | null} payload
	 * @returns {void}
	 */
	setDistributionPartner(payload: IEntityDistributionPartner | null): void;

	/**
	 * @param {IPricingResponse | null} payload
	 * @returns {void}
	 */
	setPricing(payload: IPricingResponse | null): void;

	/**
	 * @param {IStandardOrderInstance['action']} payload
	 * @returns {void}
	 */
	setAction(payload: IStandardOrderInstance['action']): void;

	getOrderItem(id: IStandardOrderInstanceItem['id']): IStandardOrderInstanceItem | null;

	/**
	 * Pricing for particular item
	 * @param {IStandardOrderInstanceItem['id']} id
	 * @returns {IStandardOrderItemPricing | null}
	 */
	getItemPricing(id: IStandardOrderInstanceItem['id']): IStandardOrderItemPricing | null;

	/**
	 * Reset order to initialization state
	 * @returns {void}
	 */
	resetOrder(): void;

	addItems(items: TStandardOrderInstanceAddItem[], priceListCode: TPriceListCode): void;

	removeItem(id: IStandardOrderInstanceItem['id']): void;

	removeAllItems(): void;

	updateAdditionalData(values: IStandardOrderInstance['additionalInfo']): void;

	updateDiscretionaryDiscount(values: IStandardOrderInstance['discretionaryDiscount']): void;

	resetLineDiscretionaryDiscount(): void;

	updateItems(values: Required<IStandardOrderInstance>['items']): void;

	updateCustomerPrices(values: IStandardOrderInstanceCustomPrice[]): void;

	updateCustomPrices(values: IStandardOrderInstance['customPrices']): void;

	updateParties(
		parties: ILicenseOperationPartyResolution,
		customerCurrencyCode?: IStandardOrderInstance['customerCurrencyCode'],
	): void;
}

type TOrderContextProviderProps = PropsWithChildren<{
	updateMode?: boolean;
	initialState?: IStandardOrderInstance;
	discountApprovalFlag?: StandardOrderApprovalFlagEnum;
	authContextSubscriberRef?: TAuthContextSubscriberRef;
}>;

/**
 * Create context
 */
const OrderContext = React.createContext<IOrderContext>({} as IOrderContext);
OrderContext.displayName = 'OrderContext';

export const useOrderContext = () => useContext(OrderContext);

/**
 * Wrapper for context provider, create useful methods for order manipulation
 * @param {TOrderContextProviderProps} props
 * @returns {ReactElement}
 * @constructor
 */
export const OrderContextProvider = (props: TOrderContextProviderProps): ReactElement => {
	const { children, updateMode = false, initialState, authContextSubscriberRef } = props;
	const [orderState, dispatch] = useReducer(orderStateReducer, initialState || getOrderContextInitialState());
	const { isGroupPartner, isLogged, authPartnerId, authCompany, authPriceLists, isRoleSalesOperations } =
		useAuthContext();
	const refreshOrderState = useRefreshOrderState();

	useImperativeHandle<IAuthContextSubscriber, IAuthContextSubscriber>(
		authContextSubscriberRef,
		() => ({
			async onLogin(authData) {
				await refreshOrderState({ state: orderState, authData, dispatch });
			},
		}),
		[refreshOrderState, orderState],
	);

	// Sync context
	const onChangeContext: TUseStorageContextOnChange<IStandardOrderInstance> = useCallback(
		(action, payload) => {
			switch (action) {
				case 'LOAD':
					if (isOrderInstanceObsolete(payload, authPartnerId)) {
						dispatch({ type: 'INIT', payload: null });
					} else {
						dispatch({ type: 'INIT', payload });
					}
					break;
				case 'CHANGE':
					if (payload) {
						dispatch({ type: 'SET', payload });
					} else {
						dispatch({ type: 'RESET' });
					}
					break;
				default:
					logError(`Not supported action: ${action}`);
			}
		},
		[dispatch, authPartnerId],
	);

	useStorageContext<IStandardOrderInstance>({
		state: orderState,
		key: ORDER_CONTEXT_STORAGE_KEY,
		onChange: onChangeContext,
		allowSync: !updateMode,
		allowLoadState: !updateMode,
		allowUpdateState: orderState.isReady && !updateMode,
	});

	/** @inheritDoc */
	const setPartner: IOrderContext['setPartner'] = useCallback((partner, partnerPriceLists) => {
		dispatch({ type: 'SET_PARTNER', payload: { partner, partnerPriceLists } });
	}, []);

	// On auth user login
	useEffect(() => {
		if (isLogged && orderState.isReady) {
			dispatch({ type: 'SET_AUTH_PARTNER_ID', payload: authPartnerId });
			if (isGroupPartner && authCompany && authPriceLists) {
				setPartner(authCompany, authPriceLists);
			}
		}
	}, [isLogged, authPartnerId, orderState.isReady, isGroupPartner, authCompany, authPriceLists, setPartner]);

	/** @inheritDoc */
	const removePartner: IOrderContext['removePartner'] = useCallback(() => {
		dispatch({ type: 'REMOVE_PARTNER' });
	}, []);

	/** @inheritDoc */
	const setCustomer: IOrderContext['setCustomer'] = useCallback((payload) => {
		dispatch({ type: 'SET_CUSTOMER', payload });
	}, []);

	/** @inheritDoc */
	const setCustomerCurrencyCode: IOrderContext['setCustomerCurrencyCode'] = useCallback((payload) => {
		dispatch({ type: 'SET_CUSTOMER_CURRENCY', payload });
	}, []);

	/** @inheritDoc */
	const setDistributionPartner: IOrderContext['setDistributionPartner'] = useCallback((payload) => {
		dispatch({ type: 'SET_DISTRIBUTION_PARTNER', payload });
	}, []);

	/** @inheritDoc */
	const setPricing: IOrderContext['setPricing'] = useCallback((payload) => {
		dispatch({ type: 'SET_PRICING', payload });
	}, []);

	/** @inheritDoc */
	const setAction: IOrderContext['setAction'] = useCallback((payload) => {
		dispatch({ type: 'SET_ACTION', payload });
	}, []);

	/** @inheritDoc */
	const getOrderItem: IOrderContext['getOrderItem'] = (id) => {
		return orderState.items.find((item) => item.id === id) || null;
	};

	/** @inheritDoc */
	const getItemPricing: IOrderContext['getItemPricing'] = (id) => {
		const _id = String(id);
		return orderState.pricing?.lineitems.find((item) => item.reference_lineitem_id === _id) || null;
	};

	/** @inheritDoc */
	const resetOrder: IOrderContext['resetOrder'] = useCallback(() => {
		if (updateMode) {
			return;
		}
		dispatch({ type: 'RESET' });
		if (isGroupPartner && authCompany && authPriceLists) {
			setPartner(authCompany, authPriceLists);
		}
	}, [isGroupPartner, authCompany, updateMode, authPriceLists, setPartner]);

	/**
	 * Determinate billable party
	 * @returns {PurchaseBillablePartyTypeEnum | null}
	 */
	const purchaseBillablePartyType: PurchaseBillablePartyTypeEnum | null = useMemo(() => {
		if (isGroupPartner || Boolean(orderState.partner?.id)) {
			return PurchaseBillablePartyTypeEnum.PARTNER;
		}
		if (!isGroupPartner && Boolean(orderState.customer?.id)) {
			return PurchaseBillablePartyTypeEnum.CUSTOMER;
		}
		return null;
	}, [isGroupPartner, orderState.partner, orderState.customer]);

	/** @inheritDoc */
	const hasOrderParties: IOrderContext['hasOrderParties'] = () => {
		// No order
		if (!orderState) {
			return false;
		}

		switch (purchaseBillablePartyType) {
			case PurchaseBillablePartyTypeEnum.PARTNER:
				return hasPartnerDetailData(orderState.partner);
			case PurchaseBillablePartyTypeEnum.CUSTOMER:
				return Boolean(orderState.customerCurrencyCode);
			default:
				return false;
		}
	};

	const billableParty: IOrderContext['billableParty'] = useMemo(() => {
		let billableParty: IOrderContext['billableParty'] = null;

		switch (purchaseBillablePartyType) {
			// End customer order
			case PurchaseBillablePartyTypeEnum.CUSTOMER:
				if (orderState.customer && orderState.customerCurrencyCode) {
					billableParty = {
						partnerId: null,
						currencyCode: orderState.customerCurrencyCode,
						countryCode: orderState.customer.billing.countryCode!,
						billablePartyType: BillablePartyTypeEnum.END_CUSTOMER,
					};
				}
				break;

			// Partner order
			case PurchaseBillablePartyTypeEnum.PARTNER:
				if (orderState.partner) {
					const { isRoleDistributor } = getPartnerRolesAndRoleGroups(orderState.partner);
					billableParty = {
						partnerId: orderState.partner.parentCompanyId || orderState.partner.id,
						currencyCode: orderState.partner.currencyCode!,
						countryCode: orderState.partner.countryCode!,
						billablePartyType: isRoleDistributor ? BillablePartyTypeEnum.DISTRIBUTOR : BillablePartyTypeEnum.PARTNER,
					};
				}
				break;

			default:
				return billableParty;
		}

		return billableParty;
	}, [purchaseBillablePartyType, orderState.partner, orderState.customer, orderState.customerCurrencyCode]);

	// Discount flag
	const discountApprovalFlag: StandardOrderApprovalFlagEnum | undefined =
		updateMode && orderState.isLocked ? props.discountApprovalFlag : orderState.pricing?.headers.discountApprovalFlag;

	const value: IOrderContext = {
		updateMode,
		orderState,
		isOrderReady: orderState.isReady,
		hasOrderParties,
		setPartner,
		removePartner,
		setCustomer,
		setCustomerCurrencyCode,
		setDistributionPartner,
		setPricing,
		getItemPricing,
		getOrderItem,
		setAction,
		resetOrder,
		addItems: useCallback(
			(items, priceListCode) =>
				dispatch({
					type: 'ADD_ITEMS',
					payload: { items, priceListCode },
				}),
			[],
		),
		removeItem: useCallback((payload) => dispatch({ type: 'REMOVE_ITEM', payload }), []),
		removeAllItems: useCallback(() => dispatch({ type: 'REMOVE_ALL_ITEMS' }), []),
		updateAdditionalData: useCallback((payload) => dispatch({ type: 'SET_ADDITIONAL_DATA', payload }), []),
		updateDiscretionaryDiscount: useCallback(
			(payload) =>
				dispatch({
					type: 'SET_DISCRETIONARY_DISCOUNT',
					payload,
				}),
			[],
		),
		resetLineDiscretionaryDiscount: useCallback(() => dispatch({ type: 'RESET_LINE_DISCRETIONARY_DISCOUNT' }), []),
		updateItems: useCallback((payload) => dispatch({ type: 'UPDATE_ITEMS', payload }), []),
		updateCustomerPrices: useCallback((payload) => dispatch({ type: 'UPDATE_CUSTOMER_PRICES', payload }), []),
		updateCustomPrices: useCallback((payload) => dispatch({ type: 'UPDATE_CUSTOM_PRICES', payload }), []),
		updateParties: useCallback((partyResolution, customerCurrencyCode) => {
			dispatch({ type: 'UPDATE_PARTIES', payload: { partyResolution, customerCurrencyCode } });
		}, []),
		isEndCustomerBillableParty: purchaseBillablePartyType === PurchaseBillablePartyTypeEnum.CUSTOMER,
		isPartnerBillableParty: purchaseBillablePartyType === PurchaseBillablePartyTypeEnum.PARTNER,
		hasBillableParty: purchaseBillablePartyType !== null,
		hasLineDiscount: orderState.items.some((item) => Boolean(item.discretionaryDiscount)),
		discountApprovalFlag,
		isDiscountApprovalRequired:
			discountApprovalFlag === StandardOrderApprovalFlagEnum.APPROVAL_REQUIRED && !isRoleSalesOperations,
		billableParty,
		partnerRolesAndRoleGroups: getPartnerRolesAndRoleGroups(orderState.partner),
		partnerGroups: getPartnerGroups(orderState.partner),
		orderMarketSegments: getMarketSegments({ partner: orderState.partner, purchaseBillablePartyType }),
	};

	return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>;
};
