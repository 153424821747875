import React from 'react';
import { FlexibleBillingDetailMenu, ReportListTable } from '../components';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { useApiFlexibleBillingDistributionPartnerReportList } from 'module/flexibleBilling/hooks';
import { useAuthContext, usePageDetailContext } from 'js/contexts';
import { IApiFlexibleBillingDetailFilter, IFlexibleBillingDistributionPartner } from 'module/flexibleBilling/index';
import { DetailHeadline } from 'module/flexibleBilling/components/DetailHeadline';
import { FlexibleBillingDetailActions } from 'module/flexibleBilling/components/actions/FlexibleBillingDetailActions';
import { useRouteParams } from 'js/hooks/useRouteParams';
import { TDetailArguments } from 'types';

export const PageDetailReports = () => {
	const params = useRouteParams<TDetailArguments>();
	const { authCompanyId } = useAuthContext();
	const {
		data: { distributionPartner },
	} = usePageDetailContext<IFlexibleBillingDistributionPartner>();

	return (
		<>
			<DetailHeadline rightContent={<FlexibleBillingDetailActions partnerId={params.id} />} />
			<FlexibleBillingDetailMenu />
			<DefaultContainer>
				<ReportListTable<IApiFlexibleBillingDetailFilter>
					filter={{ distributorId: authCompanyId, id: distributionPartner.id! }}
					query={useApiFlexibleBillingDistributionPartnerReportList}
				/>
			</DefaultContainer>
		</>
	);
};
