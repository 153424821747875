import React, { ReactNode, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const PLACEHOLDER_ID = 'FILTER_PLACEHOLDER';

export const FilterPlaceholder = () => <div id={PLACEHOLDER_ID} />;

export const FilterPlaceholderPortal = (props: { children: ReactNode }) => {
	const [placeholder, setPlaceholder] = useState<HTMLElement | null>(null);

	useLayoutEffect(() => {
		const placeholder = document.getElementById(PLACEHOLDER_ID);
		setPlaceholder(placeholder);
	}, []);

	if (!placeholder) {
		return null;
	}

	return createPortal(<div className="section__filter container-fluid">{props.children}</div>, placeholder, 'filter');
};
