import type { TUiTableRow } from '@avast/react-ui-components';
import { bold, button, dropdown, italic } from '@avast/react-ui-components';
import type { TColumnFormatter } from '@tanstack/react-table';
import {
	actions,
	copy2clipboard,
	date,
	enableStatus,
	link,
	linkButton,
	money,
	number,
	percentage,
} from 'js/components/table/formatters';

type ColumnFormatterHelper<Row extends TUiTableRow> = {
	bold: TColumnFormatter<Row>;
	italic: TColumnFormatter<Row>;
	button: typeof button;
	dropdown: typeof dropdown;
	copy2clipboard: TColumnFormatter<Row>;
	date: typeof date;
	linkButton: typeof linkButton;
	money: typeof money;
	number: typeof number;
	percentage: typeof percentage;
	actions: typeof actions;
	link: typeof link;
	enableStatus: typeof enableStatus;
};

export const createColumnFormatterHelper = <Row extends TUiTableRow>(): ColumnFormatterHelper<Row> => ({
	bold: bold<Row>(),
	italic: italic<Row>(),
	button,
	dropdown,
	copy2clipboard: copy2clipboard<Row>(),
	date,
	linkButton,
	money,
	number,
	percentage,
	actions,
	link,
	enableStatus,
});
