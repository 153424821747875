import { TPartyType } from 'module/purchase/hooks/usePartyPriceTranslation';
import { useOrderContext } from 'js/contexts';

type TUsePricingMetadata = {
	hasPricingBreakdown: boolean;
	customerPricePrefixType: TPartyType;
};

export const usePricingMetadata = (): TUsePricingMetadata => {
	const {
		orderState: { isLocked, hasSavedPricingResponse },
		updateMode,
	} = useOrderContext();
	const hasPricingBreakdown = !updateMode || !isLocked || (isLocked && Boolean(hasSavedPricingResponse));

	return {
		hasPricingBreakdown,
		customerPricePrefixType: hasPricingBreakdown ? 'customer' : 'srp',
	};
};
