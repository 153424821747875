import React, { FunctionComponent } from 'react';
import { AppSidebar } from 'js/layouts/AppSidebar';
import classNames from 'classnames';
import { AppHeader } from 'js/layouts/AppHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { DynamicBreadcrumbsItem } from 'js/components/molecules/DynamicBreadcrumbs';
import { Outlet } from 'react-router-dom';
import { useAppContext, useAuthContext } from 'js/contexts';
import { SkeletonTheme } from 'react-loading-skeleton';

export const AppLayout: FunctionComponent = () => {
	const { sidebarCollapsed } = useAppContext();
	const { isLogged } = useAuthContext();

	if (isLogged) {
		return (
			<div className={classNames('app', { 'is-collapsed': sidebarCollapsed.value })}>
				<SkeletonTheme
					baseColor="#0b2c41"
					highlightColor="#0a3c51"
				>
					<DynamicBreadcrumbsItem href="/">
						<FontAwesomeIcon icon={faHome} />
					</DynamicBreadcrumbsItem>
					<AppHeader />
					<div className="sidebar-container">
						<AppSidebar />
					</div>
					<div
						className="sidebar-overlay"
						onClick={() => sidebarCollapsed.setValue(true)}
					/>
				</SkeletonTheme>
				<div className="content-container">
					<Outlet />
				</div>
			</div>
		);
	}
	return (
		<div className="min-vh-100 d-flex justify-content-center flex-column">
			<Outlet />
		</div>
	);
};
