import * as Yup from 'yup';
import { TCustomerFormFields } from 'module/customers/forms/CustomerForm';
import { CustomerTypeEnum, DiscountTypeEnum } from 'module/customers/enums';
import { useMemo } from 'react';
import { yupContextValidator } from 'js/utils/validator';
import { IFormikContextValidator } from 'types/validator';
import { useTranslation } from 'react-i18next';
import { customersConfig } from 'module/customers/customersConfig';
import { hasCountryStates } from 'js/entities/country/countryUtils';

const isConsumer = (contactType: CustomerTypeEnum) => contactType === CustomerTypeEnum.CONSUMER;

const isBusiness = (contactType: CustomerTypeEnum) => contactType === CustomerTypeEnum.BUSINESS;

const isDiscountApplied = (billingDiscountType: DiscountTypeEnum, requestedDiscountType: DiscountTypeEnum) => {
	return billingDiscountType || requestedDiscountType;
};

const isBusinessWithDiscountType = (
	contactType: CustomerTypeEnum,
	billingDiscountType: DiscountTypeEnum,
	requestedDiscountType: DiscountTypeEnum,
) => isBusiness(contactType) && isDiscountApplied(billingDiscountType, requestedDiscountType);

export const useCustomerFormValidator = (): IFormikContextValidator<TCustomerFormFields> => {
	type Values = TCustomerFormFields;
	const [t] = useTranslation(customersConfig.trNamespace);

	const validationSchema = useMemo(
		() =>
			Yup.object().shape<Values>({
				contactType: Yup.string<CustomerTypeEnum>().label(t('entity.customerType')).required(),

				companyName: Yup.string().ensure().label(t('common:entity.company')).max(255).when('contactType', {
					is: isBusiness,
					then: Yup.string().required(),
				}),

				firstName: Yup.string().label(t('common:contact.firstName')).max(40).required(),

				lastName: Yup.string().label(t('common:contact.lastName')).max(80).required(),

				email: Yup.string().label(t('common:contact.email')).max(80).email().required(),

				phone: Yup.string()
					.ensure()
					.label(t('common:contact.phone'))
					.max(40)
					.when(['contactType', 'billing.discountType', 'requestedDiscountType'], {
						is: isBusinessWithDiscountType,
						then: Yup.string().required(),
					}),

				website: Yup.string()
					.ensure()
					.label(t('common:contact.website'))
					.max(255)
					.when(['contactType', 'billing.discountType', 'requestedDiscountType'], {
						is: isBusinessWithDiscountType,
						then: Yup.string().required(),
					}),

				industry: Yup.string()
					.ensure()
					.label(t('entity.industry'))
					.when(['contactType', 'billing.discountType', 'requestedDiscountType'], {
						is: isBusinessWithDiscountType,
						then: Yup.string().required(),
					}),

				billing: Yup.object()
					.required()
					.shape({
						street: Yup.string()
							.ensure()
							.label(t('common:address.street'))
							.max(255)
							.when(['$contactType', '$billing.discountType', '$requestedDiscountType'], {
								is: isBusinessWithDiscountType,
								then: Yup.string().required(),
							}),
						postalCode: Yup.string()
							.ensure()
							.label(t('common:address.postalCode'))
							.max(20)
							.when(['$contactType', '$billing.discountType', '$requestedDiscountType'], {
								is: isBusinessWithDiscountType,
								then: Yup.string().required(),
							}),
						city: Yup.string()
							.label(t('common:address.city'))
							.max(40)
							.when(['$contactType', '$billing.discountType', '$requestedDiscountType'], {
								is: (contactType, discountType, requestedDiscountType) =>
									isConsumer(contactType) ||
									isBusinessWithDiscountType(contactType, discountType, requestedDiscountType),
								then: Yup.string().required(),
							}),
						countryCode: Yup.string().ensure().label(t('common:address.country')).required(),
						stateCode: Yup.string().ensure().label(t('common:address.state')).when('countryCode', {
							is: hasCountryStates,
							then: Yup.string().required(),
						}),
					}),
			}),
		[t],
	);

	return {
		validationSchema,
		validate: (values) =>
			yupContextValidator<Values>(validationSchema, values, {
				contactType: values.contactType,
				requestedDiscountType: values.requestedDiscountType,
			}),
	};
};
