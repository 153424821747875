import { IApiError, TApiErrorResolve } from 'types/api';
import { apiCriticalErrorResolve } from 'js/utils/apiError';
import { API_ERR_CODE } from 'constant';
import React from 'react';
import i18n from 'i18next';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { UsageLimitApiError } from 'module/flexibleBilling/components';
import { TFlexibleBillingForm } from 'module/flexibleBilling/index';
import { TUsageLimitApiErrorItem } from 'module/flexibleBilling/components/UsageLimitApiError';
import { get, isNumber } from 'lodash';

export const submitFlexibleBillingSettingApiError = (formValues: TFlexibleBillingForm): TApiErrorResolve => {
	return (err) => {
		if (err.errorCode === API_ERR_CODE.USAGE_LIMIT_IS_LOWER_THEN_TOTAL_USAGE_LIMIT) {
			return {
				title: i18n.t(flexibleBillingConfig.trPrefix('error.usageLimit.title')),
				content: <UsageLimitApiError errorList={extractUsageLimitApiErrorList(formValues, err)} />,
				messages: [],
				hideErrorDetails: true,
				cancelButtonText: i18n.t('common:actions.gotIt'),
			};
		}

		return apiCriticalErrorResolve(err);
	};
};

const extractUsageLimitApiErrorList = (
	formValues: TFlexibleBillingForm,
	error: IApiError,
): TUsageLimitApiErrorItem[] => {
	const list: TUsageLimitApiErrorItem[] = [];

	error.violations?.forEach((violation) => {
		const productCode = get(violation.parameters, 'product');
		const limitByCustomers = get(violation.parameters, 'limitByCustomers');

		if (!productCode || !isNumber(limitByCustomers)) {
			return;
		}

		// Find product
		const item = formValues.find((item) => item.productGroupCode === productCode);
		if (item) {
			list.push({ name: item.productLabel, limit: limitByCustomers });
		}
	});

	return list;
};
