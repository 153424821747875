import { TApiErrorResolve } from 'types/api';
import { apiErrorResolveError } from 'js/utils/apiError';
import { API_ERROR_AFFILIATE } from 'module/partners/constants';

export const updateAffiliateApiError: TApiErrorResolve = (error) => {
	const messages = apiErrorResolveError(error, API_ERROR_AFFILIATE);
	return {
		messages,
	};
};
