import { IRegistrationApiParams, IRegistrationData } from 'module/registration';
import { TUseApiMutationModule, useApiMutationQuery } from 'js/queries/useApiMutationQuery';
import { IEntityPartner } from 'module/partners';
import { IPartnerRequestParams } from 'module/purchase';
import { ApiMutationTypeEnum } from 'js/enums';

const API_REGISTRATION_KEYS = {
	SET_INITIAL: 'partner/:partnerId/registration/init',
	MARK_AS_STARTED: 'partner/:partnerId/registration/start',
	SEND_REGISTRATION_FORM: 'partner/:partnerId/registration/partner-info',
	TAX_EXEMPTION: 'partner/:partnerId/registration/tax-exemption',
	FINISH: 'partner/:partnerId/registration/finish',
	ACCEPT_TERMS: 'partner/:partnerId/registration/accept-terms',
};

export const useApiRegistrationInit: TUseApiMutationModule<{}, IEntityPartner, IRegistrationApiParams> = (props) =>
	useApiMutationQuery(API_REGISTRATION_KEYS.SET_INITIAL, ApiMutationTypeEnum.POST, props);

export const useApiRegistrationStart: TUseApiMutationModule<{}, IEntityPartner, IRegistrationApiParams> = (props) =>
	useApiMutationQuery(API_REGISTRATION_KEYS.MARK_AS_STARTED, ApiMutationTypeEnum.POST, props);

export const useApiPostRegistrationForm: TUseApiMutationModule<IRegistrationData, IEntityPartner> = (props) =>
	useApiMutationQuery(API_REGISTRATION_KEYS.SEND_REGISTRATION_FORM, ApiMutationTypeEnum.POST, props);

export const useApiRegistrationFinish: TUseApiMutationModule<{}, IEntityPartner> = (props) =>
	useApiMutationQuery(API_REGISTRATION_KEYS.FINISH, ApiMutationTypeEnum.POST, props);

export const useApiAcceptTerms: TUseApiMutationModule<
	{ sessionToken: string },
	IEntityPartner,
	IPartnerRequestParams
> = (props) => useApiMutationQuery(API_REGISTRATION_KEYS.ACCEPT_TERMS, ApiMutationTypeEnum.POST, props);

export const useApiRegistrationUploadTaxExemption: TUseApiMutationModule<
	FormData,
	IEntityPartner,
	IPartnerRequestParams
> = (props) =>
	useApiMutationQuery(API_REGISTRATION_KEYS.TAX_EXEMPTION, ApiMutationTypeEnum.POST, props, {
		config: {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		},
	});
