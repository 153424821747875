import { Dropdown } from '@avast/react-ui-components';
import React from 'react';
import { IEntityLicense } from 'module/licenses';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { useRegisterCustomerToLicense } from 'module/licenses/hooks';

export const RegisterLicenseDropdownItem = ({ license }: { license: IEntityLicense }) => {
	const [t] = useTranslation(licensesConfig.trNamespace);
	const { initRegisterCustomerToLicense, isEnabled } = useRegisterCustomerToLicense(license);

	return isEnabled ? (
		<Dropdown.Item
			onClick={() => initRegisterCustomerToLicense(license)}
			testId="registerLicenseTo"
		>
			{t('actions.registerTo')}
		</Dropdown.Item>
	) : null;
};
