import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';
import { RouteName } from 'module/RouteName';
import { DynamicPageTitleItem } from 'js/components/molecules/DynamicPageTitle';
import { DynamicBreadcrumbsItem } from 'js/components/molecules/DynamicBreadcrumbs';
import { DistributionPartnerForm } from 'module/distributionPartners/forms/DistributionPartnerForm';
import { buildParametrizedRoute } from 'js/utils/common';
import { useNavigate } from 'react-router-dom';
import { useCreateDistributionPartner } from 'module/distributionPartners/hooks';

export const PageCreate = (): ReactElement => {
	const [t] = useTranslation(distributionPartnersConfig.trNamespace);
	const navigate = useNavigate();
	const createDistributionPartner = useCreateDistributionPartner();

	return (
		<div className="section__content mt-3">
			<DynamicPageTitleItem text={t('page.create.title')} />
			<DynamicBreadcrumbsItem href={RouteName.DISTRIBUTION_PARTNERS.CREATE}>
				{t('page.create.title')}
			</DynamicBreadcrumbsItem>

			<DistributionPartnerForm
				onSubmit={async (values, { setSubmitting }) => {
					const distributionPartner = await createDistributionPartner(values);
					setSubmitting(false);

					if (distributionPartner) {
						navigate(buildParametrizedRoute(RouteName.DISTRIBUTION_PARTNERS.DETAIL, distributionPartner.id));
					}
				}}
			/>
		</div>
	);
};
