import {
	IApiFlexibleBillingDetailFilter,
	IApiFlexibleBillingFilter,
	IEntityFlexibleBillingDetail,
	IFlexibleBillingDistributionPartner,
	IFlexibleBillingInviteRequest,
	IFlexibleBillingReport,
	IFlexibleBillingSpendingOverview,
	TFlexibleBillingForm,
	TFlexibleBillingUpdateRequest,
} from 'module/flexibleBilling';
import { TUseApiDetailModule, useApiDetailQuery } from 'js/queries/useApiDetailQuery';
import { TUseApiMutationModule, useApiMutationQuery } from 'js/queries/useApiMutationQuery';
import { ApiMutationTypeEnum } from 'js/enums';
import { TUseApiListModule, useApiListQuery } from 'js/queries/useApiListQuery';

export const API_FLEXIBLE_BILLING_KEYS = {
	DISTRIBUTION_PARTNER_LIST: 'pay-as-you-go/distributor/:distributorId/distribution-partner-list',
	DISTRIBUTION_PARTNER: 'pay-as-you-go/distributor/:distributorId/distribution-partner/:id',
	REPORT_LIST: 'pay-as-you-go/distributor/:distributorId/report',
	SPENDING_OVERVIEW: 'pay-as-you-go/distributor/:distributorId/distributor-spending-overview',
	DISTRIBUTION_PARTNER_SPENDING_OVERVIEW:
		'pay-as-you-go/distributor/:distributorId/distribution-partner/:id/spending-overview',
	DISTRIBUTION_PARTNER_REPORT_LIST: 'pay-as-you-go/distributor/:distributorId/distribution-partner/:id/report',
	DETAIL: 'pay-as-you-go/distributor/:distributorId/distribution-partner/:id/product-overview-list',
	UPDATE: 'pay-as-you-go/distributor/:distributorId/distribution-partner/:id/product-setting-list',
	INVITE: 'pay-as-you-go/distributor/:distributorId/distribution-partner/:id/invite-to-business-hub',
};

export const useApiFlexibleBillingDistributionPartnerList: TUseApiListModule<
	IFlexibleBillingDistributionPartner,
	IApiFlexibleBillingFilter
> = (props) => {
	return useApiListQuery(API_FLEXIBLE_BILLING_KEYS.DISTRIBUTION_PARTNER_LIST, props);
};

export const useApiFlexibleBillingReportList: TUseApiListModule<IFlexibleBillingReport, IApiFlexibleBillingFilter> = (
	props,
) => {
	return useApiListQuery(API_FLEXIBLE_BILLING_KEYS.REPORT_LIST, props);
};

export const useApiFlexibleBillingDistributionPartnerReportList: TUseApiListModule<
	IFlexibleBillingReport,
	IApiFlexibleBillingDetailFilter
> = (props) => {
	return useApiListQuery(API_FLEXIBLE_BILLING_KEYS.DISTRIBUTION_PARTNER_REPORT_LIST, props);
};

export const useApiFlexibleBillingDistributionPartner: TUseApiDetailModule<
	IFlexibleBillingDistributionPartner,
	IApiFlexibleBillingDetailFilter
> = (props) => {
	return useApiDetailQuery(API_FLEXIBLE_BILLING_KEYS.DISTRIBUTION_PARTNER, props);
};

export const useApiFlexibleBillingSpendingOverview: TUseApiDetailModule<
	IFlexibleBillingSpendingOverview,
	IApiFlexibleBillingFilter
> = (props) => {
	return useApiDetailQuery(API_FLEXIBLE_BILLING_KEYS.SPENDING_OVERVIEW, props);
};

export const useApiFlexibleBillingDistributionPartnerSpendingOverview: TUseApiDetailModule<
	IFlexibleBillingSpendingOverview,
	IApiFlexibleBillingDetailFilter
> = (props) => {
	return useApiDetailQuery(API_FLEXIBLE_BILLING_KEYS.DISTRIBUTION_PARTNER_SPENDING_OVERVIEW, props);
};

export const useApiFlexibleBillingDetail: TUseApiListModule<
	IEntityFlexibleBillingDetail,
	IApiFlexibleBillingDetailFilter
> = (props) => {
	return useApiListQuery(API_FLEXIBLE_BILLING_KEYS.DETAIL, props);
};

export const useApiFlexibleBillingUpdate: TUseApiMutationModule<
	TFlexibleBillingUpdateRequest,
	TFlexibleBillingForm,
	IApiFlexibleBillingDetailFilter
> = (props) => {
	return useApiMutationQuery(API_FLEXIBLE_BILLING_KEYS.UPDATE, ApiMutationTypeEnum.PUT, props);
};

export const useApiFlexibleBillingInvite: TUseApiMutationModule<
	IFlexibleBillingInviteRequest,
	null,
	IApiFlexibleBillingDetailFilter
> = (props) => {
	return useApiMutationQuery(API_FLEXIBLE_BILLING_KEYS.INVITE, ApiMutationTypeEnum.POST, props);
};
