import React, { PropsWithChildren, ReactElement, ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl } from '@avast/react-ui-components';
import { DataFilter, TDataFilterProps } from 'js/components/molecules/DataFilter/DataFilter';
import { SearchComboBox } from 'js/components/molecules/SearchBox';
import { Col, Row } from 'js/components/atoms/Row';
import { SelectPartner } from 'module/partners/components';
import { IOrderListFilter } from 'module/orders';
import {
	CreationSourceEnum,
	creationSourceEnumUtils,
	OrderDateRangeTypeEnum,
	orderDateRangeTypeEnumUtils,
	OrderSearchKeyEnum,
	orderSearchKeyEnumUtils,
	OrderStatusEnum,
	orderStatusEnumUtils,
	PurchaseTypeEnum,
	purchaseTypeEnumUtils,
} from 'module/orders/enums';
import { PaymentStatusEnum, paymentStatusEnumUtils } from 'js/enums';
import { FilterTypedRangeDatepicker } from 'js/components/molecules/Datepicker/FilterTypedRangeDatepicker';
import { SelectCustomer } from 'module/customers/components';
import { SelectDistributionPartner } from 'module/distributionPartners/components/SelectDistributionPartner';
import { useAuthContext } from 'js/contexts';
import { useIsPayAsYouGoEligible } from 'module/account/hooks/useIsPayAsYouGoEligible';
import { QuoteDateRangeTypeEnum, quoteDateRangeTypeEnumUtils } from 'module/orders/enums/quoteDateRangeTypeEnum';

type TFilter = IOrderListFilter;
type TFilterProps = TDataFilterProps<TFilter> & {
	controls?: ReactNode;
	isQuote?: boolean;
};

/**
 * Filter for orders
 * @param {React.PropsWithChildren<TFilterProps>} props
 * @returns {React.ReactElement}
 * @constructor
 */
export const OrdersFilter = (props: PropsWithChildren<TFilterProps>): ReactElement => {
	const [t] = useTranslation('moduleOrders');
	const { isGroupInternal, isGroupPartner, isRoleDistributor, authCompanyId } = useAuthContext();
	const { controls, isQuote, ...rest } = props;
	const isPayAsYouGoEligible = useIsPayAsYouGoEligible();

	// Function for resolve if filter fields should be disabled
	const isEnabled = useCallback((values: TFilter) => {
		return !(values.search?.key && values.search?.value);
	}, []);

	const omittedFields = useCallback(() => {
		const omitted: OrderSearchKeyEnum[] = [];

		isQuote ? omitted.push(OrderSearchKeyEnum.ORDER_NUMBER) : omitted.push(OrderSearchKeyEnum.QUOTE_NUMBER);
		!isGroupInternal && omitted.push(OrderSearchKeyEnum.OPPORTUNITY_ID);

		return omitted;
	}, [isQuote, isGroupInternal]);

	// order search keys memoization
	const orderSearchKeys = useMemo(
		() => orderSearchKeyEnumUtils.getSelectOptions({ omitted: omittedFields() }),
		[omittedFields],
	);

	const statusEnum = orderStatusEnumUtils.getSelectOptions({
		omitted: [OrderStatusEnum.PENDING_SUBMISSION],
		picked: isQuote ? orderStatusEnumUtils.getQuoteStatuses() : undefined,
	});

	return (
		<DataFilter<TFilter>
			useLocation
			{...rest}
			enabledRules={{
				partnerId: isEnabled,
				distributionPartnerId: isEnabled,
				customerId: isEnabled,
				statuses: isEnabled,
				creationSources: isEnabled,
				paymentStatus: isEnabled,
				dateRange: isEnabled,
				dateRangeType: isEnabled,
			}}
		>
			{({ values, isEnabledField, updateFilter }) => (
				<Row
					multi
					size="sm"
				>
					<Col
						xl={4}
						lg={5}
					>
						<SearchComboBox<OrderSearchKeyEnum>
							keys={orderSearchKeys}
							controlledValue={values.search}
							onSubmit={(value) => updateFilter({ search: value })}
							onCancel={(value) => updateFilter({ search: { ...value, value: '' } })}
						/>
					</Col>
					<Col
						xl={8}
						lg={7}
						className="text-end"
					>
						{controls}
					</Col>
					<Col
						xs={12}
						className="col-hr"
					/>
					{isGroupInternal && (
						<Col
							lg={3}
							md={4}
							sm={6}
						>
							<SelectPartner
								size="sm"
								value={values.partnerId}
								disabled={!isEnabledField('partnerId')}
								onChange={(value) => updateFilter({ partnerId: value })}
							/>
						</Col>
					)}
					{isRoleDistributor && (
						<Col
							lg={3}
							sm={6}
						>
							<SelectDistributionPartner
								size="sm"
								value={values.distributionPartnerId}
								distributorId={authCompanyId!}
								disabled={!isEnabledField('distributionPartnerId')}
								onChange={(distributionPartnerId) => updateFilter({ distributionPartnerId })}
							/>
						</Col>
					)}
					<Col
						lg={3}
						md={4}
						sm={6}
					>
						<SelectCustomer
							size="sm"
							partnerId={isGroupPartner ? authCompanyId : values.partnerId}
							value={values.customerId}
							disabled={!isEnabledField('customerId')}
							onChange={(value) => updateFilter({ customerId: value })}
						/>
					</Col>
					{!(isGroupPartner && isQuote) && (
						<Col
							lg={3}
							md={4}
							sm={6}
						>
							<FormControl label={isQuote ? t('entity.quoteStatus') : t('common:entity.orderStatus')}>
								<FormControl.MultiSelect<OrderStatusEnum>
									name="statuses"
									disabled={!isEnabledField('statuses')}
									value={values.statuses}
									onChange={(value) => updateFilter({ statuses: value || undefined })}
									size="sm"
									placeholder={t('entity.orderStatusPlaceholder')}
									options={statusEnum}
									closeMenuOnSelect={false}
								/>
							</FormControl>
						</Col>
					)}
					{!isQuote && (
						<Col
							lg={3}
							md={4}
							sm={6}
						>
							<FormControl label={t('common:entity.paymentStatus')}>
								<FormControl.SingleSelect<PaymentStatusEnum>
									name="paymentStatus"
									disabled={!isEnabledField('paymentStatus')}
									value={values.paymentStatus}
									onChange={(value) => updateFilter({ paymentStatus: value || undefined })}
									size="sm"
									placeholder={t('entity.paymentStatusPlaceholder')}
									options={paymentStatusEnumUtils.getSelectOptions()}
								/>
							</FormControl>
						</Col>
					)}
					{!isQuote && isGroupInternal && (
						<Col
							lg={3}
							md={4}
							sm={6}
						>
							<FormControl label={t('entity.creationSource')}>
								<FormControl.MultiSelect<CreationSourceEnum>
									name="creationSources"
									disabled={!isEnabledField('creationSources')}
									value={values.creationSources}
									onChange={(value) => updateFilter({ creationSources: value || undefined })}
									size="sm"
									placeholder={t('entity.creationSourcePlaceholder')}
									options={creationSourceEnumUtils.getSelectOptions()}
									closeMenuOnSelect={false}
								/>
							</FormControl>
						</Col>
					)}
					<Col
						lg={3}
						md={4}
						sm={6}
					>
						<FormControl label={t('entity.date')}>
							{isQuote ? (
								<FilterTypedRangeDatepicker<QuoteDateRangeTypeEnum>
									testId="date"
									defaultValue={QuoteDateRangeTypeEnum.CREATED}
									typeValues={quoteDateRangeTypeEnumUtils.getSelectOptions()}
								/>
							) : (
								<FilterTypedRangeDatepicker<OrderDateRangeTypeEnum>
									testId="date"
									defaultValue={OrderDateRangeTypeEnum.CREATED}
									typeValues={orderDateRangeTypeEnumUtils.getSelectOptions()}
								/>
							)}
						</FormControl>
					</Col>
					{isPayAsYouGoEligible && !isQuote && (
						<Col
							lg={3}
							md={4}
							sm={6}
						>
							<FormControl label={t('entity.purchaseType')}>
								<FormControl.SingleSelect<PurchaseTypeEnum>
									name="purchaseType"
									value={values.purchaseType}
									onChange={(value) => updateFilter({ purchaseType: value || undefined })}
									size="sm"
									placeholder={t('entity.purchaseTypePlaceholder')}
									options={purchaseTypeEnumUtils.getSelectOptions()}
								/>
							</FormControl>
						</Col>
					)}
				</Row>
			)}
		</DataFilter>
	);
};
