import React, { ReactElement, useState } from 'react';
import { ApiPaginatedListTable } from 'js/components/molecules/DataTable';
import { FilterPlaceholderPortal } from 'js/layouts/placeholder/FilterPlaceholder';
import { ICreditLimitListFilter, IEntityCreditLimit } from 'module/creditLimit';
import { useApiCreditLimitList } from 'module/creditLimit/hooks/useApiCreditLimit';
import { CreditLimitFilter } from 'module/creditLimit/components/CreditLimitFilter';
import { useCreditLimitListColumns } from 'module/creditLimit/hooks';

type TTableData = IEntityCreditLimit;
type TTableDataFilter = ICreditLimitListFilter;

export const PageList = (): ReactElement => {
	const columns = useCreditLimitListColumns();
	// Set changeable filter
	const [filter, setFilter] = useState<TTableDataFilter | null>(null);

	return (
		<>
			<FilterPlaceholderPortal>
				<CreditLimitFilter
					values={filter}
					onChange={setFilter}
				/>
			</FilterPlaceholderPortal>
			<ApiPaginatedListTable<TTableData, TTableDataFilter>
				columns={columns}
				query={useApiCreditLimitList}
				useLocation
				filter={filter}
				table={{
					testId: 'creditLimitList',
					enableSorting: false,
				}}
			/>
		</>
	);
};
