import { LinkButton } from 'js/components/atoms/Button';
import { RouteName } from 'module/RouteName';
import { Trans, useTranslation } from 'react-i18next';
import type { TToastProps } from '@avast/react-ui-components';
import { Toast } from '@avast/react-ui-components';
import React from 'react';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { IEntityProductGroup } from 'module/purchase';
import { TSelectVariantItem } from 'module/purchase/components/selectProducts';
import { getProductValidityLabels } from 'module/purchase/utils/common';
import { toast } from 'react-toastify';

type TToastSuccessProps = {
	productGroup: IEntityProductGroup;
	items: TSelectVariantItem[];
	isUpdateOrderMode?: boolean;
};

export const ToastSuccess = (props: TToastSuccessProps & TToastProps) => {
	const { productGroup, items, isUpdateOrderMode, ...rest } = props;
	const [t] = useTranslation(purchaseConfig.trNamespace);

	return (
		<Toast
			{...rest}
			variant="success"
			caption={t('message.addToCart.title')}
			bottomPlaceholder={
				isUpdateOrderMode ? null : (
					<LinkButton
						size="sm"
						to={RouteName.PURCHASE.ORDER}
						testId="goToCart"
						onClick={() => toast.dismiss()}
					>
						{t('actions.goToCart')}
					</LinkButton>
				)
			}
		>
			<strong className="text-secondary">{productGroup.name}</strong>
			{items.map((item) => {
				const labels = getProductValidityLabels(item.product);
				return (
					<span key={item.id}>
						<br />
						<Trans
							t={t}
							values={{
								name: labels.label,
								quantity: item.quantity,
								unit: item.unit,
								unitLabel: t(`common:entity.unitType.${item.product.group.unitType}`, {
									count: item.unit,
								}),
							}}
						>
							{'message.addToCart.item'}
						</Trans>
					</span>
				);
			})}
		</Toast>
	);
};
