import { toast } from 'react-toastify';
import { Card, CardBody, Toast } from '@avast/react-ui-components';
import React, { useCallback } from 'react';
import { useApiRegisterLicense } from 'module/licenses/hooks';
import { IEntityLicense } from 'module/licenses';
import { IEntityCustomer } from 'module/customers';
import { useTranslation } from 'react-i18next';
import { licensesConfig } from 'module/licenses/licensesConfig';
import { getCustomerRenderName } from 'module/customers/utils/customerSelectors';
import { getLicenseExternalIds, showEntitlementId } from 'module/licenses/utils/common';
import { CustomerCard } from 'module/customers/components';
import { registerLicenseApiError } from 'module/licenses/utils/apiError';
import { gtm } from 'js/analytics/gtm';
import { useApiErrorContext, useAppContext, useAuthContext, useCommonContext } from 'js/contexts';
import { TPartnerId } from 'types';
import { useAsyncLicensePartner } from 'module/licenses/hooks/useLicenseParty';

type TRegisterCustomerToLicense = {
	isEnabled?: boolean;
	initRegisterCustomerToLicense(license: IEntityLicense): Promise<void>;
};

export const useRegisterCustomerToLicense = (license: IEntityLicense): TRegisterCustomerToLicense => {
	const { loadingModalRef, confirmationModalRef, apiErrorModalRef } = useAppContext();
	const { setError } = useApiErrorContext();
	const [t] = useTranslation(licensesConfig.trNamespace);
	const licenseId = license.id;
	const getLicensePartner = useAsyncLicensePartner();
	const { selectCustomerRef } = useCommonContext();
	const { isGroupInternal, authCompanyId } = useAuthContext();

	const { mutate: updateLicense } = useApiRegisterLicense({
		config: {
			params: {
				entitlementId: licenseId.toString(),
			},
		},
	});

	const isConfirmedLicenseRegistration = useCallback(
		async (customer: IEntityCustomer): Promise<boolean> => {
			const { customerId } = getLicenseExternalIds(license);
			const message = customerId
				? t('registration.confirmation.replace', { licenseId: showEntitlementId(license.id) })
				: t('registration.confirmation.place');
			const customerCard = (
				<Card>
					<CardBody>
						<CustomerCard customer={customer!} />
					</CardBody>
				</Card>
			);

			return Boolean(
				await confirmationModalRef.current?.show({
					title: t('registration.confirmation.title'),
					messages: [message],
					submitButtonText: t('common:actions.confirm'),
					content: customerCard,
				}),
			);
		},
		[confirmationModalRef, t, license],
	);

	const registerCustomerToLicense = useCallback(
		async (customer?: IEntityCustomer | null) => {
			if (customer?.id) {
				if (!(await isConfirmedLicenseRegistration(customer))) {
					return;
				}

				loadingModalRef.current?.show({ title: t('registration.registering') });
				gtm.registerLicense(licenseId);
				updateLicense(
					{ customerId: customer.id },
					{
						onSuccess() {
							loadingModalRef.current?.hide();
							toast.success(<Toast>{t('registration.success', { customer: getCustomerRenderName(customer) })}</Toast>);
						},
						onError(error) {
							loadingModalRef.current?.hide();
							setError({ error, resolve: registerLicenseApiError });
						},
					},
				);
			}
		},
		[isConfirmedLicenseRegistration, loadingModalRef, setError, t, updateLicense, licenseId],
	);

	const initRegisterCustomerToLicense: TRegisterCustomerToLicense['initRegisterCustomerToLicense'] = useCallback(
		async (license) => {
			// Partner id
			let partnerId: TPartnerId | undefined | null;
			if (isGroupInternal) {
				loadingModalRef.current?.show({ title: t('common:_.loading') });
				const licensePartner = await getLicensePartner(license);
				if (licensePartner.hasPartner && !licensePartner.partner) {
					await apiErrorModalRef.current?.show({
						error: {
							messages: [t('error.operation.partner.unableToLoad')],
						},
					});
					loadingModalRef.current?.hide();
					return;
				}
				partnerId = licensePartner.partner?.id;
				loadingModalRef.current?.hide();
			} else {
				partnerId = authCompanyId;
			}

			// Select customer
			const customer = await selectCustomerRef.current?.show({
				partnerId,
				enableCreate: true,
			});

			await registerCustomerToLicense(customer);
		},
		[
			selectCustomerRef,
			authCompanyId,
			isGroupInternal,
			registerCustomerToLicense,
			loadingModalRef,
			apiErrorModalRef,
			getLicensePartner,
			t,
		],
	);

	return {
		initRegisterCustomerToLicense,
		isEnabled: Boolean(getLicenseExternalIds(license).partnerSalesforceId),
	};
};
