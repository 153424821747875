import React, { ReactElement, useCallback, useState } from 'react';
import { DefaultContainer } from 'js/layouts/container/DefaultContainer';
import { useAuthContext } from 'js/contexts';
import { PageDetail } from 'module/creditStatus/pages/PageDetail';
import { IApiSortBy } from 'types/api';
import { SortDirectionEnum } from 'js/enums';
import { useCreditStatusListColumns } from 'module/creditStatus/hooks';
import { ICreditStatusListFilter, IEntityCreditStatus } from 'module/creditStatus/index';
import { buildParametrizedRoute } from 'js/utils/common';
import { RouteName } from 'module/RouteName';
import { FilterPlaceholderPortal } from 'js/layouts/placeholder/FilterPlaceholder';
import { CreditStatusFilter } from 'module/creditStatus/components';
import { ApiPaginatedListTable } from 'js/components/molecules/DataTable';
import { useApiCreditStatusList } from 'module/creditStatus/hooks/useApiCreditStatus';
import { useNavigate } from 'react-router-dom';

type TTableData = IEntityCreditStatus;
type TTableDataFilter = ICreditStatusListFilter;

export const PageDefault = (): ReactElement => {
	const { isGroupPartner, authCompanyId } = useAuthContext();
	const navigate = useNavigate();

	// Set changeable filter
	const [filter, setFilter] = useState<TTableDataFilter | null>(null);

	// Table args
	const [sort] = useState<IApiSortBy<TTableData>>({ key: 'companyName', direction: SortDirectionEnum.ASC });

	// Define columns
	const columns = useCreditStatusListColumns();

	const onRowClick = useCallback(
		(row: IEntityCreditStatus) => navigate(buildParametrizedRoute(RouteName.CREDIT_STATUS.DETAIL, row.partnerId)),
		[navigate],
	);

	return isGroupPartner ? (
		<PageDetail id={authCompanyId} />
	) : (
		<DefaultContainer>
			<>
				<FilterPlaceholderPortal>
					<CreditStatusFilter
						values={filter}
						onChange={setFilter}
					/>
				</FilterPlaceholderPortal>
				<ApiPaginatedListTable<TTableData, TTableDataFilter>
					columns={columns}
					query={useApiCreditStatusList}
					sort={sort}
					useLocation
					filter={filter}
					table={{
						testId: 'creditStatusList',
						enableSorting: false,
						meta: {
							onRowClick,
						},
					}}
				/>
			</>
		</DefaultContainer>
	);
};
