import { TUseApiPaginatedListModule, useApiPaginatedListQuery } from 'js/queries/useApiPaginatedListQuery';
import { TUseApiDetailModule, useApiDetailQuery } from 'js/queries/useApiDetailQuery';
import {
	ICreditStatusListFilter,
	IEntityCreditStatus,
	IEntityCreditStatusFilter,
	IEntityCreditTransaction,
	IEntityCreditTransactionFilter,
} from 'module/creditStatus';

export const API_CREDIT_STATUS_KEYS = {
	LIST: 'credit-status',
	DETAIL: 'partner/:id/oms-credit-status',
	TRANSACTION_LIST: 'partner/:partnerId/credit-transaction',
};

export const useApiCreditStatusList: TUseApiPaginatedListModule<IEntityCreditStatus, ICreditStatusListFilter> = (
	props,
) => useApiPaginatedListQuery(API_CREDIT_STATUS_KEYS.LIST, props);

export const useApiCreditStatus: TUseApiDetailModule<IEntityCreditStatus, IEntityCreditStatusFilter> = (props) => {
	return useApiDetailQuery(API_CREDIT_STATUS_KEYS.DETAIL, props, {
		config: {
			// 404 Not found is valid response, partner is credit eligible, but do not have a credit limit
			catchError: (error) => error.response?.status !== 404,
		},
	});
};

export const useApiCreditTransactionList: TUseApiPaginatedListModule<
	IEntityCreditTransaction,
	IEntityCreditTransactionFilter
> = (props) =>
	useApiPaginatedListQuery(API_CREDIT_STATUS_KEYS.TRANSACTION_LIST, props, {
		config: {
			params: { excludeSources: ['admin-custom'] },
		},
	});
