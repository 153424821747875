import {
	useClosingBalanceWidget,
	useCreditStatusWidget,
	useExpiredLicensesLastNDaysWidget,
	useExpiringLicensesNextNDaysWidget,
	useOrdersWidget,
	useOverduePaymentWidget,
	useQuotesWidget,
} from 'submodule/widgets/config';
import { TWidgetConfig } from 'submodule/widgets';

export const useWidgets = (): TWidgetConfig[] => [
	useOrdersWidget(),
	useQuotesWidget(),
	useCreditStatusWidget(),
	useClosingBalanceWidget(),
	useExpiredLicensesLastNDaysWidget(),
	useExpiringLicensesNextNDaysWidget(),
	useOverduePaymentWidget(),
];
