import React, { ReactElement, useCallback, useState } from 'react';
import {
	Button,
	ButtonComposition,
	Card,
	CardBody,
	CardHeader,
	IconButton,
	Toast,
	Tooltip,
} from '@avast/react-ui-components';
import { Trans, useTranslation } from 'react-i18next';
import { PartnerCard } from 'module/partners/components';
import { IconChange, IconClose } from 'assets/image/icon';
import { SelectCardState } from 'module/purchase/components/widget';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { BillablePartyText } from 'module/purchase/components/widget/BillablePartyText';
import {
	AsyncSelectPartnerModal,
	TAsyncSelectPartnerModalProps,
} from 'module/partners/components/AsyncSelectPartnerModal';
import { useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { IEntityPartner } from 'module/partners';
import { useSetPartnerToOrderInstance } from 'module/purchase/hooks/order';
import { logDebug } from 'js/utils/app';
import { toast } from 'react-toastify';
import { useOrderContext } from 'js/contexts';

const widgetTrPrefix = 'widget.selectPartnerCard';

/**
 * Card component to select partner
 * @return {React.ReactElement}
 * @constructor
 */
export const SelectPartnerCard = (): ReactElement => {
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const { orderState, removePartner, hasBillableParty, isPartnerBillableParty } = useOrderContext();
	const [loading, setLoading] = useState(false);
	const selectPartnerRef = useAsyncModalRef<TAsyncSelectPartnerModalProps, IEntityPartner>();
	const setPartnerToOrderInstance = useSetPartnerToOrderInstance();

	const partner = orderState?.partner;
	const partnerId = partner?.id;

	const selectPartner = useCallback(async () => {
		setLoading(true);
		const selectedPartner = await selectPartnerRef.current?.show({
			selectedPartnerId: partnerId,
		});

		// Nothing selected
		if (!selectedPartner?.id) {
			setLoading(false);
			return;
		}

		// Add partner to the order
		await setPartnerToOrderInstance(selectedPartner.id).catch((error) => {
			logDebug(error);
			toast.error(<Toast>{t('error.context.setPartnerFailed')}</Toast>);
		});
		setLoading(false);
	}, [t, selectPartnerRef, partnerId, setPartnerToOrderInstance]);

	return (
		<Card active={isPartnerBillableParty}>
			<CardHeader>{t(`${widgetTrPrefix}.title`)}</CardHeader>
			<CardBody>
				{(() => {
					if (loading) {
						return <LoadingPlaceholder />;
					}

					if (partner) {
						return (
							<div className="state-result">
								<ButtonComposition
									size="xs"
									className="justify-content-end"
								>
									<Tooltip
										content={t(`${widgetTrPrefix}.tooltips.change`)}
										placement={'bottom'}
									>
										<IconButton
											variant="outline-primary"
											size="xs"
											icon={<IconChange />}
											onClick={selectPartner}
											testId="changePartner"
										/>
									</Tooltip>
									<Tooltip
										content={t(`${widgetTrPrefix}.tooltips.cancel`)}
										placement={'bottom'}
									>
										<IconButton
											variant="outline-primary"
											size="xs"
											icon={<IconClose />}
											onClick={() => removePartner()}
											testId="unsetPartner"
										/>
									</Tooltip>
								</ButtonComposition>
								<PartnerCard
									partner={partner}
									className="mt-3 content"
								/>
								<BillablePartyText
									isBillable={isPartnerBillableParty}
									className="mt-3"
								/>
							</div>
						);
					}

					// No value
					return (
						<SelectCardState
							note={<Trans t={t}>{`${widgetTrPrefix}.note`}</Trans>}
							isBillable={!hasBillableParty || isPartnerBillableParty}
						>
							<p>
								<Button
									variant={'outline-primary'}
									size="sm"
									onClick={selectPartner}
									testId="selectPartner"
								>
									{t(`${widgetTrPrefix}.select`)}
								</Button>
							</p>
						</SelectCardState>
					);
				})()}
				<AsyncSelectPartnerModal forwardedRef={selectPartnerRef} />
			</CardBody>
		</Card>
	);
};
