import React, { PropsWithChildren, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ordersConfig } from 'module/orders/ordersConfig';
import { useApiOrderList } from 'module/orders/hooks/useApiOrders';
import { RouteName } from 'module/RouteName';
import { IEntityOrder, IOrderListFilter } from 'module/orders';
import { VisibilityState } from '@tanstack/table-core/src/features/ColumnVisibility';
import { TPartnerId } from 'types';
import { WidgetTypeEnum, widgetTypeEnumUtils } from 'submodule/widgets/enums';
import { useNavigate } from 'react-router-dom';
import { OrderDateRangeTypeEnum, OrderStatusEnum } from 'module/orders/enums';
import { dateTimeToFilterDate } from 'js/utils/dateTime';
import { DateTime } from 'luxon';
import { useOverduePaymentWidgetColumns } from 'submodule/widgets/tableColumns/useOverduePaymentWidgetColumns';
import { PaymentStatusEnum, SortDirectionEnum } from 'js/enums';
import { object2UrlParams, tableFilterCreator } from 'js/utils/common';
import { CardTable } from 'js/components/molecules/DataTable/CardTable';

type TRecentOrdersWidgetProps = React.HTMLAttributes<HTMLDivElement> & {
	limit?: number;
	header?: string;
	columnVisibility?: VisibilityState;
	partnerId?: TPartnerId;
};

export const OverduePaymentWidget = (props: PropsWithChildren<TRecentOrdersWidgetProps>): ReactElement => {
	const { columns, columnVisibility: defaultColumnVisibility } = useOverduePaymentWidgetColumns();
	const { limit = 5, header, columnVisibility = defaultColumnVisibility, partnerId, ...rest } = props;

	const [t] = useTranslation(ordersConfig.trNamespace);
	const navigate = useNavigate();
	const queryFilter: IOrderListFilter = {
		partnerId,
		dateRangeType: OrderDateRangeTypeEnum.PAYMENT_DUE,
		dateRange: [
			dateTimeToFilterDate(DateTime.now().startOf('day').minus({ days: 51 })),
			dateTimeToFilterDate(DateTime.now().endOf('day').minus({ day: 1 })),
		] as [string, string],
		paymentStatus: PaymentStatusEnum.UNPAID,
		statuses: [OrderStatusEnum.WAITING_PAYMENT, OrderStatusEnum.COMPLETE, OrderStatusEnum.POSTED_TO_STATEMENT],
	};

	const {
		data,
		query: { isFetching },
	} = useApiOrderList({
		filter: queryFilter,
		config: { limit },
		// paymentDueDate is an exception for key and allows dueDate orders sorting
		// @ts-ignore
		sortBy: { key: 'paymentDueDate', direction: SortDirectionEnum.ASC },
	});

	return (
		<CardTable<IEntityOrder>
			testId="Overdue Orders widget"
			{...rest}
			columns={columns}
			caption={header || t(widgetTypeEnumUtils.getCaption(WidgetTypeEnum.OVERDUE_PAYMENT))}
			data={data?.items ?? []}
			onViewMore={() =>
				navigate({
					pathname: RouteName.ORDER.LIST,
					search: object2UrlParams({
						...tableFilterCreator<IOrderListFilter>(queryFilter),
						...{ sortBy: 'paymentDueDate', sortDirection: SortDirectionEnum.ASC },
					}),
				})
			}
			tableProps={{
				state: { columnVisibility },
				meta: {
					rowCount: limit,
					loading: isFetching,
					onRowClick(order) {
						navigate(ordersConfig.detailLink(order));
					},
					isScrollable: true,
				},
			}}
		/>
	);
};
