import React from 'react';
import { usePageDetailContext } from 'js/contexts';
import { useTranslation } from 'react-i18next';
import { Button } from '@avast/react-ui-components';
import { useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { AsyncInitiateRefundModal, AsyncSuccessRefundModal } from 'submodule/refunds';
import { useIsInitiateRefundEligible } from 'js/hooks/useIsInitiateRefundEligible';
import { TChargeOrderId, TPeriodActivityUuid, TStandardOrderId } from 'types';

export interface IInitiateRefundButtonData {
	id: TStandardOrderId;
	isOrderRefundableByRequester: boolean;
	chargeOrderId?: TChargeOrderId;
	periodActivityUuid?: TPeriodActivityUuid;
}

interface IInitiateRefundButtonProps {
	showRefundsModal: () => void;
}

export const InitiateRefundButton = <T extends IInitiateRefundButtonData>(props: IInitiateRefundButtonProps) => {
	const {
		data,
		query: { isFetching },
	} = usePageDetailContext<T>();
	const isInitiateRefundEligible = useIsInitiateRefundEligible();
	const [t] = useTranslation('submodules');
	const initiateRefundModalRef = useAsyncModalRef();
	const successRefundModalRef = useAsyncModalRef();

	if (!isInitiateRefundEligible || !data.isOrderRefundableByRequester || !data.chargeOrderId) {
		return null;
	}

	return (
		<>
			<Button
				size="sm"
				loading={isFetching}
				variant="outline-primary"
				onClick={() => initiateRefundModalRef.current?.show()}
				testId="initiateRefund"
			>
				{t('refunds.initiate.initiateRefund')}
			</Button>

			<AsyncInitiateRefundModal
				forwardedRef={initiateRefundModalRef}
				id={data.id}
				chargeOrderId={data.chargeOrderId}
				successRefundModalRef={successRefundModalRef}
			/>
			<AsyncSuccessRefundModal
				forwardedRef={successRefundModalRef}
				showOrderRefunds={props.showRefundsModal}
			/>
		</>
	);
};
