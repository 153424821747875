import React from 'react';
import { Modal } from '@avast/react-ui-components';
import { Beforeunload } from 'react-beforeunload';
import { useTranslation } from 'react-i18next';
import { AsyncModal, TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';

export type TAsyncModalLoadingProps = {
	ellipsis?: boolean;
	title?: string;
	disableBeforeUnload?: boolean;
};

export const AsyncModalLoading = (props: TAsyncModalContainerProps<TAsyncModalLoadingProps>) => {
	const { forwardedRef } = props;
	const [t] = useTranslation('error');

	return (
		<AsyncModal<TAsyncModalLoadingProps>
			ref={forwardedRef}
			show
			size="sm"
			backdrop="static"
			testId="loading"
		>
			{({ title = t('common:_.loading'), disableBeforeUnload, ellipsis = false }) => (
				<>
					<Modal.Body className="text-center py-8">
						<LoadingPlaceholder minHeight={false} />
						<p className="mt-1 h3 fw-bold text-truncate">
							{title}
							{ellipsis && '...'}
						</p>
					</Modal.Body>
					{disableBeforeUnload ? null : <Beforeunload onBeforeunload={() => t('beforeUnload')} />}
				</>
			)}
		</AsyncModal>
	);
};
