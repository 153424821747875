import { useApi } from 'js/hooks/api';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { TUseApiProps } from 'js/hooks/api/useApi';
import type { QueryFunctionContext } from '@tanstack/react-query';
import { TDetailId } from 'types';
import { fillUrlParameters } from 'js/utils/common';

type TUseApiDetailProps = TUseApiProps;

export type TUseApiDetailQueryKey<Filter> = [string, TDetailId, Filter];
type TUseApiDetailContextQuery<Filter> = QueryFunctionContext<TUseApiDetailQueryKey<Filter>>;

type TUseApiDetail<Data extends {}, Filter extends {}> = (
	context: TUseApiDetailContextQuery<Filter>,
) => Promise<AxiosResponse<Data>>;

export const useApiDetail = <Data extends {}, Filter extends {} = {}>(
	props?: TUseApiDetailProps,
): TUseApiDetail<Data, Filter> => {
	const { config } = props || {};
	const api = useApi({ config: { ...config, disableRetry: true } });

	return (context) => {
		const [key, id, filter] = context.queryKey;
		const [route, _filter] = fillUrlParameters<Filter>(key, { id, ...filter });
		const source = axios.CancelToken.source();

		const promise = api.get<Data>(`/${route}`, {
			cancelToken: source.token,
			params: _filter,
			...config,
		});

		// Cancel the request if React Query calls the `promise.cancel` method
		// @ts-ignore
		promise.cancel = () => {
			source.cancel('Query was canceled by React Query');
		};

		return promise;
	};
};
