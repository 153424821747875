import { createAxiosInstance } from 'api/setupInterceptors';
import { IAuthTokenResponse } from 'js/reducers/authContextReducer';
import { ApiTypeEnum } from 'js/enums';

export const getApiSsoPromise = () => {
	const api = createAxiosInstance({ apiType: ApiTypeEnum.SCH });

	return api.get<IAuthTokenResponse>('custom/utils/uem/getOmsJwtToken.php', {
		apiType: ApiTypeEnum.SCH,
	});
};

export const getApiOmsSsoPromise = () => {
	const api = createAxiosInstance({
		withCredentials: true,
		authToken: null,
	});

	return api.get<IAuthTokenResponse>('/jwt/token');
};
