import { Col, Row } from 'js/components/atoms/Row';
import { SearchBox } from 'js/components/molecules/SearchBox';
import { Button, ButtonComposition } from '@avast/react-ui-components';
import React from 'react';
import { IEntityProductGroup } from 'module/purchase';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { MarketSegmentEnum, marketSegmentEnumUtils } from 'js/enums';

export type TSelectProductFilter = {
	searchValue?: string;
	entity: Partial<IEntityProductGroup>;
};

type TSelectProductsFilterProps = {
	filter: TSelectProductFilter;
	onChange: (filter: TSelectProductFilter) => void;
	marketSegments?: MarketSegmentEnum[];
};

export const SelectProductsFilter = (props: TSelectProductsFilterProps) => {
	const { filter, onChange, marketSegments = [] } = props;
	const isMarketSegmentFilterAllowed = marketSegments.length > 1;
	const [t] = useTranslation(purchaseConfig.trNamespace);

	return (
		<Row className="mt-1">
			<Col
				sm="auto"
				className="mb-3"
			>
				<SearchBox
					size="sm"
					placeholder={t('table.filter.search.placeholder')}
					value={filter.searchValue}
					onChange={(value) => {
						onChange({
							...filter,
							searchValue: value,
						});
					}}
					delay={50}
					inline
					autoFocus
				/>
			</Col>
			{isMarketSegmentFilterAllowed && (
				<Col
					sm="auto"
					className="mb-3"
				>
					<ButtonComposition
						size="xs"
						testId="marketSegment"
					>
						{marketSegments.map((key) => (
							<Button
								key={key}
								variant="outline-primary"
								onClick={() => {
									onChange({
										...filter,
										entity: {
											...filter.entity,
											marketSegment: filter.entity.marketSegment === key ? undefined : key,
										},
									});
								}}
								size="sm"
								active={filter.entity.marketSegment === key}
								testId={key}
							>
								{`${t('table.filter.marketSegment', { type: marketSegmentEnumUtils.getText(key) })}`}
							</Button>
						))}
					</ButtonComposition>
				</Col>
			)}
		</Row>
	);
};
