import { useCallback } from 'react';
import { useApiLogout } from 'module/security/hooks/useApiSecurity';
import { useAuthContext, useOrderContext } from 'js/contexts';

export const useLogout = () => {
	const { logout } = useAuthContext();
	const { mutateAsync: logoutAsync } = useApiLogout();
	const { resetOrder } = useOrderContext();

	return useCallback(
		async (keepState?: boolean) => {
			try {
				// Logout on API
				await logoutAsync({});
			} catch (e: unknown) {
				// Pass
			}

			// Reset cache & order & contexts
			if (!keepState) {
				resetOrder();
			}

			// Invalid Auth Context
			logout();
		},
		[logoutAsync, logout, resetOrder],
	);
};
