import { DataTableTr } from 'js/components/molecules/DataTable';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { promotionsConfig } from 'module/promotions/promotionsConfig';
import { useApiPartner } from 'module/partners/hooks/useApiPartners';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import { IEntityPromotion } from 'module/promotions';
import { getPartnerRenderName } from 'module/partners/utils/partnerSelectors';

export const PartnerRow = () => {
	const [t] = useTranslation(promotionsConfig.trNamespace);
	const { data: promotion } = usePageDetailContext<IEntityPromotion>();

	const {
		data: partner,
		query: { isFetching },
	} = useApiPartner({
		id: promotion?.partnerId,
		queryConfig: { enabled: Boolean(promotion?.partnerId) },
	});

	return (
		<DataTableTr
			name={t('common:entity.partner')}
			defaultValue={t('common:_.NA')}
			isLoading={isFetching}
			highlighted
		>
			{getPartnerRenderName(partner)}
		</DataTableTr>
	);
};
