import i18n from 'i18n';
import type { ArrayLocale, DateLocale, MixedLocale, MixedSchema, NumberLocale, StringLocale, StringSchema } from 'yup';
import * as Yup from 'yup';
import { rUrl } from 'js/utils/validator';
import { checkMimeType, getFileSizeMB } from 'js/components/fileUpload/fileUploadUtils';
import { CONFIG } from 'config';

/**
 * Add custom YUP validators
 *
 * @returns {void}
 */
export const addValidators = (): void => {
	Yup.addMethod<StringSchema>(Yup.string, 'link', linkValidator);
	Yup.addMethod<MixedSchema>(Yup.mixed, 'fileMaxSize', fileMaxSizeValidator);
	Yup.addMethod<MixedSchema>(Yup.mixed, 'fileMimeType', fileMimeTypeValidator);
};

function linkValidator(this: StringSchema<string | undefined, object>): StringSchema<string | undefined, object> {
	return this.matches(new RegExp(rUrl, 'i'), {
		message: i18n.t('form:validator.string.link'),
		excludeEmptyString: true,
	});
}

function fileMaxSizeValidator(
	this: MixedSchema<{} | null | undefined, object>,
	maxSize: number = CONFIG.APP.MAX_FILE_SIZE,
): MixedSchema<{} | null | undefined, object> {
	return this.test(
		'fileMaxSize',
		({ value: file }) => {
			if (file) {
				const size = getFileSizeMB(file);
				return i18n.t('form:error.file.maxSize', { size, maxSize });
			}
			return null;
		},
		(file) => {
			if (!file) {
				return true;
			}
			return getFileSizeMB(file) <= maxSize;
		},
	);
}

function fileMimeTypeValidator(
	this: MixedSchema<{} | null | undefined, object>,
	mimeType: string,
): MixedSchema<{} | null | undefined, object> {
	return this.test('fileMimeType', i18n.t('form:error.file.type'), (file) => {
		if (!file) {
			return true;
		}
		return checkMimeType(file, mimeType);
	});
}

/**
 * Function for generate object with translated values for each key what is passed to the function
 *
 * @param {string} namespace
 * @param {string[]} keys
 * @returns {{}}
 */
const makeLocaleObject = <Type extends {}>(namespace: string, keys: Extract<keyof Type, string>[]): Type => {
	return keys.reduce<Type>((acc, key) => {
		return { ...acc, [key]: i18n.t(`form:validator.${namespace}.${key}`) };
	}, {} as Type);
};

/**
 * Set current locale translations for YUP Validator
 *
 * @returns {void}
 */
export const setLocale = (): void => {
	Yup.setLocale({
		mixed: makeLocaleObject<MixedLocale>('mixed', ['default', 'required', 'oneOf', 'notOneOf']),
		string: makeLocaleObject<StringLocale>('string', [
			'length',
			'min',
			'max',
			'matches',
			'email',
			'url',
			'uuid',
			'trim',
			'lowercase',
			'uppercase',
			'link',
		]),
		number: makeLocaleObject<NumberLocale>('number', [
			'min',
			'max',
			'lessThan',
			'moreThan',
			'positive',
			'negative',
			'integer',
		]),
		date: makeLocaleObject<DateLocale>('number', ['min', 'max']),
		array: makeLocaleObject<ArrayLocale>('number', ['min', 'max']),
	});
};
