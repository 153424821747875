import { ButtonComposition, IconButton, Tooltip } from '@avast/react-ui-components';
import React from 'react';
import { TWidgetGridRow } from 'submodule/widgets/index';
import { Row } from 'js/components/atoms/Row';
import { WidgetColumnContainer } from 'submodule/widgets/components/WidgetColumnContainer';
import { IconOneColumn, IconTwoColumns } from 'assets/image/icon';
import { useWidgetContext, useWidgetEditContext } from 'submodule/widgets/context';
import { WidgetColumnWidthEnum } from 'submodule/widgets/enums/widgetColumnWidthEnum';
import { RowDivider } from 'submodule/RowDivider';

type TStepperButtonComposition = {
	isSingleColumn: boolean;
	onOneColumnClick: () => void;
	onTwoColumnsClick: () => void;
};

const RowDividerButtons = ({ isSingleColumn, onOneColumnClick, onTwoColumnsClick }: TStepperButtonComposition) => {
	const { t } = useWidgetContext();
	const getVariant = (isActive: boolean) => (isActive ? 'primary' : 'outline-primary');

	return (
		<ButtonComposition size="xs">
			<Tooltip content={t('widgets.common.oneColumnRow')}>
				<IconButton
					onClick={onOneColumnClick}
					variant={getVariant(isSingleColumn)}
					icon={<IconOneColumn />}
					size="sm"
				/>
			</Tooltip>
			<Tooltip content={t('widgets.common.twoColumnsRow')}>
				<IconButton
					onClick={onTwoColumnsClick}
					variant={getVariant(!isSingleColumn)}
					icon={<IconTwoColumns />}
					size="sm"
				/>
			</Tooltip>
		</ButtonComposition>
	);
};

export const WidgetRowContainer = ({ row, rowIndex }: { row: TWidgetGridRow; rowIndex: number }) => {
	const { dispatch } = useWidgetEditContext();

	const onOneColumnClick = () => dispatch({ type: 'SET_ONE_COLUMN', payload: rowIndex });
	const onTwoColumnsClick = () => dispatch({ type: 'SET_TWO_COLUMNS', payload: rowIndex });

	return (
		<div
			key={`row${rowIndex}`}
			className="widget-row"
		>
			<RowDivider
				className="mb-2"
				middleComponent={
					<RowDividerButtons
						isSingleColumn={row.length === 1}
						onOneColumnClick={onOneColumnClick}
						onTwoColumnsClick={onTwoColumnsClick}
					/>
				}
			/>
			<Row
				size="sm"
				multi
			>
				{row.map((column, colIndex) => (
					<WidgetColumnContainer
						key={`widget-column-${colIndex}`}
						column={column}
						coords={{ colIndex, rowIndex }}
						width={row.length === 1 ? WidgetColumnWidthEnum.FULL : WidgetColumnWidthEnum.HALF}
					/>
				))}
			</Row>
		</div>
	);
};
