import { parseEnvInt } from 'js/utils/app';
import { TApiErrorList } from 'types/api';
import { API_ERR_CODE } from 'constant';

export const PARTNER_GROUP = {
	CREDIT_ELIGIBLE: parseEnvInt(process.env.REACT_APP_PARTNER_GROUP_CREDIT_ELIGIBLE),
	CLOSING_BALANCE: parseEnvInt(process.env.REACT_APP_PARTNER_GROUP_CLOSING_BALANCE),
	BUSINESS_PARTNER: parseEnvInt(process.env.REACT_APP_PARTNER_GROUP_BUSINESS_PARTNER),
	CONSUMER_PARTNER: parseEnvInt(process.env.REACT_APP_PARTNER_GROUP_CONSUMER_PARTNER),
	CONSUMER_TEAM: parseEnvInt(process.env.REACT_APP_PARTNER_GROUP_CONSUMER_TEAM),
	IS_RETAIL: parseEnvInt(process.env.REACT_APP_PARTNER_GROUP_IS_RETAIL),
	TEST_ACCOUNT: parseEnvInt(process.env.REACT_APP_PARTNER_GROUP_TEST_ACCOUNT),
	// Tiers
	TIER_1: parseEnvInt(process.env.REACT_APP_PARTNER_GROUP_TIER_1),
	TIER_2: parseEnvInt(process.env.REACT_APP_PARTNER_GROUP_TIER_2),
	TIER_3: parseEnvInt(process.env.REACT_APP_PARTNER_GROUP_TIER_3),
	TIER_4: parseEnvInt(process.env.REACT_APP_PARTNER_GROUP_TIER_4),
	TIER_DISTRIBUTORS: parseEnvInt(process.env.REACT_APP_PARTNER_GROUP_TIER_DISTRIBUTORS),
};

export const API_ERROR_AFFILIATE: TApiErrorList = {
	[API_ERR_CODE.PARTNER_PORTAL_MIGRATION]: 'error:api.partnerPortalMigration',
};
