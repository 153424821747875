import type { TUiTableRow } from '@avast/react-ui-components';
import { selectColumn } from '@avast/react-ui-components';
import type { CellContext, ColumnDef, ColumnHelper as BaseColumnHelper } from '@tanstack/react-table';
import { createColumnHelper as baseCreateColumnHelper } from '@tanstack/react-table';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import type { AccessorFn, DeepKeys, DisplayColumnDef } from '@tanstack/table-core';
import { ColumnMeta } from '@tanstack/table-core';
import { TConfigLocaleDateTimeValues } from 'types/config';
import { TMoneyOptions } from 'js/components/table/formatters/money';
import { TNumberOptions } from 'js/components/table/formatters/number';
import { TPercentageOptions } from 'js/components/table/formatters/percentage';
import { TActionsOptions } from 'js/components/table/formatters/actions';
import { TLinkOptions } from 'js/components/table/formatters/link';
import { TEnableStatusOptions } from 'js/components/table/formatters/enableStatus';
import { ELLIPSIS_CELL_WIDTH } from 'appConstants';
import { isArray, mergeWith } from 'lodash';

type TCustomAccessor<Row extends TUiTableRow> = AccessorFn<Row> | DeepKeys<Row>;

type ColumnHelper<Row extends TUiTableRow> = BaseColumnHelper<Row> & {
	select: () => ColumnDef<Row>;
	text: (accessor: TCustomAccessor<Row>, column: DisplayColumnDef<Row>) => ColumnDef<Row>;
	hidden: (key: DeepKeys<Row>, accessor?: TCustomAccessor<Row>) => ColumnDef<Row>;
	actions: (props: TActionsOptions<Row>, column?: Partial<DisplayColumnDef<Row>>) => ColumnDef<Row>;
	copy2clipboard: (accessor: TCustomAccessor<Row>, column: DisplayColumnDef<Row>) => ColumnDef<Row>;
	date: (
		accessor: TCustomAccessor<Row>,
		column: DisplayColumnDef<Row>,
		options?: TConfigLocaleDateTimeValues,
	) => ColumnDef<Row>;
	dateTime: (accessor: TCustomAccessor<Row>, column: DisplayColumnDef<Row>) => ColumnDef<Row>;
	timeZoneDateTime: (accessor: TCustomAccessor<Row>, column: DisplayColumnDef<Row>) => ColumnDef<Row>;
	ellipsis: (
		accessor: TCustomAccessor<Row>,
		column: DisplayColumnDef<Row>,
		options?: Partial<ColumnMeta<Row>['truncateText']>,
	) => ColumnDef<Row>;
	money: (accessor: TCustomAccessor<Row>, column: DisplayColumnDef<Row>, options: TMoneyOptions<Row>) => ColumnDef<Row>;
	number: (accessor: TCustomAccessor<Row>, column: DisplayColumnDef<Row>, options?: TNumberOptions) => ColumnDef<Row>;
	integer: (accessor: TCustomAccessor<Row>, column: DisplayColumnDef<Row>, options?: TNumberOptions) => ColumnDef<Row>;
	percentage: (
		accessor: TCustomAccessor<Row>,
		column: DisplayColumnDef<Row>,
		options: TPercentageOptions,
	) => ColumnDef<Row>;
	link: (accessor: TCustomAccessor<Row>, column: DisplayColumnDef<Row>, options: TLinkOptions<Row>) => ColumnDef<Row>;
	enableStatus: (
		accessor: TCustomAccessor<Row>,
		column: DisplayColumnDef<Row>,
		options: TEnableStatusOptions,
	) => ColumnDef<Row>;
};

const _mergeCustomizer = (acc: unknown, value: unknown) => {
	if (isArray(acc) && isArray(value)) {
		return acc.concat(value);
	}
};

const _mergeCustomizerFlipped = (acc: unknown, value: unknown) => {
	if (isArray(acc) && isArray(value)) {
		return value.concat(acc);
	}
};

const _prefixColumnMeta = <Row extends TUiTableRow>(
	column: DisplayColumnDef<Row>,
	meta: DisplayColumnDef<Row>['meta'],
	flipFormatters: boolean = false,
): DisplayColumnDef<Row> => ({
	...column,
	meta: mergeWith(meta, column.meta, flipFormatters ? _mergeCustomizerFlipped : _mergeCustomizer),
});

export const createColumnHelper = <Row extends TUiTableRow>(): ColumnHelper<Row> => {
	const formatter = createColumnFormatterHelper<Row>();
	const baseColumnHelper = baseCreateColumnHelper<Row>();

	return {
		...baseColumnHelper,
		select: selectColumn,

		accessor(accessor, column) {
			return baseColumnHelper.accessor(accessor, {
				// Rewrite default cell function what converts column data to string
				// e.g. list of object is converted to "[object Object]" and is not possible to use formatters
				// This fix keeps original value and the type

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				cell: (cell: CellContext<Row, any>) => cell.getValue() ?? null,
				...column,
			});
		},

		text(accessor, column) {
			return this.accessor(accessor, column);
		},

		hidden(key, accessor) {
			// Id value is needed for label of column in table, if the accessor is nested ("customer.name")
			return this.accessor(accessor || key, { id: key as string, meta: { hidden: true } });
		},

		actions(props, column) {
			return this.display(
				_prefixColumnMeta(
					{ id: 'actions', ...column },
					{
						sticky: 'end',
						clickAble: false,
						className: 'cell-actions',
						formatters: [formatter.actions<Row>(props)],
					},
				),
			);
		},

		copy2clipboard(accessor, column) {
			return this.accessor(
				accessor,
				_prefixColumnMeta(column, {
					clickAble: false,
					formatters: [formatter.copy2clipboard],
				}),
			);
		},

		date(accessor, column, options) {
			return this.accessor(
				accessor,
				_prefixColumnMeta(column, {
					formatters: [formatter.date<Row>(options)],
				}),
			);
		},

		dateTime(accessor, column) {
			return this.date(accessor, column, 'DATETIME');
		},

		timeZoneDateTime(accessor, column) {
			return this.date(accessor, column, 'DATETIME_TZ');
		},

		ellipsis(accessor, column, options) {
			return this.accessor(
				accessor,
				_prefixColumnMeta(column, {
					truncateText: { width: ELLIPSIS_CELL_WIDTH, ...options },
				}),
			);
		},

		money(accessor, column, options) {
			return this.accessor(
				accessor,
				_prefixColumnMeta(column, {
					align: 'end',
					formatters: [formatter.money<Row>(options)],
				}),
			);
		},

		number(accessor, column, options) {
			return this.accessor(
				accessor,
				_prefixColumnMeta(column, {
					align: 'end',
					formatters: [formatter.number<Row>(options)],
				}),
			);
		},

		integer(accessor, column, options) {
			return this.accessor(
				accessor,
				_prefixColumnMeta(column, {
					align: 'end',
					formatters: [formatter.number<Row>({ mantissa: 0, ...options })],
				}),
			);
		},

		percentage(accessor, column, options) {
			return this.accessor(
				accessor,
				_prefixColumnMeta(column, {
					align: 'end',
					formatters: [formatter.percentage<Row>(options)],
				}),
			);
		},

		link(accessor, column, options) {
			return this.accessor(
				accessor,
				_prefixColumnMeta(
					column,
					{
						clickAble: false,
						formatters: [formatter.link<Row>(options)],
					},
					true,
				),
			);
		},

		enableStatus(accessor, column, options) {
			return this.accessor(
				accessor,
				_prefixColumnMeta(column, {
					formatters: [formatter.enableStatus<Row>(options)],
				}),
			);
		},
	};
};
