import { toInteger } from 'lodash';

interface IPutUnitValueToSkuOptions {
	isBusiness?: boolean;
	unit?: number;
}

export const skuUtils = {
	putUnitValueToBusinessSku(sku: string, unit: number): string {
		return sku.replace('*', String(toInteger(unit)));
	},

	putUnitValueToSku(sku: string, options: IPutUnitValueToSkuOptions): string {
		if (options.isBusiness && options.unit) {
			return this.putUnitValueToBusinessSku(sku, options.unit);
		}

		return sku;
	},
};
