import { AxiosError } from 'axios';

export const isNetworkError = (error: unknown): boolean => {
	if (error instanceof AxiosError) {
		return error.code === 'ERR_NETWORK';
	}
	return false;
};

export const delayedPromise = (delay: number): Promise<void> => {
	return new Promise<void>((resolve) => {
		setTimeout(() => resolve(), delay);
	});
};
