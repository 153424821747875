import { TAnnouncement, TAnnouncementPlacement } from 'submodule/Announcements/types';
import { useAnnouncements } from 'submodule/Announcements/hooks/useAnnouncements';
import { useApiAnnouncementList } from 'submodule/Announcements/api';
import { useAuthContext } from 'js/contexts';

type TValidAnnouncements = {
	isLoading?: boolean;
	announcements: TAnnouncement[];
};

export const useValidAnnouncements = (placement: TAnnouncementPlacement): TValidAnnouncements => {
	const list = useAnnouncements(placement);
	const {
		authCompanyGroups: { isTestAccount },
		isGroupPartner,
		authPartnerId,
	} = useAuthContext();
	const {
		data: announcements,
		query: { isLoading: isAnnouncementsLoading },
	} = useApiAnnouncementList({ filter: { onlyValid: true, partnerId: authPartnerId! } });

	return {
		isLoading: isAnnouncementsLoading,
		announcements: list
			.filter((item) => {
				return announcements.some((announcement) => {
					if (announcement.uuid !== item.id) {
						return false;
					}

					if (isGroupPartner && announcement.isTestPartner && !isTestAccount) {
						return false;
					}

					return !announcement.isHidden;
				});
			})
			.map((item) => {
				const announcement = announcements.find((announcement) => announcement.uuid === item.id);
				return {
					...item,
					isHidable: announcement?.isHidable,
				};
			}),
	};
};
