import { SelectPartyEnum, SelectPartyOptionEnum } from '../enums';
import React, { PropsWithChildren } from 'react';
import { Card, CardHeader } from '@avast/react-ui-components';
import { useSelectPartyTranslation } from '../hooks';

type TSelectPartyCardProps = {
	type: SelectPartyEnum;
	option: SelectPartyOptionEnum;
};

export const SelectPartyCard = (props: PropsWithChildren<TSelectPartyCardProps>) => {
	const { tPartyOption } = useSelectPartyTranslation(props.type);

	return (
		<Card className="h-100">
			<CardHeader>{tPartyOption(props.option, 'title')}</CardHeader>
			{props.children}
		</Card>
	);
};
