import { ApiListTable } from 'js/components/molecules/DataTable';
import { IApiFlexibleBillingFilter, IFlexibleBillingReport } from 'module/flexibleBilling/index';
import { useFlexibleBillingReportsColumns } from 'module/flexibleBilling/hooks';
import { SortDirectionEnum } from 'js/enums';
import { AsyncModal, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { DocumentsModalContent, TDocumentsModalContentProps } from 'js/components/documents/DocumentsModalContent';
import React, { useCallback } from 'react';
import { useAuthContext } from 'js/contexts';
import { dateFormatter } from 'js/utils/dateTime';
import { TUseApiListModule } from 'js/queries/useApiListQuery';

type TReportListTableProps<Filter extends IApiFlexibleBillingFilter> = {
	filter: Filter;
	query: TUseApiListModule<IFlexibleBillingReport, Filter>;
};

export const ReportListTable = <Filter extends IApiFlexibleBillingFilter = IApiFlexibleBillingFilter>(
	props: TReportListTableProps<Filter>,
) => {
	const { authCompany } = useAuthContext();
	const documentsRef = useAsyncModalRef<TDocumentsModalContentProps>();

	// Prepare columns
	const onDocuments = useCallback(
		(row: IFlexibleBillingReport) => {
			documentsRef.current?.show({
				title: dateFormatter.default(row.startDate, 'MONTH_YEAR'),
				documents: row.documents,
			});
		},
		[documentsRef],
	);
	const columns = useFlexibleBillingReportsColumns(onDocuments, authCompany?.currencyCode || '');

	return (
		<>
			<ApiListTable<IFlexibleBillingReport, Filter>
				query={props.query}
				filterStatic={props.filter}
				columns={columns}
				sort={{ direction: SortDirectionEnum.DESC, key: 'startDate' }}
				useLocation
				table={{
					testId: 'reports',
					sortDescFirst: false,
					meta: {
						onRowClick: onDocuments,
						paginationComponent: 'Full',
					},
				}}
			/>

			<AsyncModal<TDocumentsModalContentProps>
				size="md"
				ref={documentsRef}
				testId="documents"
			>
				{(props) => <DocumentsModalContent {...props} />}
			</AsyncModal>
		</>
	);
};
