import { IEntityLanguage } from 'submodule/localeSwitch';
import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { Toast } from '@avast/react-ui-components';
import { useTranslation } from 'react-i18next';
import { useApiAffiliateUpdate } from 'module/partners/hooks/useApiPartners';
import { logError } from 'js/utils/app';
import { localeCodeNormalizer } from 'i18n/normalizer';
import { useRefreshToken } from 'api/useRefreshToken';
import { useApiErrorContext, useAppContext, useAuthContext } from 'js/contexts';
import { updateAffiliateApiError } from 'module/partners/utils/apiError';

export const useChangeLanguage = () => {
	const [t] = useTranslation('submodules');
	const { authPartner, setAuthPartner, isLoggedAsUser, isGroupPartner } = useAuthContext();
	const { loadingModalRef, confirmationModalRef } = useAppContext();
	const { setError } = useApiErrorContext();
	const refreshToken = useRefreshToken();
	const isLoggedAsCompany = !isLoggedAsUser && isGroupPartner;

	const { mutateAsync: updateAffiliate } = useApiAffiliateUpdate({
		config: { params: { affiliateId: authPartner?.id } },
	});

	return useCallback(
		async (locale: IEntityLanguage): Promise<boolean> => {
			if (isLoggedAsCompany) {
				if (
					!(await confirmationModalRef.current?.show({
						title: t('locale.language.confirmation.title'),
						messages: [t('locale.language.confirmation.message')],
						submitButtonText: t('common:actions.confirm'),
					}))
				) {
					return false;
				}
			}

			loadingModalRef.current?.show({
				title: t('locale.language.saving'),
				ellipsis: true,
			});

			// Save to OMS
			const response = await updateAffiliate({
				locale: localeCodeNormalizer.denormalize(locale.locale)!,
			}).catch((error) => {
				logError('Unable to change locale language', error);
				setError({ error, resolve: updateAffiliateApiError });
			});

			// Break on an error
			if (!response) {
				loadingModalRef.current?.hide();
				return false;
			}

			// Update Auth partner
			setAuthPartner(response.data);

			// Refresh auth token
			await refreshToken();

			loadingModalRef.current?.hide();
			toast.success(<Toast>{t('locale.language.success', { lng: locale.language })}</Toast>);
			return true;
		},
		[
			t,
			updateAffiliate,
			loadingModalRef,
			setError,
			setAuthPartner,
			refreshToken,
			isLoggedAsCompany,
			confirmationModalRef,
		],
	);
};
