import { useEllipsisEmail } from 'js/components/atoms/EmailDisplay/useEllipsisEmail';
import { Tooltip } from '@avast/react-ui-components';
import React from 'react';

type TEmailDisplayProps = {
	email?: string | null;
};

export function EmailDisplay(props: TEmailDisplayProps) {
	const { displayedEmail, containerRef, isEllipsis } = useEllipsisEmail<HTMLDivElement>(props.email || '');

	const contentComponent = (
		<div
			ref={containerRef}
			className="text-nowrap"
		>
			{displayedEmail}
		</div>
	);

	if (!props.email) {
		return null;
	}

	if (isEllipsis) {
		return (
			<Tooltip
				content={props.email}
				placement="top"
			>
				{contentComponent}
			</Tooltip>
		);
	}

	return contentComponent;
}
