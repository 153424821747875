import { TableColumnsDef, TCurrency } from 'types';
import { useMemo } from 'react';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { useTranslation } from 'react-i18next';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { IFlexibleBillingDistributionPartner } from 'module/flexibleBilling';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { getDistributionPartnerRenderName } from 'module/distributionPartners/utils/distributionPartnerSelector';
import { noValuePlaceholder } from 'js/utils/app';
import { FLEXIBLE_BILLING_UNLIMITED_VALUE } from 'module/flexibleBilling/constants';

type TTableData = IFlexibleBillingDistributionPartner;

export const useFlexibleBillingDistributorsColumns = (currency: TCurrency): TableColumnsDef<TTableData> => {
	const [t] = useTranslation(flexibleBillingConfig.trNamespace);

	return useMemo<TableColumnsDef<TTableData>>(() => {
		const columnHelper = createColumnHelper<IFlexibleBillingDistributionPartner>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.hidden('distributionPartner.id'),
			columnHelper.hidden('distributionPartner.taxId'),
			columnHelper.hidden('distributionPartner.contact.email'),
			columnHelper.ellipsis((row) => getDistributionPartnerRenderName(row.distributionPartner), {
				header: t('common:entity.distributionPartner'),
				id: 'distributionPartner',
				meta: {
					formatters: [formatter.bold],
				},
			}),
			columnHelper.enableStatus('enabled', { header: t('common:entity.status') }, { t, colored: true }),
			columnHelper.money(
				'currentSpend',
				{
					header: t('common.currentSpend'),
				},
				{ currency },
			),
			columnHelper.money(
				'maxSpend',
				{
					header: t('common.maxSpend'),
					meta: {
						formatters: [
							(formattedValue, cell) => {
								const value = cell.getValue();
								return value === FLEXIBLE_BILLING_UNLIMITED_VALUE ? t('common.unlimited') : formattedValue;
							},
							(value, cell) => {
								const row = cell.row.original;
								return row.enabled ? value : noValuePlaceholder();
							},
						],
					},
					sortingFn(a, b) {
						// Enabled last (ASC)
						if (!a.original.enabled) return -1;
						if (!b.original.enabled) return 1;
						// Unlimited last (ASC)
						if (a.original.maxSpend === -1) return 1;
						if (b.original.maxSpend === -1) return -1;
						// Max spend last (ASC)
						return a.original.maxSpend > b.original.maxSpend ? 1 : -1;
					},
				},
				{ currency },
			),
			columnHelper.actions({
				link: {
					children: t('common:actions.detail'),
					to: flexibleBillingConfig.detailLink,
					testId: 'detail',
				},
			}),
		];
	}, [currency, t]);
};
