import React from 'react';
import {
	ClosingBalanceWidget,
	CreditStatusWidget,
	LicenseWidgetLastNDays,
	LicenseWidgetNextNDays,
	OverduePaymentWidget,
	QuotesWidget,
	RecentOrdersWidget,
} from './widget';
import { TWidget } from 'submodule/widgets';
import { useWidgetColumnVisibility } from 'submodule/widgets/hooks';
import { WidgetTypeEnum } from '../enums';
import { logError } from 'js/utils/app';

type TWidgetRendererProps = {
	item: TWidget;
};

export const WidgetRenderer = (props: TWidgetRendererProps) => {
	const { type, label, settings } = props.item;
	const columnVisibility = useWidgetColumnVisibility(props.item);

	switch (type) {
		case WidgetTypeEnum.QUOTES:
			return (
				<QuotesWidget
					header={label}
					limit={settings.limit}
					columnVisibility={columnVisibility}
					partnerId={settings.partnerId}
				/>
			);
		case WidgetTypeEnum.ORDERS:
			return (
				<RecentOrdersWidget
					header={label}
					limit={settings.limit}
					columnVisibility={columnVisibility}
					partnerId={settings.partnerId}
				/>
			);
		case WidgetTypeEnum.CREDIT_STATUS:
			return <CreditStatusWidget name={label} />;
		case WidgetTypeEnum.EXPIRING_LICENSES_NEXT_N_DAYS:
			return (
				<LicenseWidgetNextNDays
					header={label}
					limit={settings.limit}
					days={settings.days || 30}
					columnVisibility={columnVisibility}
					partnerId={settings.partnerId}
				/>
			);
		case WidgetTypeEnum.EXPIRED_LICENSES_LAST_N_DAYS:
			return (
				<LicenseWidgetLastNDays
					header={label}
					limit={settings.limit}
					days={settings.days || 30}
					columnVisibility={columnVisibility}
					partnerId={settings.partnerId}
				/>
			);
		case WidgetTypeEnum.CLOSING_BALANCE:
			return (
				<ClosingBalanceWidget
					header={label}
					limit={settings.limit}
					columnVisibility={columnVisibility}
					filter={{ partnerId: settings.partnerId }}
				/>
			);
		case WidgetTypeEnum.OVERDUE_PAYMENT:
			return (
				<OverduePaymentWidget
					header={label}
					limit={settings.limit}
					columnVisibility={columnVisibility}
					partnerId={settings.partnerId}
				/>
			);
		default:
			logError(`Not acceptable widget type: ${type}`, props);
			return null;
	}
};
