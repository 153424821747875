import {
	IApiDistributionPartnerCreateParams,
	IApiDistributionPartnerUpdateParams,
	IAsyncExportDistributionPartnersRequest,
	IDistributionPartnerListFilter,
	IDistributionPartnerSearchFilter,
	IEntityDistributionPartner,
	IFormDistributionPartner,
} from 'module/distributionPartners';
import { TUseApiDetailModule, useApiDetailQuery } from 'js/queries/useApiDetailQuery';
import { TUseApiMutationModule, useApiMutationQuery } from 'js/queries/useApiMutationQuery';
import { TUseApiListModule, useApiListQuery } from 'js/queries/useApiListQuery';
import { ApiMutationTypeEnum } from 'js/enums';
import { IAsyncJob } from 'js/asyncJobs';
import { API_FLEXIBLE_BILLING_KEYS } from 'module/flexibleBilling/hooks/useApiFlexibleBilling';

export const API_DISTRIBUTION_PARTNERS_KEYS = {
	LIST: 'distributor/:distributorId/distribution-partner',
	DETAIL: 'distributor/:distributorId/distribution-partner/:id',
	CREATE: 'distributor/:distributorId/distribution-partner',
	UPDATE: 'distributor/:distributorId/distribution-partner/:salesforceId',
	EXPORT: 'export/distribution-partners',
};

export const useApiDistributionPartnersList: TUseApiListModule<
	IEntityDistributionPartner,
	IDistributionPartnerListFilter
> = (props) => useApiListQuery(API_DISTRIBUTION_PARTNERS_KEYS.LIST, props);

export const useApiDistributionPartner: TUseApiDetailModule<
	IEntityDistributionPartner,
	IDistributionPartnerSearchFilter
> = (props) => useApiDetailQuery(API_DISTRIBUTION_PARTNERS_KEYS.DETAIL, props);

export const useApiCreateDistributionPartner: TUseApiMutationModule<
	IFormDistributionPartner,
	IEntityDistributionPartner,
	IApiDistributionPartnerCreateParams
> = (props) =>
	useApiMutationQuery(API_DISTRIBUTION_PARTNERS_KEYS.CREATE, ApiMutationTypeEnum.POST, props, {
		invalidateQueries: [
			...Object.values(API_DISTRIBUTION_PARTNERS_KEYS),
			API_FLEXIBLE_BILLING_KEYS.DISTRIBUTION_PARTNER_LIST,
		],
	});

export const useApiUpdateDistributionPartner: TUseApiMutationModule<
	IFormDistributionPartner,
	IEntityDistributionPartner,
	IApiDistributionPartnerUpdateParams
> = (props) =>
	useApiMutationQuery(API_DISTRIBUTION_PARTNERS_KEYS.UPDATE, ApiMutationTypeEnum.PATCH, props, {
		invalidateQueries: [
			...Object.values(API_DISTRIBUTION_PARTNERS_KEYS),
			API_FLEXIBLE_BILLING_KEYS.DISTRIBUTION_PARTNER_LIST,
			API_FLEXIBLE_BILLING_KEYS.DISTRIBUTION_PARTNER,
		],
	});

export const useApiDistributionPartnersExport: TUseApiMutationModule<
	IAsyncExportDistributionPartnersRequest,
	IAsyncJob
> = (props) => useApiMutationQuery(API_DISTRIBUTION_PARTNERS_KEYS.EXPORT, ApiMutationTypeEnum.POST, props);
