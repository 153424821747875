import React, { PropsWithChildren } from 'react';
import { CardBody } from '@avast/react-ui-components';
import { LoadingPlaceholder } from 'js/layouts/placeholder/LoadingPlaceholder';

type TSelectPartyCardBodyProps = {
	loading?: boolean;
};

export const SelectPartyCardBody = (props: PropsWithChildren<TSelectPartyCardBodyProps>) => {
	return <CardBody>{props.loading ? <LoadingPlaceholder /> : props.children}</CardBody>;
};
