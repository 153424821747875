import { useApi } from 'js/hooks/api';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { TUseApiProps } from 'js/hooks/api/useApi';
import type { QueryFunctionContext } from '@tanstack/react-query';
import { IApiSortBy, TApiListResponse } from 'types/api';
import { fillUrlParameters } from 'js/utils/common';

export type TUseApiListFilterDto<Filter, ApiFilter = Filter> = (filter: Filter) => ApiFilter;

export type TUseApiListProps<Filter, ApiFilter = Filter> = TUseApiProps & {
	apiFilterNormalizer?: TUseApiListFilterDto<Filter, ApiFilter>;
};

export type TUseApiListQueryKey<Data extends {}, Filter> =
	| [string, Filter, IApiSortBy<Data> | undefined]
	| [string, Filter];
type TUseApiListContextQuery<Data extends {}, Filter> = QueryFunctionContext<TUseApiListQueryKey<Data, Filter>>;

type TUseApiList<Data extends {}, Filter extends {}> = (
	context: TUseApiListContextQuery<Data, Filter>,
) => Promise<AxiosResponse<TApiListResponse<Data>>>;

export const useApiList = <Data extends {}, Filter extends {}, ApiFilter extends {} = Filter>(
	props: TUseApiListProps<Filter, ApiFilter>,
): TUseApiList<Data, Filter> => {
	const { config, apiFilterNormalizer } = props;
	const api = useApi({ config: { ...config, disableRetry: true } });

	return (context) => {
		const [key, filter, sortBy] = context.queryKey;
		let apiFilter = { ...config?.params, ...filter };
		if (apiFilterNormalizer) {
			apiFilter = apiFilterNormalizer(apiFilter);
		}
		const [route, _filter] = fillUrlParameters<ApiFilter>(key, apiFilter);
		const source = axios.CancelToken.source();

		const promise = api.get<TApiListResponse<Data>>(`/${route}`, {
			cancelToken: source.token,
			...config,
			params: {
				..._filter,
				sort: sortBy?.key,
				direction: sortBy?.direction,
			},
		});

		// Cancel the request if React Query calls the `promise.cancel` method
		// @ts-ignore
		promise.cancel = () => {
			source.cancel('Query was canceled by React Query');
		};

		return promise;
	};
};
