import React, { PropsWithChildren, ReactElement } from 'react';
import { useApiClosingBalanceList } from 'module/closingBalance/hooks/useApiClosingBalance';
import { useTranslation } from 'react-i18next';
import { closingBalanceConfig } from 'module/closingBalance/closingBalanceConfig';
import { RouteName } from 'module/RouteName';
import { buildParametrizedRoute } from 'js/utils/common';
import { dateTimeToFilterDate } from 'js/utils/dateTime';
import { IClosingBalanceListFilter, IEntityClosingBalance } from 'module/closingBalance';
import { DateTime } from 'luxon';
import { useClosingBalanceWidgetColumns } from 'submodule/widgets/tableColumns';
import { VisibilityState } from '@tanstack/table-core/src/features/ColumnVisibility';
import { WidgetTypeEnum, widgetTypeEnumUtils } from 'submodule/widgets/enums';
import { useNavigate } from 'react-router-dom';
import { CardTable } from 'js/components/molecules/DataTable/CardTable';

type TClosingBalanceWidgetProps = React.HTMLAttributes<HTMLDivElement> & {
	limit?: number;
	header?: string;
	columnVisibility?: VisibilityState;
	filter?: IClosingBalanceListFilter;
};

// Table data type
type TClosingBalanceWidgetData = IEntityClosingBalance;

/**
 * Widget with recent closing balance
 * @param {PropsWithChildren<TClosingBalanceWidgetProps>} props
 * @returns {ReactElement}
 * @constructor
 */
export const ClosingBalanceWidget = (props: PropsWithChildren<TClosingBalanceWidgetProps>): ReactElement => {
	const { columns, columnVisibility: defaultColumnVisibility } = useClosingBalanceWidgetColumns();
	const { limit = 5, filter, header, columnVisibility = defaultColumnVisibility, ...rest } = props;
	const [t] = useTranslation(closingBalanceConfig.trNamespace);
	const navigate = useNavigate();
	const viewMoreRange = {
		filter: {
			dateRange: [
				dateTimeToFilterDate(DateTime.now().minus({ years: 1 }).startOf('month')),
				dateTimeToFilterDate(DateTime.now().endOf('month')),
			],
			...filter,
		},
	};

	const {
		data,
		query: { isFetching, isError },
	} = useApiClosingBalanceList({ config: { limit } });

	return (
		<CardTable<TClosingBalanceWidgetData>
			testId="closingBalance widget"
			{...rest}
			columns={columns}
			caption={header || t(widgetTypeEnumUtils.getCaption(WidgetTypeEnum.CLOSING_BALANCE))}
			data={data?.items ?? []}
			onViewMore={() => navigate(buildParametrizedRoute(RouteName.CLOSING_BALANCE.LIST, viewMoreRange))}
			tableProps={{
				state: { columnVisibility },
				meta: {
					rowCount: limit,
					loading: isFetching,
					onRowClick(row) {
						navigate(buildParametrizedRoute(RouteName.CLOSING_BALANCE.DETAIL, row.id));
					},
					customError: isError || !data ? t('error.closingBalanceNotFound') : false,
				},
			}}
		/>
	);
};
