import React, { FunctionComponent } from 'react';
import { ModuleContainer } from 'js/layouts/container/ModuleContainer';
import { creditStatusConfig } from 'module/creditStatus/creditStatusConfig';
import { CreditStatusRoutes } from 'module/creditStatus/CreditStatusRoutes';
import { useAuthContext } from 'js/contexts';
import { DISABLED_BREADCRUMB_LINK } from 'js/components/molecules/DynamicBreadcrumbs';

export const CreditStatusContainer: FunctionComponent = () => {
	const { isGroupPartner } = useAuthContext();
	let config = creditStatusConfig;

	// Partner doesn't have Credit Status list permissions
	if (isGroupPartner) {
		config = { ...creditStatusConfig, menuLink: DISABLED_BREADCRUMB_LINK };
	}

	return (
		<>
			<ModuleContainer moduleMenuConfig={config} />
			<CreditStatusRoutes />
		</>
	);
};
