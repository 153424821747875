import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { IDistributionPartnerListFilter } from 'module/distributionPartners';
import { DataFilter, TDataFilterProps } from 'js/components/molecules/DataFilter/DataFilter';
import { SearchBox } from 'js/components/molecules/SearchBox';
import { Col, Row } from 'js/components/atoms/Row';
import { SelectPartner } from 'module/partners/components';
import { PartnerTypeEnum } from 'module/partners/enums';
import { useAuthContext } from 'js/contexts';
import { useTranslation } from 'react-i18next';
import { distributionPartnersConfig } from 'module/distributionPartners/distributionPartnersConfig';

type TDistributionPartnerFilterProps = TDataFilterProps<IDistributionPartnerListFilter> & {
	controls?: ReactNode;
};

/**
 * Filter for Distribution Partners
 * @param {React.PropsWithChildren<TDistributionPartnerFilterProps>} props
 * @returns {React.ReactElement}
 * @constructor
 */
export const DistributionPartnerFilter = (props: PropsWithChildren<TDistributionPartnerFilterProps>): ReactElement => {
	const [t] = useTranslation(distributionPartnersConfig.trNamespace);
	const { controls, ...rest } = props;
	const { isGroupInternal } = useAuthContext();

	return (
		<DataFilter<IDistributionPartnerListFilter>
			useLocation
			{...rest}
		>
			{({ values, updateFilter }) => (
				<Row
					multi
					size="sm"
					align="center"
					justify="between"
				>
					{isGroupInternal && (
						<Col
							xl={3}
							md={4}
							sm={6}
						>
							<SelectPartner
								disabledLabel
								size="sm"
								value={values.distributorId}
								accountTypesWhiteList={[PartnerTypeEnum.DISTRIBUTOR]}
								onChange={(distributorId) => updateFilter({ distributorId })}
							/>
						</Col>
					)}
					<Col
						md={isGroupInternal ? 4 : 6}
						sm={isGroupInternal ? 6 : 12}
					>
						<SearchBox
							size="sm"
							placeholder={t('select.filter.placeholder')}
							value={values.search}
							disabled={isGroupInternal && !values.distributorId}
							onChange={(value) => updateFilter({ search: value })}
						/>
					</Col>
					<Col
						xl={isGroupInternal ? 5 : 6}
						md={isGroupInternal ? 4 : 6}
						sm={12}
						className="text-end"
					>
						{controls}
					</Col>
				</Row>
			)}
		</DataFilter>
	);
};
