import { Modal, Toast } from '@avast/react-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { customersConfig } from 'module/customers/customersConfig';
import { CustomerForm } from 'module/customers/forms/CustomerForm';
import { toast } from 'react-toastify';
import { updateCustomerApiError } from 'module/customers/utils/apiError';
import { useApiUpdateCustomer } from 'module/customers/hooks/useApiCustomers';
import { TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { IEntityCustomer } from 'module/customers';
import { getCustomerRenderName } from 'module/customers/utils/customerSelectors';
import { useApiErrorContext, useAuthContext } from 'js/contexts';

type TAsyncUpdateCustomerModalProps = {
	onUpdate: (customer: IEntityCustomer) => void;
	customer?: IEntityCustomer | null;
};

export const AsyncUpdateCustomerModal = (props: TAsyncUpdateCustomerModalProps & TAsyncModalContainerProps) => {
	const { onUpdate, customer, forwardedRef } = props;
	const [t] = useTranslation(customersConfig.trNamespace);
	const { authCompanyId } = useAuthContext();
	const { setError } = useApiErrorContext();
	const { mutate: updateCustomer } = useApiUpdateCustomer({
		config: {
			params: {
				partnerId: authCompanyId!,
				salesforceCustomerId: customer?.id!,
			},
		},
	});

	if (!customer) {
		forwardedRef.current?.onCancel();
		return null;
	}

	return (
		<>
			<Modal.Header>{getCustomerRenderName(customer)}</Modal.Header>
			<Modal.Body>
				<CustomerForm
					initialValues={customer}
					isUpdate
					onSubmit={(values, { setSubmitting }) => {
						updateCustomer(values, {
							onSuccess(response) {
								setSubmitting(false);
								if (Boolean(response?.data)) {
									toast.success(<Toast>{t('page.update.success')}</Toast>);
									onUpdate(response.data);
								}
							},
							onError(error) {
								setError({ error, resolve: updateCustomerApiError });
								setSubmitting(false);
							},
						});
					}}
					onCancel={() => {
						forwardedRef.current?.onCancel();
					}}
				/>
			</Modal.Body>
		</>
	);
};
