import React, { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@avast/react-ui-components';
import { InitiateRefundContextProvider } from 'submodule/refunds/initiate/InitiateRefundContext';
import { AsyncModal, TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import { STATUS_SUCCESS } from 'appConstants';
import { TChargeOrderId, TStandardOrderId } from 'types';
import { InitiateRefundForm } from 'submodule/refunds/initiate/InitiateRefundForm';
import { InitiateRefundFormEnum } from '../enums';

type TAsyncInitiateRefundModalProps = TAsyncModalContainerProps & {
	id: TStandardOrderId;
	chargeOrderId: TChargeOrderId;
	successRefundModalRef?: RefObject<AsyncModal>;
};

export const AsyncInitiateRefundModal = (props: TAsyncInitiateRefundModalProps) => {
	const { forwardedRef: currentModalRef, successRefundModalRef, id } = props;
	const [t] = useTranslation('submodules');

	function closeCurrentModal() {
		currentModalRef.current?.onSuccess(STATUS_SUCCESS);
	}

	async function showSuccessModal() {
		return successRefundModalRef?.current?.show();
	}

	async function handleSuccess() {
		// Show a success modal with details, then close current modal
		showSuccessModal().finally(closeCurrentModal);
	}

	function handleChangeView(step: InitiateRefundFormEnum) {
		currentModalRef.current?.updateModalProps({ size: step === 'PRODUCTS' ? 'xl' : 'md' });
	}

	return (
		<AsyncModal
			ref={currentModalRef}
			size="xl"
			testId="initiateRefund"
			backdrop="static"
		>
			<Modal.Header>{t('refunds.initiate.title', { id })}</Modal.Header>
			<Modal.Body>
				<InitiateRefundContextProvider
					chargeOrderId={props.chargeOrderId}
					onSuccess={handleSuccess}
					onCancel={() => currentModalRef.current?.onCancel()}
					onChangeView={handleChangeView}
				>
					<InitiateRefundForm />
				</InitiateRefundContextProvider>
			</Modal.Body>
		</AsyncModal>
	);
};
