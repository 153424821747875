import { IFlexibleBillingInviteRequest } from 'module/flexibleBilling';
import React, { useCallback } from 'react';
import { useApiErrorContext, useAppContext, useAuthContext } from 'js/contexts';
import { useTranslation } from 'react-i18next';
import { flexibleBillingConfig } from 'module/flexibleBilling/flexibleBillingConfig';
import { logError } from 'js/utils/app';
import { toast } from 'react-toastify';
import { Toast } from '@avast/react-ui-components';
import { API_FLEXIBLE_BILLING_KEYS, useAsyncApiFlexibleBillingInvite } from 'module/flexibleBilling/hooks';
import { IEntityDistributionPartner } from 'module/distributionPartners';
import { useInvalidateQueries } from 'js/hooks/useInvalidateQueries';
import { API_NOTIFICATION_KEYS } from 'js/notifications/useApiNotifications';

export const useInviteToFlexibleBilling = () => {
	const [t] = useTranslation(flexibleBillingConfig.trNamespace);
	const { authCompanyId } = useAuthContext();
	const { setError } = useApiErrorContext();
	const { loadingModalRef } = useAppContext();
	const invite = useAsyncApiFlexibleBillingInvite();
	const invalidQueries = useInvalidateQueries([
		API_NOTIFICATION_KEYS.LIST,
		API_FLEXIBLE_BILLING_KEYS.DISTRIBUTION_PARTNER_LIST,
	]);

	return useCallback(
		async (distributionPartner: IEntityDistributionPartner, data: IFlexibleBillingInviteRequest): Promise<boolean> => {
			loadingModalRef.current?.show({
				title: t('invite.processing'),
				ellipsis: true,
			});

			const response = await invite({ distributorId: authCompanyId, id: distributionPartner.id! }, data).catch(
				(error) => {
					logError('Invite dist. partner to flexible billing failed', error);
					setError({ error });
				},
			);

			if (response) {
				toast.success(<Toast>{t('invite.success')}</Toast>);
				invalidQueries();
			}

			loadingModalRef.current?.hide();
			return Boolean(response);
		},
		[t, loadingModalRef, invite, setError, authCompanyId, invalidQueries],
	);
};
