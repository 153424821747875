import { TWidget, TWidgetColumn, TWidgetConfig, TWidgetCoords } from 'submodule/widgets';
import { VisibilityState } from '@tanstack/table-core/src/features/ColumnVisibility';
import { assign, get, isNumber, isString } from 'lodash';
import type { ISelectOption, TSelectOptions, TUiTableRow } from '@avast/react-ui-components';
import { TableColumnDef, TableColumnsDef } from 'types';
import { WidgetTypeEnum } from 'submodule/widgets/enums';
import type { FormikContextType } from 'formik';

export const getAvailableColumnsKeys = (widget: TWidgetConfig): TWidgetColumn[] =>
	(widget.getAvailableColumns?.() || []).map((column) => column.value);

const getTableColumnKey = <Row extends TUiTableRow>(column: TableColumnDef<Row>): TWidgetColumn | null =>
	// @ts-ignore
	column.id || column.accessorKey || null;
export const buildColumnVisibility = (
	widget: TWidgetConfig | undefined,
	columnsKeys: TWidgetColumn[] = [],
): VisibilityState | undefined => {
	if (!widget) {
		return undefined;
	}

	return getAvailableColumnsKeys(widget).reduce((acc, key) => {
		assign(acc, { [key]: columnsKeys.includes(key) });
		return acc;
	}, {});
};

const filterValidTableColumns = <Row extends TUiTableRow = TUiTableRow>(
	columns: TableColumnsDef<Row>,
): TableColumnsDef<Row> => columns.filter((column) => Boolean(getTableColumnKey(column)) && isString(column.header));

export const tableColumnsToWidgetConfigColumns = <Row extends TUiTableRow = TUiTableRow>(
	columns: TableColumnsDef<Row>,
): TSelectOptions =>
	filterValidTableColumns(columns).map(
		(column) =>
			({
				value: getTableColumnKey(column)!,
				label: column.header!,
			}) as ISelectOption,
	);

export const tableColumnVisibilityToKeys = <Row extends TUiTableRow = TUiTableRow>(
	columns: TableColumnsDef<Row>,
	columnVisibility?: VisibilityState,
): TWidgetColumn[] =>
	filterValidTableColumns(columns).reduce<string[]>((acc, column) => {
		const key: TWidgetColumn = getTableColumnKey(column)!;
		const visibility: boolean = get(columnVisibility, key, true);

		if (visibility) {
			acc.push(key);
		}

		return acc;
	}, []);

export const resetSelectedColumns = (
	widgets: TWidgetConfig[],
	formContext: FormikContextType<TWidget>,
	nextType: WidgetTypeEnum,
): void => {
	const { setFieldValue } = formContext;

	// Get the widget and set his visible columns
	const widget = widgets.find((widget) => widget.type === nextType);
	setFieldValue('columns', widget?.getDefaultState().columns || []);
};

export const validateWidgetSettingsFields = (settings: TWidget['settings'], config: TWidgetConfig) => {
	const configSettings = config.getSettingsFields();
	const errorMessages: string[] = [];

	for (const [key, value] of Object.entries(settings)) {
		const field = configSettings[key];

		if (field === undefined) {
			errorMessages.push(`Field ${key} is not allowed`);
		} else if (field.type === 'number') {
			if (!isNumber(value)) {
				errorMessages.push(`Field ${key} has to be a number`);
			}
		}
	}

	return errorMessages;
};

export const getColKey = ({ rowIndex, colIndex }: TWidgetCoords) => `row.${rowIndex}-col.${colIndex}`;

export const exportedForTesting = {
	getTableColumnKey,
	filterValidTableColumns,
};
