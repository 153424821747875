import { TAnnouncement } from 'submodule/Announcements/types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Tip } from 'assets/image/icon';

export const useNewLanguagesAnnouncement = (): TAnnouncement => {
	const [t] = useTranslation('submodules');

	return {
		id: '17270e3f-f1cf-4615-8a04-931210744bb3',
		show: true,
		icon: <Tip />,
		variant: 'success',
		caption() {
			return <Trans t={t}>{'announcements.newLanguages.title'}</Trans>;
		},
		render() {
			return (
				<>
					<p>
						<Trans t={t}>{'announcements.newLanguages.contentTop'}</Trans>
					</p>
					<p>
						<Trans t={t}>{'announcements.newLanguages.contentBottom'}</Trans>
					</p>
				</>
			);
		},
		placement: ['HOMEPAGE_INTERNAL', 'HOMEPAGE_PARTNER'],
	};
};
