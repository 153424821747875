import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { IDigitalRiverElement, IDigitalRiverInstance } from 'types/digitalRiver';
import { drElementOptions } from 'module/account/utils/form';
import { useCurrentLanguage } from 'submodule/localeSwitch/hooks';

export interface IAutoBillingContext {
	digitalRiver: IDigitalRiverInstance;
	cardNumber: IDigitalRiverElement;
	cardExpiration: IDigitalRiverElement;
	cardCVV: IDigitalRiverElement;
}

const AutoBillingContext = createContext<IAutoBillingContext>({} as IAutoBillingContext);
AutoBillingContext.displayName = 'AutoBillingContext';

export const useAutoBillingContext = () => useContext(AutoBillingContext);

export const AutoBillingContextProvider = ({ children }: PropsWithChildren<{}>) => {
	const language = useCurrentLanguage();

	const digitalRiver = useMemo(() => {
		return new window.DigitalRiver(process.env.REACT_APP_DIGITAL_RIVER_PKEY, { locale: language.locale });
	}, [language.locale]);

	const cardNumber = useMemo(() => digitalRiver.createElement('cardnumber', drElementOptions), [digitalRiver]);
	const cardExpiration = useMemo(() => digitalRiver.createElement('cardexpiration', drElementOptions), [digitalRiver]);
	const cardCVV = useMemo(() => digitalRiver.createElement('cardcvv', drElementOptions), [digitalRiver]);

	return (
		<AutoBillingContext.Provider value={{ digitalRiver, cardNumber, cardExpiration, cardCVV }}>
			{children}
		</AutoBillingContext.Provider>
	);
};
