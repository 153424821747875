import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { DataFilter, TDataFilterProps } from 'js/components/molecules/DataFilter/DataFilter';
import { Col, Row } from 'js/components/atoms/Row';
import { useTranslation } from 'react-i18next';
import { ICustomerGridFilter } from 'module/customers';
import { SearchBox } from 'js/components/molecules/SearchBox';
import { customersConfig } from 'module/customers/customersConfig';
import { SelectPartner } from 'module/partners/components';
import { useAuthContext } from 'js/contexts';

type TFilter = ICustomerGridFilter;
type TFilterProps = TDataFilterProps<TFilter> & {
	controls?: ReactNode;
};

/**
 * Filter for credit status list
 * @param {React.PropsWithChildren<TFilterProps>} props
 * @returns {React.ReactElement}
 * @constructor
 */
export const CustomersFilter = (props: PropsWithChildren<TFilterProps>): ReactElement => {
	const { controls, ...rest } = props;
	const [t] = useTranslation(customersConfig.trNamespace);
	const { isGroupInternal } = useAuthContext();

	return (
		<DataFilter<TFilter>
			useLocation
			{...rest}
		>
			{({ values, updateFilter }) => (
				<Row
					multi
					size="sm"
					align="center"
				>
					{isGroupInternal && (
						<>
							<Col
								xl={3}
								lg={4}
								md={6}
							>
								<SelectPartner
									disabledLabel
									size="sm"
									value={values.partnerId}
									onChange={(partnerId) => updateFilter({ partnerId })}
								/>
							</Col>
							<Col
								xs={12}
								className="col-hr"
							/>
						</>
					)}
					<Col md={6}>
						<SearchBox
							size="sm"
							value={values.filter}
							placeholder={t('select.filter.placeholder')}
							onChange={(filter) => updateFilter({ filter })}
						/>
					</Col>
					<Col
						md={6}
						className="text-end"
					>
						{controls}
					</Col>
				</Row>
			)}
		</DataFilter>
	);
};
