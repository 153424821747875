import { useDefaultConfig } from 'submodule/widgets/hooks/useDefaultConfig';
import { TWidgetGrid } from 'submodule/widgets';
import { AffiliateConfigEnum } from 'module/partners/enums';
import { useAffiliateConfig } from 'module/partners/hooks/useAffiliateConfig';
import { widgetConfigNormalizer } from 'submodule/widgets/normalizer';
import { solveWidgets } from 'submodule/widgets/solveWidgetRows';
import { useAuthContext } from 'js/contexts';
import { useAllowedWidgets } from 'submodule/widgets/hooks/useAllowedWidgets';

type TUseLoadUserWidgetConfig = {
	config: TWidgetGrid;
	loading: boolean;
};

export const useUserWidgetConfig = (): TUseLoadUserWidgetConfig => {
	const defaultConfig = useDefaultConfig();
	const { authPartnerId } = useAuthContext();
	const allowedWidgets = useAllowedWidgets();

	const { data, loading } = useAffiliateConfig<string, TWidgetGrid>({
		affiliateId: authPartnerId!,
		name: AffiliateConfigEnum.HOME_WIDGET,
		defaultValue: defaultConfig,
	});

	let config = defaultConfig;

	if (data?.value) {
		config = widgetConfigNormalizer.normalize(data.value, allowedWidgets);
	}

	return {
		config: solveWidgets.appendLastEmptyRow(config),
		loading,
	};
};
