import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useAuthContext } from 'js/contexts';

export type TPartyType = 'srp' | 'customer' | 'quotedCustomer' | 'partner';
type TTransKey = 'price' | 'unitPrice' | 'totalPrice';
export type TUsePartyPrefixTranslation = {
	tPartyPrice(key: TTransKey, type?: TPartyType): string;
};

export const usePartyPriceTranslation = (): TUsePartyPrefixTranslation => {
	const [t] = useTranslation('common');
	const { isGroupPartner } = useAuthContext();

	const prefixType = isGroupPartner ? 'your' : 'partner';

	return {
		tPartyPrice: useCallback(
			(key, type) => {
				const transKey: string[] = ['partyPrice', type || prefixType, key];
				return t(transKey.join('.'));
			},
			[t, prefixType],
		),
	};
};
