import { IEntityCustomer } from 'module/customers';
import { TableColumnsDef } from 'types';
import { createColumnHelper } from 'js/components/table/createColumnHelper';
import { createColumnFormatterHelper } from 'js/components/table/createColumnFormatterHelper';
import { useTranslation } from 'react-i18next';
import { customersConfig } from 'module/customers/customersConfig';
import React, { useMemo } from 'react';
import { noValuePlaceholder } from 'js/utils/app';
import { customerTypeEnumUtils } from 'module/customers/enums';
import { DropdownLink } from 'js/components/molecules/Dropdown/DropdownLink';
import { buildParametrizedRoute, tableFilterCreator } from 'js/utils/common';
import { RouteName } from 'module/RouteName';
import { ILicenseListFilter } from 'module/licenses';
import { IOrderListFilter } from 'module/orders';
import { Can } from 'js/components/molecules/Can';
import { useAuthContext } from 'js/contexts';
import { ELLIPSIS_CELL_WIDTH } from 'appConstants';

type TTableData = IEntityCustomer;

export const useCustomerListColumns = (): TableColumnsDef<TTableData> => {
	const [t] = useTranslation(customersConfig.trNamespace);
	const { isGroupPartner } = useAuthContext();

	return useMemo(() => {
		const columnHelper = createColumnHelper<TTableData>();
		const formatter = createColumnFormatterHelper<TTableData>();

		return [
			columnHelper.hidden('id'),
			columnHelper.link(
				'companyName',
				{
					header: t('common:entity.company'),
					meta: {
						formatters: [formatter.bold],
						defaultValue: { value: noValuePlaceholder(), applyFormatters: true },
						truncateText: { width: ELLIPSIS_CELL_WIDTH },
					},
				},
				{
					to: customersConfig.detailLink,
				},
			),
			columnHelper.text('firstName', { header: t('common:contact.firstName') }),
			columnHelper.text('lastName', { header: t('common:contact.lastName') }),
			columnHelper.copy2clipboard('email', { header: t('common:contact.email') }),
			columnHelper.text('contactType', {
				header: t('entity.customerType'),
				meta: { formatters: [customerTypeEnumUtils.getText] },
			}),
			columnHelper.text('phone', {
				header: t('common:contact.phone'),
				meta: { defaultValue: { value: noValuePlaceholder() } },
			}),
			columnHelper.text('billing.discountType', {
				header: t('entity.discount'),
				meta: { defaultValue: { value: noValuePlaceholder() } },
			}),
			columnHelper.actions({
				link: {
					children: t('common:actions.detail'),
					to: customersConfig.detailLink,
					testId: 'detail',
				},
				dropdown: {
					items(row) {
						const items = [
							<DropdownLink
								key="licenses"
								to={buildParametrizedRoute(
									RouteName.LICENSE.LIST,
									tableFilterCreator<ILicenseListFilter>({ customerId: row.id }),
								)}
								testId="relatedLicenses"
							>
								{t('actions.relatedLicenses')}
							</DropdownLink>,
							<DropdownLink
								key="orders"
								to={buildParametrizedRoute(
									RouteName.ORDER.LIST,
									tableFilterCreator<IOrderListFilter>({ customerId: row.id }),
								)}
								testId="relatedOrders"
							>
								{t('actions.relatedOrders')}
							</DropdownLink>,
							<Can
								key="update"
								do={customersConfig.aclModule}
								update
							>
								<DropdownLink
									to={buildParametrizedRoute(RouteName.CUSTOMERS.UPDATE, row.id)}
									testId="update"
								>
									{t('actions.edit')}
								</DropdownLink>
							</Can>,
						];

						if (!isGroupPartner) {
							items.push(
								<DropdownLink
									key="quotes"
									to={buildParametrizedRoute(
										RouteName.QUOTE.LIST,
										tableFilterCreator<IOrderListFilter>({ customerId: row.id }),
									)}
									testId="relatedQuotes"
								>
									{t('actions.relatedQuotes')}
								</DropdownLink>,
							);
						}

						return items;
					},
				},
			}),
		];
	}, [t, isGroupPartner]);
};
