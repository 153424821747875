import React from 'react';
import { TCustomerFormFields } from 'module/customers/forms/CustomerForm';
import { AsyncModal, TAsyncModalContainerProps, useAsyncModalRef } from 'js/components/molecules/Modal/AsyncModal';
import { IEntityCustomer } from 'module/customers';
import { CreateCustomerContainer } from 'module/customers/components/CreateCustomerContainer';
import { IEntityPartner } from 'module/partners';

export type TAsyncCreateCustomerModalProps = {
	initialValues?: TCustomerFormFields | null;
	partner?: IEntityPartner | null;
};

export const useAsyncCreateCustomerRef = () => useAsyncModalRef<TAsyncCreateCustomerModalProps, IEntityCustomer>();

export const AsyncCreateCustomerModal = (
	props: TAsyncModalContainerProps<TAsyncCreateCustomerModalProps, IEntityCustomer>,
) => {
	const { forwardedRef } = props;

	return (
		<AsyncModal<TAsyncCreateCustomerModalProps, IEntityCustomer>
			ref={forwardedRef}
			testId="createCustomer"
			backdrop="static"
		>
			{(props) => (
				<CreateCustomerContainer
					partner={props.partner}
					initialValues={props.initialValues}
					onCreate={(customer) => forwardedRef.current?.onSuccess(customer)}
					onCancel={() => forwardedRef.current?.onCancel()}
				/>
			)}
		</AsyncModal>
	);
};
