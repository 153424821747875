import React from 'react';
import { Col, Row } from 'js/components/atoms/Row';
import { DataTable, DataTableTr } from 'js/components/molecules/DataTable';
import { closingBalanceConfig } from 'module/closingBalance/closingBalanceConfig';
import { useTranslation } from 'react-i18next';
import { IEntityClosingBalance } from 'module/closingBalance';
import { dateFormatter } from 'js/utils/dateTime';
import { numberFormatter } from 'js/utils/number';
import { ordersConfig } from 'module/orders/ordersConfig';
import { normalizeStateName } from 'js/utils/normalize';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import { DateTime } from 'luxon';
import { CONFIG } from 'config';
import { closingBalanceOrderStatusEnumUtils } from 'module/closingBalance/enums';
import { isGcOrder } from 'module/orders/utils/common';
import { paymentStatusEnumUtils } from 'js/enums';

export const InformationTable = () => {
	const [t] = useTranslation(closingBalanceConfig.trNamespace);
	const {
		data,
		query: { isFetching },
	} = usePageDetailContext<IEntityClosingBalance>();
	const periodStartDate =
		data.periodEndDate &&
		// TODO: https://butr.avast.com/browse/BOSS-3504 use prepared functions from UI components
		DateTime.fromISO(data.periodEndDate).setZone(CONFIG.LOCALE.TIMEZONE).startOf('month').toISO();

	return (
		<Row
			multi
			md={2}
		>
			<Col>
				<DataTable isLoading={isFetching}>
					<DataTableTr
						name={t('common:entity.companyName')}
						highlighted
					>
						{data.billableParty?.name}
					</DataTableTr>
					<DataTableTr name={t('common:entity.companyId')}>{data.billableParty?.id}</DataTableTr>
					<DataTableTr name={t('common:contact.taxId')}>{data.billableParty?.taxId}</DataTableTr>
					<DataTableTr name={t('common:address.street')}>{data.billableParty?.address1}</DataTableTr>
					<DataTableTr name={t('common:address.city')}>{data.billableParty?.city}</DataTableTr>
					<DataTableTr name={t('common:address.postalCode')}>{data.billableParty?.postalCode}</DataTableTr>
					<DataTableTr
						name={t('common:address.state')}
						noRenderEmpty
					>
						{normalizeStateName(data.billableParty?.state)}
					</DataTableTr>
					<DataTableTr name={t('common:address.country')}>{data.billableParty?.country}</DataTableTr>
				</DataTable>
			</Col>
			<Col>
				<DataTable isLoading={isFetching}>
					<DataTableTr
						name={t('common:entity.detailId')}
						highlighted
					>
						{isGcOrder(data) ? data.chargeOrderId : data.id}
					</DataTableTr>
					<DataTableTr
						name={t('common:entity.orderStatus')}
						highlighted
					>
						{closingBalanceOrderStatusEnumUtils.getText(data.billing?.status)}
					</DataTableTr>
					<DataTableTr name={t(ordersConfig.trPrefix('entity.gcOrderNumber'))}>{data.gcOrderId}</DataTableTr>
					<DataTableTr name={t('common:dates.start')}>{dateFormatter.toDate(periodStartDate)}</DataTableTr>
					<DataTableTr name={t('common:dates.end')}>{dateFormatter.toDate(data.periodEndDate)}</DataTableTr>
					<DataTableTr name={t('common:entity.totalAmount')}>
						{numberFormatter.currency(data.totalAmountWithoutTax, data.currency)}
					</DataTableTr>
					<DataTableTr name={t('common:entity.totalAmountWTax')}>
						{numberFormatter.currency(data.totalAmountWithTax, data.currency)}
					</DataTableTr>
					<DataTableTr
						name={t('common:entity.paymentStatus')}
						highlighted
					>
						{paymentStatusEnumUtils.getText(data.payment?.status)}
					</DataTableTr>
					<DataTableTr
						name={t('common:dates.payment')}
						highlighted
						defaultValue={t('common:_.NA')}
					>
						{dateFormatter.toDate(data.payment?.date)}
					</DataTableTr>
					<DataTableTr
						name={t('common:dates.paymentDue')}
						defaultValue={t('common:_.NA')}
					>
						{dateFormatter.toDate(data.payment?.dueDate)}
					</DataTableTr>
					<DataTableTr name={t('common:entity.paymentAmount')}>
						{numberFormatter.currency(data.payment?.amount, data.currency)}
					</DataTableTr>
					<DataTableTr
						name={t('common:dates.billing')}
						defaultValue={t('common:_.NA')}
					>
						{dateFormatter.toDate(data.billing?.date)}
					</DataTableTr>
				</DataTable>
			</Col>
		</Row>
	);
};
