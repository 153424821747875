import { SelectPartyOptionEnum } from '../enums';
import React, { PropsWithChildren } from 'react';
import { Button, CardFooter } from '@avast/react-ui-components';

type TSelectPartyCardFooterProps = {
	option: SelectPartyOptionEnum;
	disabled?: boolean;
	loading?: boolean;
	onSelect(): void;
};

export const SelectPartyCardFooter = (props: PropsWithChildren<TSelectPartyCardFooterProps>) => {
	return (
		<CardFooter className="text-center">
			<Button
				disabled={props.disabled}
				loading={props.loading}
				onClick={props.onSelect}
				size="sm"
				testId={`selectParty-${props.option}`}
			>
				{props.children}
			</Button>
		</CardFooter>
	);
};
