import React, { ReactElement, useMemo, useState } from 'react';
import { IApiSortBy } from 'types/api';
import { ApiPaginatedListTable } from 'js/components/molecules/DataTable';
import { LicensesFilter, SearchByLicenseStringTable } from 'module/licenses/components';
import { FilterPlaceholderPortal } from 'js/layouts/placeholder/FilterPlaceholder';
import { RouteName } from 'module/RouteName';
import { useApiLicenseList, useLicenseListColumns } from 'module/licenses/hooks';
import { IEntityLicense, ILicenseListApiFilter, ILicenseListFilter } from 'module/licenses';
import { buildParametrizedRoute } from 'js/utils/common';
import { useAsyncContainerRef } from 'js/components/molecules/Modal/AsyncContainer';
import {
	AsyncLicenseOperationContainer,
	TAsyncLicenseOperationContainerProps,
} from 'module/licenses/components/licenseOperation/AsyncLicenseOperationContainer';
import { ExportLicensesButton } from 'module/licenses/components/buttons';
import { IListMetaDataValues } from 'types/utils';
import { ButtonComposition } from '@avast/react-ui-components';
import { useNavigate } from 'react-router-dom';
import { SortDirectionEnum } from 'js/enums';
import { useAuthContext } from 'js/contexts';
import { LicenseSearchKeyEnum } from 'module/licenses/enums';

type TTableData = IEntityLicense;
type TTableDataFilter = ILicenseListFilter;
type TTableDataApiFilter = ILicenseListApiFilter;

export const PageList = (): ReactElement => {
	const navigate = useNavigate();
	const { isGroupPartner, authCompanyId, isAuthorizedBuyer } = useAuthContext();
	const [listMetaData, setListMetaData] = useState<IListMetaDataValues<TTableDataFilter>>({});

	// Set manual (static) filter - not change able
	const filterStatic = useMemo<TTableDataFilter>(
		() =>
			isGroupPartner
				? {
						partnerId: authCompanyId,
					}
				: {},
		[authCompanyId, isGroupPartner],
	);

	// Async license operation container
	const licenseOperationRef = useAsyncContainerRef<TAsyncLicenseOperationContainerProps>();

	// Set changeable filter
	const [filter, setFilter] = useState<TTableDataFilter | null>(null);

	// Table args
	const [sort] = useState<IApiSortBy<TTableData>>({ key: 'expiration', direction: SortDirectionEnum.ASC });

	// Define columns
	const columns = useLicenseListColumns(licenseOperationRef);

	return (
		<>
			<FilterPlaceholderPortal>
				<LicensesFilter
					values={filter}
					onChange={setFilter}
					controls={
						<ButtonComposition size="sm">
							<ExportLicensesButton listMetaData={listMetaData} />
							<ExportLicensesButton
								listMetaData={listMetaData}
								detailed
							/>
						</ButtonComposition>
					}
				/>
			</FilterPlaceholderPortal>

			{(() => {
				if (filter?.search?.key === LicenseSearchKeyEnum.LICENSE_KEY && Boolean(filter.search.value)) {
					return (
						<SearchByLicenseStringTable
							columns={columns}
							onMetaDataChange={setListMetaData}
							sort={{ key: 'expiration', direction: SortDirectionEnum.DESC }}
							filter={filter}
						/>
					);
				}

				return (
					<ApiPaginatedListTable<TTableData, TTableDataFilter, TTableDataApiFilter>
						columns={columns}
						query={useApiLicenseList}
						sort={sort}
						useLocation
						filter={filter}
						filterStatic={filterStatic}
						filterRequired
						table={{
							testId: 'licenses',
							meta: {
								onRowClick: (license) => navigate(buildParametrizedRoute(RouteName.LICENSE.DETAIL, license.id)),
							},
						}}
						onMetaDataChange={setListMetaData}
					/>
				);
			})()}

			{isAuthorizedBuyer && <AsyncLicenseOperationContainer forwardedRef={licenseOperationRef} />}
		</>
	);
};
