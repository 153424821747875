import { IApiError, TApiErrorResolve, TApiErrorResult } from 'types/api';
import { registrationConfig } from 'module/registration/registrationConfig';
import i18n from 'i18next';
import { apiErrorResolveError } from 'js/utils/apiError';
import { API_ERROR_REGISTRATION } from 'module/registration/constants';

const resolveApiError = (title: string, error: IApiError): TApiErrorResult => {
	const messages = apiErrorResolveError(error, API_ERROR_REGISTRATION);
	return { title, messages };
};

export const initRegistrationApiError: TApiErrorResolve = (error) =>
	resolveApiError(i18n.t('error:unspecifiedError'), error);

export const resolveTermsAcceptedApiError: TApiErrorResolve = (error) =>
	resolveApiError(i18n.t(registrationConfig.trPrefix('error.unableToAcceptTerms')), error);

export const sendRegistrationInfoApiError: TApiErrorResolve = (error) =>
	resolveApiError(i18n.t(registrationConfig.trPrefix('error.unableToProcess')), error);

export const sendTaxExemptionFormApiError: TApiErrorResolve = (error) =>
	resolveApiError(i18n.t(registrationConfig.trPrefix('error.unableToProcess')), error);

export const finishingRegistrationApiError: TApiErrorResolve = (error) =>
	resolveApiError(i18n.t(registrationConfig.trPrefix('error.unableToProcess')), error);
