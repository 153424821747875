import { IInitiateRefundForm } from 'submodule/refunds/index';
import { RefundTypeEnum } from 'submodule/refunds/enums';

export const isFullRefund = (refundFormData: IInitiateRefundForm) => {
	return refundFormData.refundableAmount === refundFormData.refundAmount;
};

export const isRefundTypeOrder = (values: IInitiateRefundForm) => {
	return values.refundType === RefundTypeEnum.ORDER;
};
