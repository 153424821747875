import React from 'react';
import { useTranslation } from 'react-i18next';
import { closingBalanceConfig } from 'module/closingBalance/closingBalanceConfig';
import { Col, Row } from 'js/components/atoms/Row';
import { DataTable, DataTableTr } from 'js/components/molecules/DataTable';
import { IEntityClosingBalanceLicenses } from 'module/closingBalance';
import { numberFormatter } from 'js/utils/number';
import { dateFormatter } from 'js/utils/dateTime';
import { Copy2Clipboard } from 'js/components/molecules/Copy2Clipboard';
import { buildParametrizedRoute } from 'js/utils/common';
import { RouteName } from 'module/RouteName';
import { Link } from 'react-router-dom';
import { licensesConfig } from 'module/licenses/licensesConfig';

type TDetailViewModalContentProps = {
	detailView: IEntityClosingBalanceLicenses;
};

export const DetailViewModalContent = ({ detailView }: TDetailViewModalContentProps) => {
	const [t] = useTranslation(closingBalanceConfig.trNamespace);
	const { licenseKey, product, partnerDiscount, lineItemPriceWithoutTax, standardOrder, srpTotalPrice, currency } =
		detailView;

	return (
		<Row
			multi
			md={2}
		>
			<Col>
				<DataTable>
					<DataTableTr name={t('common:entity.licenseKey')}>
						<Copy2Clipboard value={licenseKey} />
					</DataTableTr>
					<DataTableTr name={t('common:entity.productName')}>{product.name}</DataTableTr>
					<DataTableTr name={t('entity.term')}>
						{product.validity && t('common:datePeriod.MONTH', { count: product.validity })}
					</DataTableTr>
					<DataTableTr name={t('common:entity.quantity')}>{product.quantity}</DataTableTr>
					<DataTableTr name={t(`${licensesConfig.trNamespace}:entity.orderId`)}>
						<Link to={buildParametrizedRoute(RouteName.ORDER.DETAIL, standardOrder.id)}>{standardOrder.id}</Link>
					</DataTableTr>
				</DataTable>
			</Col>
			<Col>
				<DataTable>
					<DataTableTr name={t('common:entity.sku')}>{product.sku}</DataTableTr>
					<DataTableTr name={t('entity.partnerDiscount')}>{partnerDiscount}</DataTableTr>
					<DataTableTr name={t('entity.itemPriceWOTax')}>
						{numberFormatter.currency(lineItemPriceWithoutTax, currency)}
					</DataTableTr>
					<DataTableTr name={t('entity.fulfillmentCompleteDate')}>
						{dateFormatter.toTimeZoneDateTime(standardOrder.fulfillmentCompleteDate)}
					</DataTableTr>
					<DataTableTr name={t('entity.srpTotalPrice')}>
						{numberFormatter.currency(srpTotalPrice, currency)}
					</DataTableTr>
				</DataTable>
			</Col>
		</Row>
	);
};
