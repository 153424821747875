import { useMemo } from 'react';
import { useApiPartnerPaymentTypes } from 'module/partners/hooks/useApiPartners';
import { ICheckoutButton, IPaymentType, IPaymentTypesRequestParams, IReviewOrderButton } from 'module/purchase';
import { totalAmountSelector } from 'js/selectors/order';
import { getPaymentTypeTranslationKey, sortPaymentTypes } from 'module/purchase/utils/common';
import { PAYMENT_TYPES_DETAIL } from 'module/purchase/constants';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { hasInstanceZeroValueItem, hasOnlyBusinessProducts } from 'module/purchase/utils/selectors';
import { IButtonVisibility } from 'types';
import { isPartnerEligibleToBuy } from 'module/partners/utils/partnerSelectors';
import { isIspOrder } from 'module/orders/utils/common';
import { useRequiredOrderParties } from 'module/purchase/hooks/useRequiredOrderParties';
import { logError } from 'js/utils/app';
import { useFormikContext } from 'formik';
import { LicenseOperationEnum } from 'module/licenses/enums';
import {
	BillingSystemPaymentTypeEnum,
	PaymentTypeActionEnum,
	PaymentTypeTranslationKeyEnum,
} from 'module/purchase/enums';
import { useAuthContext } from 'js/contexts/AuthContext';
import { useOrderContext } from 'js/contexts';
import { isDefined } from 'js/utils/common';
import { isString } from 'lodash';

type TUsePaymentTypesResponse = {
	paymentTypes: IPaymentType[];
	allowedPaymentTypes: IPaymentType[];
	isLoading: boolean;
	isFetching: boolean;
};

type TUseCheckoutButtons = {
	checkoutButtons: ICheckoutButton[];
	isLoading: boolean;
	isFetching: boolean;
};

/**
 * Prepare request data for get payment type
 * @returns {IPaymentTypesRequestParams}
 */
const useGetPaymentTypeRequest = (): IPaymentTypesRequestParams => {
	const { orderState, isPartnerBillableParty, billableParty } = useOrderContext();
	const amount: number = totalAmountSelector(orderState);

	if (isPartnerBillableParty && isDefined(billableParty)) {
		return {
			partnerId: billableParty.partnerId,
			amount,
		};
	}

	return {};
};

const usePaymentButtonsGuard = (): { isEnabled: boolean } => {
	const { isDiscountApprovalRequired, orderState } = useOrderContext();
	const { orderPartiesValid } = useRequiredOrderParties();

	if (isDiscountApprovalRequired) {
		return { isEnabled: false };
	}

	if (hasInstanceZeroValueItem(orderState)) {
		return { isEnabled: false };
	}

	return { isEnabled: orderPartiesValid };
};

/**
 * Get checkout buttons
 * @returns {TUseCheckoutButtons}
 */
export const useCheckoutButtons = (): TUseCheckoutButtons => {
	const paymentTypes = usePaymentTypes();
	const { orderState } = useOrderContext();
	const { isValid } = useFormikContext();
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const quotePaymentTypes = useQuotePaymentTypes();
	const endCustomerPaymentTypes = useEndCustomerPaymentTypes();
	const paymentButtonsGuard = usePaymentButtonsGuard();
	const { orderPartiesValid } = useRequiredOrderParties();

	const checkoutButtons: ICheckoutButton[] = [
		...paymentTypes.allowedPaymentTypes.map<ICheckoutButton>((paymentType) => ({
			caption: t(getPaymentTypeTranslationKey(paymentType.name, PaymentTypeTranslationKeyEnum.CAPTION)),
			name: t(getPaymentTypeTranslationKey(paymentType.name, PaymentTypeTranslationKeyEnum.BUTTON)),
			isEnabled: paymentType.isEnabled && paymentButtonsGuard.isEnabled && isValid,
			action: paymentType.name,
			variant: 'primary',
		})),
		...endCustomerPaymentTypes,
		...quotePaymentTypes,
		{
			caption: t(getPaymentTypeTranslationKey(PaymentTypeActionEnum.SAVE, PaymentTypeTranslationKeyEnum.CAPTION)),
			name: t(getPaymentTypeTranslationKey(PaymentTypeActionEnum.SAVE, PaymentTypeTranslationKeyEnum.BUTTON)),
			isEnabled: !orderState.isLocked && orderPartiesValid && isValid,
			action: PaymentTypeActionEnum.SAVE,
			variant: 'outline-primary',
		},
	];

	return {
		checkoutButtons,
		isLoading: paymentTypes.isLoading,
		isFetching: paymentTypes.isFetching,
	};
};

const useEndCustomerPaymentTypes = () => {
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const paymentTypes: ICheckoutButton[] = [];
	const endCustomerQuotePaymentTypeVisibility = useEndCustomerQuotePaymentTypeVisibility();
	const endCustomerLocPaymentTypeVisibility = useEndCustomerLocPaymentTypeVisibility();

	if (endCustomerLocPaymentTypeVisibility.isAllowed) {
		paymentTypes.push({
			caption: t(
				getPaymentTypeTranslationKey(
					BillingSystemPaymentTypeEnum.GLOBAL_COMMERCE_LINE_OF_CREDIT,
					PaymentTypeTranslationKeyEnum.CAPTION,
				),
			),
			name: t(
				getPaymentTypeTranslationKey(
					BillingSystemPaymentTypeEnum.GLOBAL_COMMERCE_LINE_OF_CREDIT,
					PaymentTypeTranslationKeyEnum.BUTTON,
				),
			),
			isEnabled: endCustomerLocPaymentTypeVisibility.isEnabled,
			action: BillingSystemPaymentTypeEnum.GLOBAL_COMMERCE_LINE_OF_CREDIT,
			variant: 'primary',
		});
	}

	if (endCustomerQuotePaymentTypeVisibility.isAllowed) {
		paymentTypes.push({
			caption: t(
				getPaymentTypeTranslationKey(PaymentTypeActionEnum.END_CUSTOMER_QUOTE, PaymentTypeTranslationKeyEnum.CAPTION),
			),
			name: t(
				getPaymentTypeTranslationKey(PaymentTypeActionEnum.END_CUSTOMER_QUOTE, PaymentTypeTranslationKeyEnum.BUTTON),
			),
			isEnabled: endCustomerQuotePaymentTypeVisibility.isEnabled,
			action: PaymentTypeActionEnum.END_CUSTOMER_QUOTE,
			variant: 'outline-primary',
		});
	}

	return paymentTypes;
};

const useQuotePaymentTypes = () => {
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const quotePaymentTypeVisibility = useQuotePaymentTypeVisibility();
	const customerQuotePaymentTypeVisibility = useCustomerQuotePaymentTypeVisibility();
	const paymentTypes: ICheckoutButton[] = [];

	if (quotePaymentTypeVisibility.isAllowed) {
		paymentTypes.push({
			caption: t(getPaymentTypeTranslationKey(PaymentTypeActionEnum.QUOTE, PaymentTypeTranslationKeyEnum.CAPTION)),
			name: t(getPaymentTypeTranslationKey(PaymentTypeActionEnum.QUOTE, PaymentTypeTranslationKeyEnum.BUTTON)),
			isEnabled: quotePaymentTypeVisibility.isEnabled,
			action: PaymentTypeActionEnum.QUOTE,
			variant: 'outline-primary',
		});
	}

	if (customerQuotePaymentTypeVisibility.isAllowed) {
		paymentTypes.push({
			caption: t(
				getPaymentTypeTranslationKey(PaymentTypeActionEnum.CUSTOMER_QUOTE, PaymentTypeTranslationKeyEnum.CAPTION),
			),
			name: t(getPaymentTypeTranslationKey(PaymentTypeActionEnum.CUSTOMER_QUOTE, PaymentTypeTranslationKeyEnum.BUTTON)),
			isEnabled: customerQuotePaymentTypeVisibility.isEnabled,
			action: PaymentTypeActionEnum.CUSTOMER_QUOTE,
			variant: 'outline-primary',
		});
	}
	return paymentTypes;
};

export const useEditPriceButton = (): IReviewOrderButton | null => {
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const { isRoleSalesOperations, authPartner } = useAuthContext();
	const {
		orderState: { pricing, isLocked, items },
		isEndCustomerBillableParty,
	} = useOrderContext();
	const customPaymentTypeEnabled = useCustomPaymentTypeEnabled(false);

	const isAllowed = isRoleSalesOperations && authPartner?.attributes?.canEditPrice && !isEndCustomerBillableParty;
	const isEnabled =
		!pricing?.headers.discountApplied &&
		items.some((item) => item.licenseOperation === LicenseOperationEnum.NEW) &&
		customPaymentTypeEnabled;

	if (isAllowed && !isLocked) {
		return {
			name: t('components.editPrices.button'),
			isEnabled,
			variant: 'outline-danger',
		};
	}
	return null;
};

const useQuotePaymentTypeVisibility = (): IButtonVisibility => {
	const { isGroupSales } = useAuthContext();
	const { isEndCustomerBillableParty, orderState, partnerGroups } = useOrderContext();
	const customPaymentTypeEnabled = useCustomPaymentTypeEnabled(false);
	const { isValid } = useFormikContext();

	if (!isGroupSales || isEndCustomerBillableParty || partnerGroups.isRetail) {
		return { isAllowed: false };
	}

	return { isAllowed: true, isEnabled: !isIspOrder(orderState) && customPaymentTypeEnabled && isValid };
};

const useEndCustomerQuotePaymentTypeVisibility = (): IButtonVisibility => {
	const { isGroupSales } = useAuthContext();
	const { isEndCustomerBillableParty, orderState } = useOrderContext();
	const { isValid } = useFormikContext();
	const customPaymentTypeEnabled = useCustomPaymentTypeEnabled();

	if (!isGroupSales || !isEndCustomerBillableParty) {
		return { isAllowed: false };
	}

	return {
		isAllowed: true,
		isEnabled: customPaymentTypeEnabled && hasOnlyBusinessProducts(orderState) && isValid,
	};
};

const useEndCustomerLocPaymentTypeVisibility = (): IButtonVisibility => {
	const { isRoleSalesOperations } = useAuthContext();
	const { isEndCustomerBillableParty, orderState } = useOrderContext();
	const { isValid } = useFormikContext();
	const customPaymentTypeEnabled = useCustomPaymentTypeEnabled();

	if (!isRoleSalesOperations || !isEndCustomerBillableParty) {
		return { isAllowed: false };
	}

	return {
		isAllowed: true,
		isEnabled: customPaymentTypeEnabled && Boolean(orderState.customer?.isCreditEligible) && isValid,
	};
};

const useCustomPaymentTypeEnabled = (checkPartnerEligibility: boolean = true) => {
	const { isDiscountApprovalRequired, orderState, isPartnerBillableParty } = useOrderContext();
	const { orderPartiesValid } = useRequiredOrderParties();

	let isEnabled = !hasInstanceZeroValueItem(orderState) && !isDiscountApprovalRequired && orderPartiesValid;

	if (checkPartnerEligibility && isPartnerBillableParty && isEnabled) {
		isEnabled = isPartnerEligibleToBuy(orderState.partner);
	}

	return isEnabled;
};

const useCustomerQuotePaymentTypeVisibility = (): IButtonVisibility => {
	const { isGroupPartner } = useAuthContext();
	const { orderState, partnerGroups } = useOrderContext();
	const { isValid } = useFormikContext();
	const customPaymentTypeEnabled = useCustomPaymentTypeEnabled(false);
	const hasCustomerEmail = isString(orderState.customer?.email);

	if (!isGroupPartner || partnerGroups.isRetail || !hasCustomerEmail) {
		return { isAllowed: false };
	}

	return {
		isAllowed: true,
		isEnabled: customPaymentTypeEnabled && isDefined(orderState.customer?.id) && isValid,
	};
};

/**
 * Get payment type (buttons) for the current order
 * @returns {TUsePaymentTypesResponse}
 */
export const usePaymentTypes = (): TUsePaymentTypesResponse => {
	const requestData = useGetPaymentTypeRequest();
	const {
		orderState: { pricing },
	} = useOrderContext();

	const {
		data,
		query: { isFetching, isInitialLoading },
	} = useApiPartnerPaymentTypes({
		filter: requestData,
		queryConfig: {
			enabled:
				isDefined(requestData) &&
				isDefined(requestData.partnerId) &&
				isDefined(requestData.amount) &&
				isDefined(pricing),
		},
	});

	const paymentTypes: IPaymentType[] = useMemo(() => {
		if (data) {
			return data.paymentTypes
				.filter((type) => {
					if (PAYMENT_TYPES_DETAIL.hasOwnProperty(type.name)) {
						return true;
					}

					logError(`Undefined payment type ${type.name}`, type);
					return false;
				})
				.sort(sortPaymentTypes)
				.map((type) => ({ ...type, ...PAYMENT_TYPES_DETAIL[type.name] }));
		}
		return [];
	}, [data]);

	return {
		paymentTypes,
		allowedPaymentTypes: paymentTypes.filter((type) => type.isAllowed),
		isLoading: isInitialLoading,
		isFetching: isFetching || !pricing,
	};
};
