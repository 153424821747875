import { IOrderContext } from 'js/contexts/OrderContext';
import {
	IEntityProduct,
	IEntityProductGroup,
	IPricingResponse,
	IPricingResponseHeader,
	IStandardOrder,
	IStandardOrderInstance,
	IStandardOrderInstanceItem,
	IStandardOrderInstanceProduct,
	IStandardOrderItem,
	IStandardOrderItemPricing,
	IStandardOrderPricing,
	IStandardOrderSave,
} from 'module/purchase';
import { getOrderInstanceItemQuantity } from 'module/purchase/utils/common';
import { LicenseOperationEnum, licenseOperationEnumUtils } from 'module/licenses/enums';
import { CUSTOM_PRICING_MESSAGE } from 'module/purchase/constants';
import { IEntityOrder, IEntityOrderItem } from 'module/orders';
import { isIspOrder, isOrderItemBusiness, normalizeBusinessSku, normalizeSku } from 'module/orders/utils/common';
import { isGroupBusiness, isGroupConsumer } from 'module/purchase/utils/selectors';
import { extractBulkQuantityFromSku, isDefined } from 'js/utils/common';
import { getOrderItemQuantity, getOrderItemUnit } from 'module/orders/utils/orderItemUtils';
import { StandardOrderDiscountCategoryEnum } from 'module/orders/enums';
import {
	BillingSystemPaymentTypeEnum,
	CustomerSegmentEnum,
	PaymentTypeActionEnum,
	ProductUnitTypeEnum,
	StandardOrderApprovalFlagEnum,
	ValidityTypeEnum,
} from 'module/purchase/enums';
import { toNumber } from 'lodash';
import { MarketSegmentEnum } from 'js/enums';

export const orderInstanceNormalizer = {
	denormalizeToSave(
		orderContext: IOrderContext,
		customPrices?: IStandardOrderInstance['customPrices'],
	): IStandardOrderSave | null {
		const { billableParty, orderState, getItemPricing, discountApprovalFlag } = orderContext;
		const { pricing } = orderState;
		const hasCustomPrices = customPrices?.some((item) => isDefined(item.unitPrice));

		if (!billableParty || !pricing) {
			return null;
		}

		const lineItems: IStandardOrderItem[] = orderState.items.map((instanceItem) => {
			const itemPricing = getItemPricing(instanceItem.id);
			const customPrice = customPrices?.find((customPrice) => customPrice.id === instanceItem.id);
			const item: IStandardOrderItem = {
				id: instanceItem.detail_id,
				sku: instanceItem.product.sku,
				licenseOperation: instanceItem.licenseOperation,
				pricing: { ...itemPricing! },
				savedCustomerPrice: instanceItem.savedCustomerPrice,
				quantity: getOrderInstanceItemQuantity(instanceItem),
				discount: itemPricing?.discountLinePercent,
			};

			if (customPrice && isDefined(customPrice.unitPrice) && item.licenseOperation === LicenseOperationEnum.NEW) {
				item.pricing.discUnitPriceCUR = customPrice.unitPrice;
				item.pricing.unitPrice = customPrice.unitPrice;
				item.pricing.linePriceWOTax = customPrice.unitPrice * item.pricing.unitsToPrice!;
				item.pricing.pricingMessageLine = CUSTOM_PRICING_MESSAGE;
				item.customUnitPrice = customPrice.unitPrice;
				if (item.pricing.pricingPromoMessage_Line) {
					item.pricing.pricingPromoMessage_Line = CUSTOM_PRICING_MESSAGE;
				}
			}

			if (item.licenseOperation !== LicenseOperationEnum.NEW) {
				item.currentEntitlementId = instanceItem.license?.id;
			}

			return item;
		});

		const orderPricing: IStandardOrderPricing = { ...pricing.headers };
		if (discountApprovalFlag) {
			orderPricing.discountApprovalFlag = discountApprovalFlag;
		}
		if (hasCustomPrices) {
			orderPricing.totalAmountWithoutTax = lineItems.reduce<number>(
				(acc, item) => acc + item.pricing.linePriceWOTax,
				0,
			);
		}

		const order: IStandardOrderSave = {
			id: orderState.standardOrderId,
			partnerId: orderState.partner?.id,
			distributionPartnerId: orderState.distributionPartner?.id,
			customerId: orderState.customer?.id,
			currencyCode: billableParty.currencyCode,
			quoteFlag:
				orderState.action === PaymentTypeActionEnum.QUOTE ||
				orderState.action === PaymentTypeActionEnum.END_CUSTOMER_QUOTE,
			privateNotes: orderState.additionalInfo.privateNotes,
			publicNotes: orderState.additionalInfo.publicNotes,
			externalPurchaseNumber: orderState.additionalInfo.externalPurchaseNumber,
			opportunityId: orderState.additionalInfo.opportunityId,
			lineItems,
			pricing: orderPricing,
			priceListCode: orderState.priceListCode,
			discount: orderPricing.discountOrderPercent,
		};

		// Quote Flag from already saved order
		if (isDefined(orderState.standardOrderId) && Boolean(orderState.quote?.flag)) {
			order.quoteFlag = true;
		}

		if (orderState.action === PaymentTypeActionEnum.CUSTOMER_QUOTE) {
			order.sendCustomerPriceEmailFlag = true;
			order.customerPriceEmailPersonalMessage = orderState.additionalInfo.customerQuoteMessage;
			order.customerPriceEmailPersonalMessageHtml = orderState.additionalInfo.customerQuoteMessage?.replace(
				/\r\n|\r|\n/g,
				'&lt;br /&gt;',
			);
		}

		return order;
	},

	denormalize(paymentType: BillingSystemPaymentTypeEnum, orderContext: IOrderContext): IStandardOrder | null {
		const standardOrderSave = this.denormalizeToSave(orderContext);

		return standardOrderSave
			? {
					...standardOrderSave,
					paymentType,
				}
			: null;
	},

	normalizeProduct(group: IEntityProductGroup, product: IEntityProduct): IStandardOrderInstanceProduct {
		return {
			...product,
			isBusiness: isGroupBusiness(group),
			isConsumer: isGroupConsumer(group),
			group: {
				name: group.name,
				code: group.code,
				marketSegment: group.marketSegment,
				unitType: group.unitType,
			},
		};
	},

	normalizeProductFallback(item: IEntityOrderItem): IStandardOrderInstanceProduct | null {
		const sku = item.product.sku || item.product.naturalSku;
		const code = item.ems?.order?.productGroup;
		const name = item.product?.description;
		const isBusiness = isOrderItemBusiness(item);
		const validity: number = toNumber(item.ems?.order?.validity);
		const bulkQuantity = extractBulkQuantityFromSku(sku);

		if (!sku || !code || !name || !validity || !isDefined(bulkQuantity)) {
			return null;
		}

		const group: IStandardOrderInstanceProduct['group'] = {
			code,
			name,
			unitType: isBusiness ? ProductUnitTypeEnum.SEATS : ProductUnitTypeEnum.DEVICE, // Default value, we are unable to recognize it
			marketSegment: isBusiness ? MarketSegmentEnum.BUSINESS : MarketSegmentEnum.CONSUMER,
		};

		return {
			sku: isBusiness ? normalizeBusinessSku(sku) : normalizeSku(sku),
			label: name,
			...this.normalizeProductValidityData(validity),
			bulkQuantity: isBusiness ? 1 : bulkQuantity,
			group,
			isBusiness,
			isConsumer: !isBusiness,
		};
	},

	normalizeProductValidityData(validityValue: number): Pick<IEntityProduct, 'validity' | 'validityType'> {
		let validity: IEntityProduct['validity'] = validityValue;
		let validityType: IEntityProduct['validityType'] = ValidityTypeEnum.MONTH;

		if (validity % 12 === 0) {
			validity /= 12;
			validityType = ValidityTypeEnum.YEAR;
		}

		return { validity, validityType };
	},

	normalizeIspItemToProduct(
		group: IEntityProductGroup,
		lineItem: IEntityOrderItem,
	): IStandardOrderInstanceProduct | null {
		const id = lineItem.product?.id;
		const sku = normalizeSku(lineItem.product?.sku);
		const validity = parseInt(lineItem.ems?.order?.validity || '', 10);

		if (!id || !sku || !validity) {
			return null;
		}

		const isConsumer = isGroupConsumer(group);
		const bulkQuantity = extractBulkQuantityFromSku(sku);
		return {
			sku,
			isConsumer,
			isBusiness: isGroupBusiness(group),
			...this.normalizeProductValidityData(validity),
			bulkQuantity: isConsumer ? bulkQuantity : 1,
			group: {
				code: group.code,
				name: group.name,
				unitType: group.unitType,
				marketSegment: group.marketSegment,
			},
		};
	},

	normalizePricing(order: IEntityOrder | null, lineitems: IStandardOrderItemPricing[]): IPricingResponse | null {
		if (isDefined(order?.pricing)) {
			return {
				headers: order?.pricing!,
				lineitems,
			};
		}

		if (!order || !order.totalAmountWithoutTax || !order.discount) {
			return null;
		}

		if (!isIspOrder(order) && !order.md5HashValue) {
			return null;
		}

		const headers: IPricingResponseHeader = {
			md5HashValue: order.md5HashValue,
			customerTotalPrice: lineitems.reduce((acc, item) => acc + (item.customerPrice?.total || 0), 0),
			totalAmountWithoutTax: order.totalAmountWithoutTax,
			totalPrice: order.totalPrice,
			lineQuantities: order.lineQuantities,
			discountApprovalFlag: order.discount.approvalFlag || StandardOrderApprovalFlagEnum.NA,
			discountApplied: order.discount.applied || false,
			discountCategory: order.discount.category || StandardOrderDiscountCategoryEnum.NONE,
			discountOrderFlat: order.discount.flat || 0,
			discountOrderPercent: order.discount.percent || 0,
		};

		return { headers, lineitems };
	},

	normalizeItem(
		id: number,
		item: IEntityOrderItem,
		instanceItemProduct: IStandardOrderInstanceProduct,
	): IStandardOrderInstanceItem {
		const instanceItem: IStandardOrderInstanceItem = {
			id,
			detail_id: item.id,
			licenseOperation: licenseOperationEnumUtils.getFromTransactionType(item.transactionType),
			quantity: getOrderItemQuantity(item),
			unit: getOrderItemUnit(item),
			product: instanceItemProduct,
			savedCustomerPrice: item.price?.savedCustomer,
		};

		if (item.discount?.percent) {
			instanceItem.discretionaryDiscount = item.discount.percent;
		}

		if (instanceItem.licenseOperation !== LicenseOperationEnum.NEW && isDefined(item.currentEntitlement)) {
			instanceItem.unitToIncrease = item.unitsToIncrease;
			instanceItem.licenseUnit = item.unitSeats;
			instanceItem.licenseSku = item.upgradeFromProduct?.sku;
			instanceItem.license = {
				id: item.currentEntitlement.id,
				expireDate: item.currentEntitlement.expireDate,
				createDate: item.currentEntitlement.createDate,
				licenseKey: item.currentEntitlement.key,
			};
		}

		return instanceItem;
	},

	normalizeItemPricing(id: number, item: IEntityOrderItem, order: IEntityOrder): IStandardOrderItemPricing {
		if (isDefined(item.pricing)) {
			return {
				...item.pricing,
				reference_lineitem_id: String(id),
			};
		}

		return {
			reference_lineitem_id: String(id),
			dailySaleUnitPrice: item.price?.dailySaleUnit,
			discountType: item.discount?.type,
			pricingMessageLine: item.pricingMessage,
			pricingPromoApplied_Line: item.pricingPromoApplied,
			pricingPromoMessage_Line: item.pricingPromoMessage,
			prorationRate: item.prorationRate,
			prorateUnitsTotal: item.price?.proratedUnit,
			unitsElapsed: item.unitsElapsed,
			unitsProrated: item.unitsProrated,
			unitsToIncrease: item.unitsToIncrease,
			unitsToPrice: item.pricedQuantity,
			unitSeats: item.unitSeats,
			upgradeCredit: item.upgradeCredit,
			discountLineFlat: item.discount?.flat || 0,
			discountLinePercent: item.discount?.percent || 0,
			unitsQuantityTotal: item.unitsQuantityTotal || 0,
			tierLevel: item.tierLevel || 'NA',
			linePriceWOTax: item.price?.withoutTax || 0,
			discUnitPriceCUR: item.price?.discountedUnit || 0,
			unitPrice: item.price?.discountedUnit || 0,
			priceAfterSegmentDiscount: item.price?.totalSrp || 0,
			saleUnitPriceCUR: item.price?.saleUnit || 0,
			proratedUnitPriceCUR: item.price?.proratedUnit || 0,
			customerSegment: order.customerSegment || CustomerSegmentEnum.COMMERCIAL,
			customerPrice: {
				unit: item.price?.saleUnit,
				total: item.price?.totalSrp,
			},
		};
	},
};
