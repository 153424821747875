import { TUseApiPaginatedListModule, useApiPaginatedListQuery } from 'js/queries/useApiPaginatedListQuery';
import { TUseApiDetailModule, useApiDetailQuery } from 'js/queries/useApiDetailQuery';
import { TUseApiMutationModule, useApiMutationQuery } from 'js/queries/useApiMutationQuery';
import { TDetailArguments } from 'types';
import { TUseApiListModule, useApiListQuery } from 'js/queries/useApiListQuery';
import { IEntityProductGroup } from 'module/purchase';
import {
	IAsyncExportPromotionRequest,
	IEntityPromotion,
	IPromotionListFilter,
	IPromotionPatchRequestParams,
	IPromotionRequest,
} from 'module/promotions';
import { CACHE_TIME } from 'appConstants';
import { useCallback } from 'react';
import { promotionEntityNormalizer, promotionFilterNormalizer } from 'module/promotions/normalizer';
import type { AxiosResponse } from 'axios';
import { IApiPaginatedListResponse } from 'types/api';
import { ApiMutationTypeEnum } from 'js/enums';
import { IAsyncJob } from 'js/asyncJobs';

const API_PROMOTION_KEYS = {
	LIST: 'promotion',
	CREATE: 'promotion',
	DETAIL: 'promotion/:id',
	UPDATE: 'promotion/:id',
	DISABLE: 'promotion/:id/disable',
	PRODUCT_LIST: 'product',
	EXPORT: 'export/promotions',
};

export const useApiPromotionList: TUseApiPaginatedListModule<
	IEntityPromotion,
	IPromotionListFilter,
	IPromotionListFilter
> = (props) =>
	useApiPaginatedListQuery(API_PROMOTION_KEYS.LIST, props, {
		queryConfig: {
			select: useCallback(
				(response: AxiosResponse<IApiPaginatedListResponse<IEntityPromotion>>) => ({
					...response,
					data: {
						...response.data,
						items: promotionEntityNormalizer.normalizeList(response.data.items),
					},
				}),
				[],
			),
		},
		apiFilterNormalizer: (filter) => promotionFilterNormalizer.denormalize(filter),
	});

export const useApiPromotion: TUseApiDetailModule<IEntityPromotion, TDetailArguments> = (props) =>
	useApiDetailQuery(API_PROMOTION_KEYS.DETAIL, props, {
		queryConfig: {
			select: useCallback(
				(response: AxiosResponse<IEntityPromotion>) => ({
					...response,
					data: promotionEntityNormalizer.normalize(response.data),
				}),
				[],
			),
		},
	});

export const useApiCreatePromotion: TUseApiMutationModule<IPromotionRequest, IEntityPromotion> = (props) =>
	useApiMutationQuery(API_PROMOTION_KEYS.CREATE, ApiMutationTypeEnum.POST, props, {
		invalidateQueries: Object.values(API_PROMOTION_KEYS),
	});

export const useApiUpdatePromotion: TUseApiMutationModule<
	IPromotionRequest,
	IEntityPromotion,
	IPromotionPatchRequestParams
> = (props) =>
	useApiMutationQuery(API_PROMOTION_KEYS.UPDATE, ApiMutationTypeEnum.PUT, props, {
		invalidateQueries: Object.values(API_PROMOTION_KEYS),
	});

export const useApiDisablePromotion: TUseApiMutationModule<{}, IEntityPromotion, IPromotionPatchRequestParams> = (
	props,
) =>
	useApiMutationQuery(API_PROMOTION_KEYS.DISABLE, ApiMutationTypeEnum.PATCH, props, {
		invalidateQueries: Object.values(API_PROMOTION_KEYS),
	});

export const useApiPromotionProductGroupList: TUseApiListModule<IEntityProductGroup> = (props) =>
	useApiListQuery(API_PROMOTION_KEYS.PRODUCT_LIST, props, {
		queryConfig: { staleTime: CACHE_TIME.MEDIUM },
	});

export const useApiPromotionExport: TUseApiMutationModule<IAsyncExportPromotionRequest, IAsyncJob> = (props) =>
	useApiMutationQuery(API_PROMOTION_KEYS.EXPORT, ApiMutationTypeEnum.POST, props);
