import { TChargeOrderId } from 'types';
import { useApiRefunds } from 'submodule/refunds/hooks/useApiRefunds';

export type TUseIsChargeOrderRefunded = {
	isFetching: boolean;
	isRefunded: boolean;
	isLoading: boolean;
};

export const useIsChargeOrderRefunded = (chargeOrderId?: TChargeOrderId): TUseIsChargeOrderRefunded => {
	const { data: refunds, query } = useApiRefunds({
		filter: { chargeOrderId: chargeOrderId! },
		queryConfig: {
			enabled: Boolean(chargeOrderId),
		},
	});

	return {
		isFetching: query.isFetching,
		isRefunded: Boolean(refunds) && refunds.length > 0,
		// Is going to be replaced with isLoading in React Query v5
		isLoading: query.isInitialLoading,
	};
};
