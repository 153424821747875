import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { usePartyPriceTranslation } from 'module/purchase/hooks/usePartyPriceTranslation';
import { FixedWidthWrapper } from 'js/components/molecules/Inputs/FixedWidthWrapper';
import { useFormikContext } from 'formik';
import { TEditPricesForm } from 'module/purchase/components/editPrices/AsyncEditPricesModal';
import { FormikControl } from 'js/components/formik/FormikControl';
import { numberFormatter } from 'js/utils/number';
import { useEditPrices } from 'module/purchase/components/editPrices/useEditPrices';
import { CustomTable } from 'js/components/molecules/CustomTable';
import { noValuePlaceholder } from 'js/utils/app';
import { LicenseOperationEnum } from 'module/licenses/enums';
import { useOrderContext } from 'js/contexts';

export const EditPricesTable = (): ReactElement | null => {
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const {
		orderState: { pricing },
		getItemPricing,
		getOrderItem,
		billableParty,
	} = useOrderContext();
	const { getItemPrice, getTotalPrice } = useEditPrices();
	const { tPartyPrice } = usePartyPriceTranslation();
	const { values } = useFormikContext<TEditPricesForm>();
	const currency = billableParty?.currencyCode!;

	return (
		<CustomTable
			className="table-data"
			hover
			responsive
		>
			<thead>
				<tr>
					<CustomTable.Th>{t('common:entity.productName')}</CustomTable.Th>
					<CustomTable.Th>{t('common:entity.quantity')}</CustomTable.Th>
					<CustomTable.Th>{t('common:entity.unit', { count: 0 })}</CustomTable.Th>
					<CustomTable.Th align="end">{tPartyPrice('unitPrice', 'customer')}</CustomTable.Th>
					<CustomTable.Th align="end">{tPartyPrice('unitPrice')}</CustomTable.Th>
					<CustomTable.Th align="end">{t('components.editPrices.columnLabel')}</CustomTable.Th>
					<CustomTable.Th align="end">{t('common:entity.priceWOTax')}</CustomTable.Th>
				</tr>
			</thead>
			<tbody>
				{values.items.map((item, i) => {
					const { id } = item;
					const orderItem = getOrderItem(id);
					const pricingItem = getItemPricing(id);
					if (!orderItem) {
						return null;
					}
					const { quantity, unit, product } = orderItem;

					return (
						<tr key={id}>
							<CustomTable.Td>
								<span className="fw-bold">{product.group.name}</span>
								<br />
								{product.sku}
							</CustomTable.Td>
							<CustomTable.Td>{quantity}</CustomTable.Td>
							<CustomTable.Td>{unit}</CustomTable.Td>
							<CustomTable.Td align="right">
								{numberFormatter.currency(pricingItem?.customerPrice?.unit, currency)}
							</CustomTable.Td>
							<CustomTable.Td align="right">
								{numberFormatter.currency(pricingItem?.unitPrice, currency)}
							</CustomTable.Td>
							<CustomTable.Td align="right">
								{(() => {
									if (orderItem?.licenseOperation !== LicenseOperationEnum.NEW) {
										return noValuePlaceholder(t('common:_.NA'));
									}

									return (
										<FixedWidthWrapper width={160}>
											<FormikControl.Number
												name={`items.${i}.unitPrice`}
												size="sm"
												step={0.01}
												min={0.01}
												id={`customPrice-${id}`}
												numberFormat={{
													output: 'currency',
													currencySymbol: currency,
													currencyPosition: 'postfix',
													spaceSeparatedCurrency: true,
												}}
											/>
										</FixedWidthWrapper>
									);
								})()}
							</CustomTable.Td>
							<CustomTable.Td align="right">{numberFormatter.currency(getItemPrice(item), currency)}</CustomTable.Td>
						</tr>
					);
				})}
			</tbody>
			<tfoot>
				<tr>
					<CustomTable.Td
						foot
						className="cell cell-foot"
						colSpan={3}
					>
						{t('common:entity.totalPrice')}
					</CustomTable.Td>
					<CustomTable.Td
						foot
						align="right"
						className="text-gray"
					>
						{numberFormatter.currency(pricing?.headers.customerTotalPrice, currency)}
					</CustomTable.Td>
					<CustomTable.Td
						foot
						align="right"
						className="text-gray"
					>
						{numberFormatter.currency(pricing?.headers.totalAmountWithoutTax, currency)}
					</CustomTable.Td>
					<CustomTable.Td
						foot
						align="right"
						className="text-success"
						colSpan={2}
					>
						{numberFormatter.currency(getTotalPrice(values), currency)}
					</CustomTable.Td>
				</tr>
			</tfoot>
		</CustomTable>
	);
};
