import React from 'react';
import type { TableProps } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import classNames from 'classnames';
import { DataTableContextProvider, IDataTableContext } from 'js/components/molecules/DataTable/DataTableContext';

export type TDataTableProps = Omit<TableProps, 'variant'> &
	React.TableHTMLAttributes<HTMLTableElement> &
	IDataTableContext & {
		variant?: 'simple' | 'airy';
		contentWidth?: boolean;
	};

export const DataTable = (props: TDataTableProps) => {
	const { children, className, contentWidth, variant = 'airy', isLoading, ...tableProps } = props;
	const _className = classNames('table-detail-data', 'table-transparent', className, {
		[`is-${variant}`]: Boolean(variant),
		'content-width': Boolean(contentWidth),
	});

	return (
		<DataTableContextProvider isLoading={isLoading}>
			<Table
				{...tableProps}
				className={_className}
			>
				<tbody>{children}</tbody>
			</Table>
		</DataTableContextProvider>
	);
};
