import { useLicenseOperationContext } from 'module/licenses/context/LicenseOperationContext';
import { useCallback } from 'react';
import { ILicenseOperationPartyResolution } from 'module/licenses/components/selectParty';

export const useLicenseOperationPartyResolver = () => {
	const licenseOperationContext = useLicenseOperationContext();

	return useCallback(async (): Promise<ILicenseOperationPartyResolution | null> => {
		const { selectPartyModalRef } = licenseOperationContext;
		const response = await selectPartyModalRef.current?.show();
		return response ? response : null;
	}, [licenseOperationContext]);
};
