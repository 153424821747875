import { faPencilAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useWidgetContext, useWidgetEditContext } from 'submodule/widgets/context';
import { TWidgetPlaceholderItemProps } from '../components/WidgetItemContainer';
import {
	DraggableProps,
	DraggableProvided,
	DraggableProvidedDraggableProps,
	DraggableProvidedDragHandleProps,
	DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import classNames from 'classnames';
import { Attributes, ComponentProps } from 'react';
import { TIconButtonProps } from '@avast/react-ui-components';

type TDraggableComponentProps = Attributes & Omit<DraggableProps, 'children'>;
type TDraggableContentProps = DraggableProvidedDraggableProps &
	Partial<DraggableProvidedDragHandleProps> &
	ComponentProps<'div'>;

interface IUseWidgetItem {
	editWidgetProps: TIconButtonProps;
	removeWidgetProps: TIconButtonProps;
	draggableContainerProps: TDraggableComponentProps;
	getDraggableContentProps: (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => TDraggableContentProps;
}

export const useWidgetItem = ({ item, itemIndex, coords }: TWidgetPlaceholderItemProps): IUseWidgetItem => {
	const { t } = useWidgetContext();
	const { dispatch, widgetModal } = useWidgetEditContext();

	const editWidget = () => {
		widgetModal.current?.show({
			action: 'EDIT_WIDGET',
			title: t('widgets.action.editWidget'),
			coords,
			index: itemIndex,
			initialValues: item.widget,
		});
	};

	const removeWidget = () => dispatch({ type: 'REMOVE_WIDGET', payload: { index: itemIndex, coords } });
	const draggableKey = `column${itemIndex}-${item?.id}`;

	const editWidgetProps: TIconButtonProps = {
		size: 'xs' as const,
		iconFa: faPencilAlt,
		variant: 'outline-primary',
		onClick: editWidget,
	};

	const removeWidgetProps: TIconButtonProps = {
		size: 'xs' as const,
		iconFa: faTimes,
		variant: 'outline-danger',
		onClick: removeWidget,
	};

	const draggableContainerProps: TDraggableComponentProps = {
		key: draggableKey,
		draggableId: draggableKey,
		index: itemIndex,
	};

	const getDraggableContentProps = (
		provided: DraggableProvided,
		snapshot: DraggableStateSnapshot,
	): TDraggableContentProps => ({
		ref: provided.innerRef,
		className: classNames('widget-draggable', 'widget-item', { 'is-dragging': snapshot.isDragging }),
		...provided.draggableProps,
		...provided.dragHandleProps,
	});

	return {
		editWidgetProps,
		removeWidgetProps,
		draggableContainerProps,
		getDraggableContentProps,
	};
};
