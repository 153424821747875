import React from 'react';
import { accountConfig } from 'module/account/accountConfig';
import { RouteName } from 'module/RouteName';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Alert, Toast } from '@avast/react-ui-components';
import { AutoBillingContextProvider } from 'module/account/context/AutoBillingContext';
import { DynamicPageTitleItem } from 'js/components/molecules/DynamicPageTitle';
import { DynamicBreadcrumbsItem } from 'js/components/molecules/DynamicBreadcrumbs';
import { useIsPayAsYouGoEligible } from 'module/account/hooks/useIsPayAsYouGoEligible';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from 'js/contexts';

export const FlexibleBillingLayout = () => {
	const [t] = useTranslation(accountConfig.trNamespace);
	const { authPartner } = useAuthContext();
	const isPayAsYouGoEligible = useIsPayAsYouGoEligible();

	if (!isPayAsYouGoEligible) {
		toast.error(<Toast>{t('error:permissionDenied')}</Toast>);
		return (
			<Navigate
				to={RouteName.HOMEPAGE.DEFAULT}
				replace
			/>
		);
	}

	if (authPartner === null) {
		return <Alert caption={t('error:sectionDataNotFound')} />;
	}

	return (
		<>
			<DynamicPageTitleItem text={t('page.flexibleBilling.title')} />
			<DynamicBreadcrumbsItem href={RouteName.ACCOUNT.FLEXIBLE_BILLING}>
				{t('page.flexibleBilling.title')}
			</DynamicBreadcrumbsItem>
			<AutoBillingContextProvider>
				<Outlet />
			</AutoBillingContextProvider>
		</>
	);
};
