import React from 'react';
import { usePageDetailContext } from 'js/contexts/PageDetailContext';
import { useTranslation } from 'react-i18next';
import { ChargeOrderDocumentsButton } from 'js/components/documents/ChargeOrderDocumentsButton';
import { IEntityClosingBalance } from 'module/closingBalance';
import { hasAvailableDocuments } from 'module/closingBalance/utils/common';
import { closingBalanceConfig } from 'module/closingBalance/closingBalanceConfig';

type TOrderDocumentsButtonProps = {
	openOnInit?: boolean;
};

export const OrderDocumentsButton = (props: TOrderDocumentsButtonProps) => {
	const { data: closingBalance, query } = usePageDetailContext<IEntityClosingBalance>();
	const [t] = useTranslation(closingBalanceConfig.trNamespace);
	const availableDocuments = hasAvailableDocuments(closingBalance);

	return (
		<ChargeOrderDocumentsButton
			id={closingBalance.id}
			query={query}
			chargeOrderId={closingBalance.chargeOrderId!}
			title={t('common.documents')}
			openOnInit={props.openOnInit}
			availableDocuments={availableDocuments}
		/>
	);
};
