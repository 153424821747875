import { useCallback } from 'react';
import { useApi } from 'js/hooks/api';
import { refreshTokenService } from 'api/refreshTokenService';
import { useAuthContext } from 'js/contexts';
import { noop } from 'lodash';

export const useRefreshToken = () => {
	const {
		authState: { token },
		setAuthToken,
	} = useAuthContext();
	const api = useApi();

	return useCallback(async () => {
		const refreshTokenResponse = await refreshTokenService.subscribe(api, token).catch(noop);

		if (refreshTokenResponse) {
			setAuthToken(refreshTokenResponse);
		}
	}, [api, token, setAuthToken]);
};
