import {
	IGenerateRetailCodesForm,
	IGenerateRetailCodesLineItemRequest,
	IGenerateRetailCodesProduct,
	IGenerateRetailCodesRequest,
} from 'module/retail';
import { isPosaPartner } from 'module/partners/utils/partnerSelectors';
import { RetailCodeTypeEnum } from 'module/retail/enums';
import { skuUtils } from 'js/utils/sku';

export const retailFormNormalizer = {
	denormalize(form: IGenerateRetailCodesForm): IGenerateRetailCodesRequest | null {
		const { partner, products } = form;

		if (!partner?.id || products.length === 0) {
			return null;
		}

		return {
			type: isPosaPartner(partner) ? RetailCodeTypeEnum.POSA : RetailCodeTypeEnum.STANDARD,
			partnerId: partner.id,
			lineItems: products.map(this.denormalizeLineItem),
		};
	},

	denormalizeLineItem(item: IGenerateRetailCodesProduct): IGenerateRetailCodesLineItemRequest {
		const sku = skuUtils.putUnitValueToSku(item.product.sku, {
			isBusiness: item.product.isBusiness,
			unit: item.unit,
		});

		return { sku, quantity: item.quantity };
	},
};
