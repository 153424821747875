import CopyToClipboard from 'react-copy-to-clipboard';
import type { ITestId } from '@avast/react-ui-components';
import { IconButton, Toast, useTestAttributes } from '@avast/react-ui-components';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { noValuePlaceholder } from 'js/utils/app';
import { DEFAULT_ENTITY_VALUE } from 'appConstants';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { isDefined } from 'js/utils/common';

type TCopy2ClipboardProps = {
	value?: string | null;
	defaultValue?: boolean | ReactNode;
} & ITestId;

export const Copy2Clipboard = (props: PropsWithChildren<TCopy2ClipboardProps>): ReactElement | null => {
	const { defaultValue = true, children, value, testId } = props;
	const [t] = useTranslation();
	const testAttributes = useTestAttributes(testId, { type: 'copy2Clipboard' });

	if (!value) {
		if (isDefined(defaultValue)) {
			// noinspection PointlessBooleanExpressionJS
			return noValuePlaceholder(defaultValue === true ? DEFAULT_ENTITY_VALUE : defaultValue);
		}
		return null;
	}

	return (
		<CopyToClipboard
			{...testAttributes}
			text={value}
			onCopy={() => {
				toast.success(<Toast caption={t('common:actions.copied')} />, {
					autoClose: 2000,
					hideProgressBar: true,
					pauseOnHover: false,
				});
			}}
		>
			<div className="component__copy2clipboard">
				<IconButton
					className="btn-copy"
					iconFa={faCopy}
				/>
				<div className="ms-1 fs-md">{children || value}</div>
			</div>
		</CopyToClipboard>
	);
};
