import { AsyncModal, TAsyncModalContainerProps } from 'js/components/molecules/Modal/AsyncModal';
import React from 'react';
import { ILicenseOperationPartyResolution } from './selectPartyReducer';
import { SelectPartyModal } from './SelectPartyModal';

export const AsyncSelectPartyModal = (props: TAsyncModalContainerProps<{}, ILicenseOperationPartyResolution>) => {
	const { forwardedRef } = props;

	return (
		<AsyncModal<{}, ILicenseOperationPartyResolution>
			ref={forwardedRef}
			testId="selectParty"
		>
			<SelectPartyModal forwardedRef={forwardedRef} />
		</AsyncModal>
	);
};
