import React, { PropsWithChildren, useRef } from 'react';
import { AuthContextProvider, CommonContextProvider, OrderContextProvider } from 'js/contexts';
import { IAuthContextSubscriber } from 'types';

export const UserProviders = (props: PropsWithChildren<{}>) => {
	const authContextSubscriberRef = useRef<IAuthContextSubscriber>(null);

	return (
		<AuthContextProvider authContextSubscriberRef={authContextSubscriberRef}>
			<CommonContextProvider>
				<OrderContextProvider authContextSubscriberRef={authContextSubscriberRef}>
					{props.children}
				</OrderContextProvider>
			</CommonContextProvider>
		</AuthContextProvider>
	);
};
