import { generateEnumUtils } from 'js/enums/generator/enumFunctions';

export enum OrderDateRangeTypeEnum {
	CREATED = 'CREATED',
	BILLING = 'BILLING',
	PAYMENT = 'PAYMENT',
	PAYMENT_DUE = 'PAYMENT_DUE',
}

export const orderDateRangeTypeEnumUtils = generateEnumUtils<OrderDateRangeTypeEnum>(OrderDateRangeTypeEnum, {
	translatePath: 'enums:dateRangeType',
});
