import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { purchaseConfig } from 'module/purchase/purchaseConfig';
import { DistributionPartnerCard } from 'module/distributionPartners/components';
import { Alert, IconButton, Tooltip } from '@avast/react-ui-components';
import { useRequiredOrderParties } from 'module/purchase/hooks/useRequiredOrderParties';
import { IconChange } from 'assets/image/icon';
import { RequiredDistributionPartnerAlert } from 'module/purchase/components/RequiredDistributionPartnerAlert';
import { useCommonContext, useOrderContext } from 'js/contexts';
import { isDefined } from 'js/utils/common';

/**
 * Order distribution partner tab
 * @return {ReactElement}
 * @constructor
 */
export const DistributionPartnerTab = (): ReactElement => {
	const [t] = useTranslation(purchaseConfig.trNamespace);
	const { orderState, setDistributionPartner } = useOrderContext();
	const { selectDistributionPartnerRef } = useCommonContext();
	const { distributionPartner } = useRequiredOrderParties();

	if (isDefined(orderState.distributionPartner)) {
		const selectDistributionPartner = async () => {
			const distributionPartner = await selectDistributionPartnerRef.current?.show({
				distributorId: orderState.partner?.id,
				selectedDistributionPartnerId: orderState.distributionPartner?.id,
			});

			if (distributionPartner) {
				setDistributionPartner(distributionPartner);
			}
		};

		return (
			<div className="d-flex">
				<DistributionPartnerCard
					distributionPartner={orderState.distributionPartner}
					className="flex-grow-1 min-w-0"
				/>
				{distributionPartner.editable && (
					<div className="flex-shrink-0 ps-3">
						<Tooltip
							content={t('widget.selectDistributionPartnerCard.tooltips.change')}
							placement="bottom"
						>
							<IconButton
								variant="outline-primary"
								size="xs"
								icon={<IconChange />}
								onClick={selectDistributionPartner}
								testId="changeDistributionPartner"
							/>
						</Tooltip>
					</div>
				)}
			</div>
		);
	}

	if (!distributionPartner.valid) {
		return <RequiredDistributionPartnerAlert />;
	}

	return (
		<Alert variant="info">
			<Trans t={t}>{'widget.selectDistributionPartnerCard.note'}</Trans>
		</Alert>
	);
};
